/**
 * Created by cdavis on 2/28/17.
 */
'use strict';

mimosaApp.service('UIDB', function ($timeout, $rootScope, a5Socket, productDef, $http) {
    var DB = {
        initialized: false,
        temp: {},
        persist: {}
    };

    var handlers = {
        Update: function (event, message) {
            var message = unpack(message);
            if(message.hasOwnProperty('persist')) {
                DB.persist = message.persist;
            }
            if(message.hasOwnProperty('temp')) {
                DB.temp = message.temp;
            }
        },
        SetBase: function (event, message) {
            message = unpack(message);
            if(message.uidb.hasOwnProperty('persist')) {
                DB.persist = message.uidb.persist;
                DB.initialized = true;
            }
            if(message.uidb.hasOwnProperty('temp')) {
                DB.temp = message.uidb.temp;
                DB.initialized = true;
            }
        }
    };
    $rootScope.$on('socket:uidb', handlers.Update);
    $rootScope.$on('socket:base', handlers.SetBase);
    return DB;
});
