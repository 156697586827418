angular.module('MimosaApp').controller('ClientListPageController', ["$scope", "$rootScope", "$controller", "Device", "Clients", "$sce", function($scope, $rootScope, $controller, Device, Clients, $sce){
    $controller('PageController', {$scope: $scope, Device: Device});
    Device.socket.forward("dashboard_stats");
    $scope.Device.socket.forward("dashboard_apstats");

    $scope.Device = Device;
    $scope.Clients = Clients;
    $scope.tab = "list";
    $scope.toggle = {};
    $scope.showCharts = true;

    $scope.toggleCharts = function() {
        $scope.showCharts = !$scope.showCharts;
    };

    $scope.clientLimitDefaultAction = [
        {
            key: "Blacklist",
            value: 0
        },{
            key: "Whitelist",
            value: 1
        }, {
            key: "Dynamic",
            value: 2
        }
    ];
    $scope.statusAction = ["Blacklist", "Whitelist", "Dynamic"];

    $scope.clientLimitPersistence = [
        {
            key: "No",
            value: 0
        },{
            key: "Yes",
            value: 1
        }
    ];

    $scope.addSubscriber = function() {
        if(!Array.isArray(Device.stageDevice.subscribers)) {
            Device.stageDevice.subscribers = [];
        }
        Device.stageDevice.subscribers.push({
            'isAssociated' : 0,
            'selected' : false,
            'name' : '',
            'brand' : '',
            'model' : '',
            'macAddress' : '',
            'ssid' : '',
            'addSubscriber' : true,
            'planName' : Device.stageDevice.subscriberPlans[0].planName,
            'isVsaClient' : 0
        });
    };

    $scope.bars = {
        signal: {
            type: 'success',
            value: 175,
            max: 200
        },
        error: {
            type: 'danger',
            value: 45,
            max: 15
        },
        airUtil: {
            type: 'warning',
            value: 110,
            max: 225
        }
    };
    $scope.max = 200;

    $scope.$on("socket:dashboard_apstats", function(e, msg) {
        $scope.apstats = unpack(msg);
    });

    $scope.$watch("Device.stageDevice.clientVlans", function(n) {
       for(var mac in n) {
           if(n[mac] === null) {
               delete n[mac];
           }
       }
    }, true);

    $scope.$on("socket:time", function (e, msg) {
        var msg = unpack(msg);
        $scope.unixtime = msg.Data;
        $scope.Time = new Date(msg.Data * 1000);
        $scope.RebootTime = new Date(msg.RebootTime * 1000);
        $scope.UpTime = msg.Data - msg.RebootTime;
    });

    $scope.problemTemplate = 'app/partials/problem.html';

    $scope.popoverConfig = {
        templateUrl: 'actionPopoverTemplate.html',
        trigger: 'outsideClick',
        title: 'Actions'
    };

    var removeClient = function(client) {
        var call = new RPCall({
            path: "/device/ui/clientlist",
            interface: "device.ui.clients",
            method: "deleteClient",
            target: "clientInfo.mgr",
            args: {
                mac: client.MACaddr[0]
            }
        }, Device);

        call.call();

        for(var i = 0; Device.stageDevice.subscribers[i];i++) {
            var subscriber = Device.stageDevice.subscribers[i];
            if(subscriber.macAddress.toUpperCase() == client.MACaddr[0].toUpperCase()) {

                // More hacks to get around Subscriber handler issuers.
                Device.stageDevice.subscribers.splice(i, 1);
                Device.save();
                Device.realDevice.subscribers = angular.copy(Device.stageDevice.subscribers);
            }
        }
    }

    var kickClient = function(client) {
        var call = new RPCall({
            path: "/network/wireless/disassocClient",
            interface: "system.status.global",
            method: "disassocClient",
            target: "a5.bbic",
            args: {
                mac: client.MACaddr[0],
                ssid: client.ssid
            }
        }, Device);

        call.call();
    };

    var rebootClient = function(client) {
        var call = new RPCall({
            path: "/system/mqtt/send/reboot",
            interface: "system.mqtt.ifc",
            method: "sendRebootMethod",
            target: "mqttmanager.process",
            args: {
                rebootList: [client.MACaddr[0].toLocaleLowerCase()]
            }
        }, Device);

        call.call();
    };

    var instantClean = function (client) {
        var call = new RPCall({
            path: "/device/npu/staleClient",
            interface: "system.rpc.global",
            method: "instantCleanup",
            target: "staleclient.handler",
            args: {}
        }, Device);
        call.call(function(data){
            if(data.result && data.result == "success"){
                $scope.instantCleanStartSuccess = true;
            } else {
                $scope.instantCleanStartSuccess = false;
            }
        });
    };

    $scope.instantCleanStart = function () {
        instantClean();
    };

    $scope.promptRemove = function(client) {
        $rootScope.$broadcast("prompt", "Are you sure you want to remove this client and associated settings?", function(){}, function() {
            removeClient(client);
        })
    };
    $scope.promptKick = function(client) {
        var msg = "Are you sure you wish to force disassociation of " +(client.Name || client.IP || client.MACaddr.toUpperCase() ) +"?";
        $rootScope.$broadcast("prompt", msg, function(){}, function() {
            kickClient(client);
        });
    };

    $scope.promptReboot = function(client) {
        var msg = "Are you sure you wish to trigger a reboot for " +(client.Name || client.IP || client.MACaddr.toUpperCase() ) +"?";
        $rootScope.$broadcast("prompt", msg, function(){}, function() {
            rebootClient(client);
        });
    };

    $scope.removeSubscriber = function(subscriber) {
        var index = Device.stageDevice.subscribers.indexOf(subscriber);
        $rootScope.$broadcast("prompt", "Subscriber will need to re-associate to Access Point in order to reconnect.", function() {}, function(){
            Device.stageDevice.subscribers.splice(index, 1);
        });
    };

    $scope.displayedPopovers = [];

    $scope.hideOtherPopovers = function(index) {
        var newPopoverArr = [];
        for (var i = 0; i < Clients.list.length; i++) {
            if (index === i) {
                newPopoverArr.push(true)
            }
            else {
                newPopoverArr.push(false)
            }
        }
        $scope.displayedPopovers = newPopoverArr;
    };

    function makeTable(client, text) {
        var table = $("<table><thead></thead><tbody></tbody></table>").addClass("table").css("margin-bottom", 0);
        var header = $("<tr/>");
        var row = $("<tr/>");
        header.append("<th/>");
        row.append("<td>DL:</td>");

        var numCols = 4;
        if(text == "evm") {
            numCols = 2;
        }

        for(var i =0; i < numCols;i++) {
            header.append($("<th/>").text("#"+(i + 1)));
            var value = parseFloat(client.rx[text+"_"+i][0]);
            if(text === "evm") {
                value = Math.abs(value);
            }

            if(i >= 2 && text == "rssi") {
                value = false;
            }

            if(value)
                value = value.toFixed(1);
            else
                value = "--";

            row.append($("<td/>").text(value));
        }
        table.find("tbody").append(row);
        var row = $("<tr/>");
        row.append("<td>UL:</td>");
        for(var i =0; i < numCols;i++) {
            var value = parseFloat(client["su_"+text+"_"+i][0]);

            if(text === "evm") {
                value = Math.abs(value);
            }

            if(value)
                value = value.toFixed(1);
            else
                value = "--";

            row.append($("<td/>").text(value));
        }
        table.find("tbody").append(row);
        table.find("thead").append(header);
        return $("<div/>").width(300).append(table);
    };

    var trusted = {};

    $scope.rssiValues = function(client) {
        var table = makeTable(client, "rssi").html();
        return trusted[table] || (trusted[table] = $sce.trustAsHtml(table));
    };
    $scope.evmValues = function(client) {
        var table = makeTable(client, "evm").html();
        return trusted[table] || (trusted[table] = $sce.trustAsHtml(table));
    };

    $scope.dlSnr1 = function(client) {
        var value = parseFloat(client.rx.evm_0[0]);
        value = Math.abs(value);

        if (value)
            value = value.toFixed(1);
        else
            value = "--";

        return value;
    };

    $scope.dlSnr2 = function(client) {
        var value = parseFloat(client.rx.evm_1[0]);
        value = Math.abs(value);
        if(value)
            value = value.toFixed(1);
        else
            value = "--";

        return value;
    };


    $scope.ulSnr1 = function(client) {
        var value = parseFloat(client.su_evm_0[0]);
        value = Math.abs(value);

        if (value)
            value = value.toFixed(1);
        else
            value = "--";

        return value;
    };

    $scope.ulSnr2 = function(client) {
        var value = parseFloat(client.su_evm_1[0]);
        value = Math.abs(value);
        if(value)
            value = value.toFixed(1);
        else
            value = "--";

        return value;
    };

    $scope.clientDistance = function(value) {
        if(!value || value == -1) {
            return ["--", "--"];
        }
        if(value < 2) {
            return ["< 300", "< 984ft"];
        }
        return distance(value * 300);
    }

    /**
     * Exploded view chart
     */
    //data
    $scope.chartData = {
        throughput: [ [], [] ]
    };

    var returnLabels = function  () {
        var init = ["Now"];
        for (var i = 0; i < 59; i++) {
            init.push(i + "s");
        };
        init.push("1min");
        return init.reverse();
    };

    // Labels
    $scope.chartLabels = returnLabels();

    // throughput
    $scope.seriesThru = [ 'DL', 'UL'];

    //PER
    $scope.seriesPer = ['DL', 'UL'];

    $scope.chartColors = [
            {
                backgroundColor: "rgba(220,220,220,0)",
                strokeColor: 'rgba(93, 202, 147, .8)'
            },
            {
                backgroundColor: "rgba(220,220,220,0)",
                strokeColor: 'rgba(70, 145, 219, .9)'
            }
        ];

    $scope.thruChartOptions = {
        animation: false,
        title: {
            display: true,
            text: "Throughput",
            fontFamily: "Open Sans Light",
            fontSize: 14
        },
        legend: {
            display: true
        },
        tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
                title: function(item) {
                    return item[0].xLabel + ' ago'
                },
                label: function(item, data) {
                    var labelArr = [];
                    labelArr[0] = data.datasets[0].label+": "+ (bytesToBps(data.datasets[0].data[item.index]) || 0);
                    labelArr[1] = data.datasets[1].label+": "+ (bytesToBps(data.datasets[1].data[item.index]) || 0);
                    return labelArr;
                },
                afterTitle: function(item, data){
                    var dataIndex = item[0].index,
                        dataDL = data.datasets[0].data[dataIndex],
                        dataUL = data.datasets[1].data[dataIndex];
                    return "Total: " + bytesToBps(dataDL + dataUL);
                }
            }
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        maxTicksLimit: 20,
                        callback: function(y) {
                            return bytesToBps(y, 0);
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: 20
                    }
                }
            ]
        }
    };

    $scope.perChartOptions = {
        animation: false,
        legend: {
          display: true
        },
        title: {
            display: true,
            text: "PER",
            fontFamily: "Open Sans Light",
            fontSize: 14
        },
        tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
                title: function(item) {
                    return item[0].xLabel + ' ago'
                },
                label: function(item, data) {
                    var labelArr = [];
                    labelArr[0] = data.datasets[0].label+": "+ data.datasets[0].data[item.index].toFixed(1) + "%";
                    if(data.datasets.length > 1)
                        labelArr[1] = data.datasets[1].label+": "+ data.datasets[1].data[item.index].toFixed(1) + "%";
                    return labelArr;
                }
            }
        },
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        maxTicksLimit: 12,
                        suggestedMax: 5,
                        callback: function(y) {
                            return y.toFixed(1) + "%";
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: 20
                    }
                }
            ]
        }
    };

    $scope.datasetOverridePer = [
        {
            fill: false
        }, {
            fill: false
        }
    ];

    var showAll = true;
    $scope.showHide = function() {
        for(var key in Clients.list) {
            $scope.toggle[Clients.list[key].MACaddr[0]] = showAll;
        }
        showAll = !showAll;
    };

    function bytesToBps(y, fixed) {
        var abs_y = Math.abs(y);
        if (abs_y >= 1000000000) {
            return (y / 1000000000).toFixed(1) + " Gbps"
        }
        else if (abs_y >= 1000000) {
            return (y / 1000000).toFixed(fixed) + " Mbps"
        }
        else if (abs_y >= 1000) {
            return (y / 1000).toFixed(fixed) + " kbps"
        }
        else if (abs_y > 0) {
            return y.toFixed(fixed) + " bps"
        }
        else if (abs_y === 0) {
            return ''
        }
        else {
            return y
        }
    };

    $scope.bandwidths = [20, 40, 80];

    function pushNshift (newData, dataSet, maxLength) {
        if (dataSet.length >= maxLength) {
            dataSet.shift();
            dataSet.push(newData);
        }
        else {
            dataSet.push(newData);
        }
    };
    /**
     * Chart End
     */

    $scope.airtimeBarType = function(val){
        if (val <= 33) {
            return "success";
        }
        else if (val <= 66) {
            return "warning";
        }
        else {
            return "danger";
        }
    };

    $scope.txperBarType = function(val) {

        switch(Device.realDevice.wirelessMode.mode) {
            case "csma":
                var good = 15;
                var medium = 25;
                break;
            default:
                var good = 4;
                var medium = 6;
                break;
        }
        if(val <= good) {
            return "success";
        }

        if(val <= medium) {
            return "warning";
        }

        return "danger";
    };

    $scope.changeTab = function(tab) {
        if(tab != $scope.tab) {
            ga('send', 'event', 'clientList', tab);
        }
        $scope.tab = tab;
    };

    $scope.orderByAttribute = "Name:true";
    $scope.sortClientHosts = function (column) {
        $scope.rev = $scope.rev ? false : true;
        $scope.orderByAttribute = column + ':' + $scope.rev;
    };
    $scope.deviceHosts = { data: null };

    $scope.$watch("Device.stageDevice.clientLimit.host_limits", function () {
        if (Device.stageDevice.clientLimit) {
            $scope.deviceHosts.data = Device.stageDevice.clientLimit.host_limits;
        }
    });
    $scope.newhostRow = {
        flag: false,
        orderBykey: 'Name',
        newhost: {
            station: this.station,
            client_key: this.client_key,
            IP: this.IP,
            Name: this.Name
        }
    };
    $scope.pushNewHostLimit = function () {
        if (Device.stageDevice.clientLimit.host_limits.hasOwnProperty($scope.newhostRow.newhost.client_key.toUpperCase())) {
            $scope.newhostRow.newhost.client_keyErr = true;
            return false;
        }
        if ($scope.newhostRow.newhost.action == undefined) {
            $scope.newhostRow.newhost.actionError = true;
            return false;
        }
        if ($scope.newhostRow.newhost.station == undefined || $scope.newhostRow.newhost.station == "") {
            $scope.newhostRow.newhost.stationErr = true;
            return false;
        }

        $scope.newhostRow.newhost.client_key = $scope.newhostRow.newhost.client_key.toUpperCase();

        $scope.Device.stageDevice.clientLimit.host_limits[$scope.newhostRow.newhost.client_key] = JSON.parse(JSON.stringify($scope.newhostRow.newhost));
        $scope.deleteNewHostLimit();
    };

    $scope.deleteNewHostLimit = function () {
        $scope.newhostRow.newhost = {};
        $scope.newhostRow.flag = false;
    };

    $scope.retrieveMimosaModel = function(mac) {
        var upperMac = mac.toUpperCase();
        if (Clients.list[upperMac]) {
            return Clients.list[upperMac].deviceType;
        }
        else {
            return "N/A";
        }
    };

    $scope.distance = function distance(meters, precision) {
        var undef, result = [];
        meters = parseInt(meters);
        if (isNaN(meters))
            return result;
        var format = precision === undef ?
            function(number) {
                return number;
            } :
            function(number) {
                return number.toFixed(precision)
            };

        var feet = meters * 3.2808399;
        result[0] = meters < 1000 ? (format(meters) + ' m') : (format(meters / 1000) + ' km');
        result[1] = feet > 5280 ? (format(feet / 5280) + ' mi') : (Math.round(format(feet)) + ' ft');

        return result;

    };

    /**
     * Should VLAN be disabled? False is field enabled, true is field disabled.
     * @param subscriber
     * @returns {boolean}
     */
    $scope.disableVlan = function(subscriber) {

        if(!subscriber.isAssociated) {
            return false;
        }
        var ssids = Device.realDevice.wirelessMode.mode == "tdma" ? Device.realDevice.tdma_ssids5 : Device.realDevice.ssids5;
        for(var i = 0; ssids[i];i++) {
            var ssid = ssids[i];
            if(ssid.ssid == subscriber.ssid) {
                if(ssid['SSID Type'] == "CPE" && ssid['CPE Data Vlan Enabled']) {
                    return false;
                }
            }
        }
        return true;
    };

    $scope.isPortAuthEnabled = function(ssidName) {

        var ssids = Device.realDevice.wirelessMode.mode == "tdma" ? Device.realDevice.tdma_ssids5 : Device.realDevice.ssids5;
        for (var i = 0; ssids[i]; i++ ) {
            var ssid = ssids[i];
            if (ssid.ssid == ssidName) {
                if(ssid['Radius Proxy'])
                    return true;
            }
        }
        return false;
    };

    ga('send', 'event', 'clientList', 'list');

}]);

angular.module('MimosaApp').filter('orderByObject', function () {
    return function (input, attribute) {
        if(!input) return;
        if (!angular.isObject(input)) return input;
        var array = [];
        for (var objectKey in input) {
            //input[objectKey]['key'] = objectKey;
            array.push(input[objectKey]);
        }
        var column = attribute.split(':')[0];
        var order = attribute.split(':')[1];
        //console.log(column, order);
        array.sort(function (a, b) {
            if(!a[column] || !b[column])
                return;
            if (attribute == 'position') {
                a = parseInt(a[column]);
                b = parseInt(b[column]);
                if (order == 'true')
                    return a - b;
                else
                    return b - a;
            } else {
                if (order == 'true')
                    return a[column].localeCompare(b[column]);
                else
                    return b[column].localeCompare(a[column]);
            }
        });
        return array;
    }
});
