angular.module('MimosaApp').controller('ConfigPageController', ['$scope', '$controller', 'Device', 'FileUpload', function($scope, $controller, Device, FileUpload) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.configFile = false;
    $scope.Device = Device;
    $scope.uploadConfig = function() {
        var file = $scope.configFile;
        var uploadUrl = '/loadconfig';
        FileUpload.uploadFileToUrl(file, uploadUrl);
    };

}]);
