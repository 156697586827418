/**
 * Created by gagan on 6/23/16.
 */
angular.module('MimosaApp').controller('NotificationsPageController', ['$scope', '$controller', 'Device', '$rootScope', function($scope, $controller, Device, $rootScope) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;
    $scope.logHostModes = ["TCP", "UDP"];

}]);
