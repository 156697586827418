'use strict';

angular.module('MimosaApp').controller('SitesurveyPageController', ['$scope', '$controller', 'Device', 'Sites', '$rootScope', function ($scope, $controller, Device, Sites, $rootScope) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Sites = Sites;

    $scope.Scan = function() {
        if(Device.realDevice.wirelessMode.mode == "csma") {
            Device.stats.sitesurvey = {};
            Sites.start();
        } else {
            $rootScope.$broadcast("prompt", "Doing a Site Survey will disconnect currently associated clients. ", function() {
            }, function(){
                Device.stats.sitesurvey = {};
                Sites.start();
            });
        }
    };

    $scope.haveResults = function() {
        if(Device.stats.sitesurvey === undefined) { return false; }
        return Object.keys(Device.stats.sitesurvey).length !== 0;
    };

    $scope.$watch("Device.stats.sitesurvey", function(n, o) {
        if(!$.isEmptyObject(n)) {
            Sites.received();
        }
    }, true);

    $scope.$on("base-loaded", function() {
        Device.stats.sitesurvey = {};
    });
}]);
