angular.module('MimosaApp').controller('BackupPageController', ['$scope', '$controller', 'Device', 'FileUpload', '$rootScope', '$http', function($scope, $controller, Device, FileUpload, $rootScope, $http) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;
    $scope.FileUpload = FileUpload;


    $scope.config = false;
    $scope.uploadFile = function(key) {
        var file = $scope[key];
        switch(key) {
            case "config":
                var uploadUrl = "/loadconfig";
                break;
        }
        FileUpload.uploadFileToUrl(file, uploadUrl, key);
    };
    $scope.uploadShow = function(key) {
        if(FileUpload.hasOwnProperty(key)) {
            if(FileUpload[key].percent > -1) {
                return false;
            }
        }
        if($scope[key]) {
            return true;
        }
        return false;
    };

    $scope.cancelUpload =function(key) {
        $scope[key] = false;
        FileUpload.cancelUpload(key);
        FileUpload.reset(key);
        $("#"+key+"_upload_input").val('');
    };
    // If the error flag is set, we reset all the upload data, remove error flag, show a message.
    $scope.$watch(function() {
        return FileUpload.error;
    }, function(n) {
        if(n) {
            $rootScope.$broadcast("alert:info", 'There was an error uploading. Please try again.');
            $scope.cancelUpload('config');
        }
    }, true);

    $scope.callUrl = function(url) {
        var url = "/"+url;
        $http.get(url);
    };

    $scope.prompt = function(msg) {
        $rootScope.$broadcast("prompt", msg, function() {
            $scope.cancelUpload('config');
        });
    };
}]);