'use strict';

angular.module("MimosaApp").directive("passwordToMatch", function($parse) {
    return {
        require: 'ngModel',
        scope: {
            otherPassword: '=passwordToMatch'
        },
        link: function(scope, elem, attrs, ngModel) {

            ngModel.$validators.passwordToMatch = function(modelValue) {
                if (modelValue === undefined) return true;
                return modelValue === scope.otherPassword;
            };
            
            scope.$watch('otherPassword', function() {
                ngModel.$validate();
            });

        }
    }
});
