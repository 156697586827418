/**
 * Created by charlie on 7/24/15.
 */

angular.module('MimosaApp').controller('Subscriber-plansPageController', ['Device', '$scope', '$controller', function(Device, $scope, $controller) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;
    initPageData = function() {
        // Top of page data
        if(!Array.isArray(Device.stageDevice.subscribers)) {
            return;
        }
        $scope.totalSubscribers = Device.stageDevice.subscribers.length;
        $scope.calcTotalCommitted = function() {
            $scope.totalDlCommitted = 0;
            $scope.totalUlCommitted = 0;
            for (planNum = 0; planNum < $scope.Device.stageDevice.subscriberPlans.length; planNum++) {
                $scope.totalDlCommitted += $scope.Device.stageDevice.subscriberPlans[planNum].dlCommittedInMbps * $scope.Device.stageDevice.subscriberPlans[planNum].connectedClients
                $scope.totalUlCommitted += $scope.Device.stageDevice.subscriberPlans[planNum].ulCommittedInMbps * $scope.Device.stageDevice.subscriberPlans[planNum].connectedClients
            }
        }();
    };
    initPageData();

    $scope.isPageValid = function() {
        if(!$scope.plansForm.$valid) {
            throw {
                message: "Invalid input. Please provide valid input."
            }
        }
        for (planNum = 0; planNum < $scope.Device.stageDevice.subscriberPlans.length; planNum++) {
            if ( $scope.Device.stageDevice.subscriberPlans[planNum].dlPeakInMbps <
                 $scope.Device.stageDevice.subscriberPlans[planNum].dlCommittedInMbps ||
                 $scope.Device.stageDevice.subscriberPlans[planNum].ulPeakInMbps <
                 $scope.Device.stageDevice.subscriberPlans[planNum].ulCommittedInMbps
            )
            throw {
                name: 'Invalid form',
                message: 'Max speeds cannot be less than Committed speeds.'
            }
        }
        if (checkDuplicateNames(Device.stageDevice.subscriberPlans, "planName")) {
            throw {
                name: 'Invalid form',
                message: 'Duplicate plan names not permitted.'
            }
        }
    };

    function checkDuplicateNames (arr, prop) {
        var arrValues = {};
        for (var i = 0; i < arr.length; i++) {
            var name = arr[i][prop];
            if (arrValues.hasOwnProperty(name)) {
                return true;
            }
            arrValues[name] = true;
        }
        return false;
    };

    $scope.removePlan = function(plan) {
        var index = Device.stageDevice.subscriberPlans.indexOf(plan);
        if (plan.isDefault == true) {
            alert('Cannot delete default plan');
            return;
        }
        if(plan.isVsaPlan == true)
        {
            alert('Plans cannot be deleted if they are auto-configured by RADIUS Vendor-Specific Attributes (VSA)');
            return;
        }
        if (plan.connectedClients > 0) {
            alert('Cannot delete a plan with subscribers. Please remove those subscribers or change their plan and try again.');
            return;
        }
        else {
            if (confirm('Are you sure you want to delete this plan?')) {
            Device.stageDevice.subscriberPlans.splice(index, 1);
            }
            else return;
        }
    };

    $scope.addPlan = function() {
        Device.stageDevice.subscriberPlans.push({
            'planName': '',
            'dlPeakInMbps': 1,
            'dlCommittedInMbps': 1,
            'ulPeakInMbps': 1,
            'ulCommittedInMbps': 1,
            'connectedClients' : 0,
            'isDefault' : false,
            'isVsaPlan' : false
        });
    };
    $scope.$watch(function() {
        return $scope.Device.stageDevice.subscriberPlans;
    }, function() {
        initPageData();
    }, true);
}]);
