angular.module('MimosaApp').controller('SavingController', ['$scope', "Device", "growl", function($scope, Device, growl) {

    $scope.saving = false;
    $scope.$on("saving", function() {
        $scope.saving = false;
    });
    $scope.$on('socket:successful', function() {
        $scope.saving = false;
        growl.info("Configuration changes saved successfully.");

    });
    $scope.$on('socket:error_msg', function() {
        $scope.saving = false;
    });
}]);
