angular.module('MimosaApp').directive('roundDisplay', function ($filter, $parse) {
    return {
        require: 'ngModel',
        link: function($scope, $element, $attrs, ngModelCtrl) {
            var decimal =  $parse($attrs.roundDisplay)($scope) || 2;
            ngModelCtrl.$formatters.push(function(value) {
                return parseFloat(parseFloat(value).toFixed(decimal));
            });

            ngModelCtrl.$render = function() {
                $element.val(ngModelCtrl.$viewValue);
            };
        }
    }
});