mimosaApp.service('GPS', function ($rootScope) {
    var gps_data = {
        avg_snr: 0,
        snr_total: 0,
        snrs: [],
        fixed: [],
        glonass: [],
        gpssats: [],
        all_sats:[],
        latitude: 0,
        longitude: 0,
        elevation: 0
    };

    var config = {
        glonassStart: 33
    };

    function MessageReceived(event, message) {
        if (message.key == "gps") {
            // remove any whitespaces if present.
            // message.data = message.data.replace(/\s/g, '');
            var message = unpack(message.data);
            parseData(message);
        }
    }

    function parseData(data) {
        var fixed_sats = data.FIX.split(',').map(function (item) {
            return parseInt(item, 10);
        }).filter(function (item) {
            return !isNaN(item) && item !== null;
        });

        var sat_snrs = {};
        data.SAT.forEach(function (ele) {
            var parts = ele.split(',');
            sat_snrs[parseInt(parts[0])] = parseInt(parts[3], 10);
        });

        var snr_total = 0;
        var local_glonass = [];
        var local_sats = [];
        var local_all_sats = [];

        for (var i = 0; i < fixed_sats.length; i++) {
            var sat_id = fixed_sats[i];
            var sat_snr = sat_snrs[parseInt(sat_id)];

            var sat = {};
            sat.x = i;
            sat.y = parseInt(sat_snr);
            sat.id = parseInt(sat_id);

            snr_total += sat.y;

            if (sat.id > 33) {
                local_glonass.push(sat);
            } else {
                local_sats.push(sat);
            }
            local_all_sats.push(sat);
        }

        gps_data.avg_snr = snr_total / fixed_sats.length;
        gps_data.snr_total = snr_total;
        gps_data.snrs = sat_snrs;
        gps_data.fixed = fixed_sats;
        gps_data.glonass = local_glonass;
        gps_data.gpssats = local_sats;
        gps_data.all_sats = local_all_sats;
        gps_data.latitude = data.LATITUDE;
        gps_data.longitude = data.LONGITUDE;
        gps_data.elevation = data.ELEVATION;

    }

    $rootScope.$on("socket:stats", MessageReceived);

    return gps_data;
});
