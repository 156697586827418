var DbusHandlers = {
    ssids2: function(Device) {
        Device.setCfg('/network/npu/wireless/hostapd/ssid', {
            'ssids2': Device.stageDevice.ssids2
        });
    },
    ssids5: function(Device) {
        Device.setCfg('/network/bbic/wireless/hostapd/ssid', {
            'ssids5': Device.stageDevice.ssids5
        });
    },
    tdma_ssids5: function(Device) {
        Device.setCfg('/network/bbictdma/wireless/hostapd/ssid', {
            'ssids5': Device.stageDevice.tdma_ssids5
        });
    },
    nms: function(Device) {
        Device.setCfg('/device/general/nms', {
            'nms': Device.stageDevice.nms
        });
    },
    ipv4: function(Device) {
        Device.setCfg('/network/npu/port/mgmt/staticaddr', {
            'ipv4': Device.stageDevice.ipv4
        });
    },
    subscriberPlans: function(Device) {
        Device.setCfg('/network/npu/ratelimit/subscriberPlan', {
            'subscriberPlans': Device.stageDevice.subscriberPlans
        });
    },
    subscribers: function(Device) {
        Device.setCfg('/network/npu/ratelimit/subscriber', {
            'subscribers': Device.stageDevice.subscribers
        });
    },
    clients: function(Device) {
        Device.setCfg('/network/npu/ratelimit/clients', {
            'clients': Device.stageDevice.clients
        });
    },
    acls: function(Device) {
        Device.setCfg('/network/npu/security/acl', {
            'acls': Device.stageDevice.acls
        });
    },
    rateLimit: function(Device) {
        Device.setCfg('/network/npu/ratelimit/trafficShaping', {
            'rateLimit': Device.stageDevice.rateLimit
        });
    },
    radioConfigBbic: function (Device) {
        Device.setCfg('/network/bbic/wireless/radio/config', {
            'radioConfigBbic': $.extend(Device.stageDevice.radioConfigBbic, { "Change Reason": "user" })
        });
    },
    radioConfigNpu: function(Device) {
        Device.setCfg('/network/npu/wireless/radio/config', {
            'radioConfigNpu': Device.stageDevice.radioConfigNpu
        });
    },
    hostname: function(Device) {
        Device.setCfg('/device/general/hostname', {
            'hostname' : Device.stageDevice.hostname
        });
    },
    deviceDescription: function(Device) {
        Device.setCfg('/device/general/deviceDescription', {
            'deviceDescription' : Device.stageDevice.deviceDescription
        });
    },
    ledConfig: function(Device) {
        Device.setCfg('/device/ui/led', {
            'ledConfig' : Device.stageDevice.ledConfig
        });
    },
    logHost: function(Device) {
        Device.setCfg('/device/general/logHost', {
            'logHost' : Device.stageDevice.logHost
        });
    },
    nms: function(Device) {
        Device.setCfg('/device/general/nms', {
            'nms' : Device.stageDevice.nms
        });
    },
    clientVlans: function(Device) {
        for(var mac in Device.stageDevice.clientVlans) {
            Device.stageDevice.clientVlans[mac] = parseInt(Device.stageDevice.clientVlans[mac]);
        }
        Device.setCfg('/network/bbic/wireless/clientVlan', {
            'clientVlans': Device.stageDevice.clientVlans
        });
    },
    tpid: function(Device) {
        Device.setCfg('/network/bbic/wireless/vlan/tpid', {
            'tpid' : Device.stageDevice.tpid
        });
    },
    option82: function(Device) {
        Device.setCfg('/network/npu/dhcp/option82', {
            'option82' : Device.stageDevice.option82
        });
    },
    mgmtVlan: function(Device) {
        Device.setCfg("/network/npu/vlan/mgmt", {
            'mgmtVlan': Device.stageDevice.mgmtVlan
        });
    },
    clientLimit: function(Device) {
        Device.setCfg("/network/bbic/fwt/clientLimit", {
            'clientLimit': Device.stageDevice.clientLimit
        });
    },
    jumboPkt: function(Device) {
        Device.setCfg("/network/npu/port/mgmt/jumbo", {
            'jumboPkt': Device.stageDevice.jumboPkt
        });
    },
    sessionTimeout: function(Device) {
        Device.setCfg("/device/ui/sessionTimeout", {
            'sessionTimeout': Device.stageDevice.sessionTimeout
        });
    },
    clientWatchDog: function(Device) {
        Device.setCfg("/device/general/client/watchdog", {
            'clientWatchDog': Device.stageDevice.clientWatchDog
        });
    },
    webServer: function(Device) {
        Device.setCfg("/device/ui/webserver", {
            'webServer': Device.stageDevice.webServer
        });
    },
    resetPassword: function(Device) {
        Device.setCfg("/device/ui/password", {
            'password': Device.stageDevice.resetPassword.newPassword,
            'curPassword': Device.stageDevice.resetPassword.currentPassword
        });
    },
    agc: function(Device) {
        Device.setCfg("/network/bbic/wireless/radio/agc", {
            'agc': Device.stageDevice.agc
        });
    },
    tdma_agc: function(Device) {
        Device.setCfg("/network/bbictdma/wireless/radio/agc", {
            'agc': Device.stageDevice.tdma_agc
        });
    },
    snmp: function(Device) {
        Device.setCfg("/system/npu/snmp", {
            'snmp': Device.stageDevice.snmp
        });
    },
    dhcpRogueServer: function(Device) {
        Device.setCfg("/network/npu/security/rogueDhcpServerProtection", {
            "rogueDhcpProtect": Device.stageDevice.dhcpRogueServer
        });
    },
    appPriority: function(Device) {
        Device.setCfg("/network/npu/security/applicationPrioritization", {
            appPriority: Device.stageDevice.appPriority
        })
    },
    wirelessMode: function(Device) {
        if (Device.stageDevice.wirelessMode.mode === 'csma') {
            if (Device.stageDevice.wirelessMode.genderTrafficSplit) delete Device.stageDevice.wirelessMode.genderTrafficSplit;
            if (Device.stageDevice.wirelessMode.phyRateOptimization) delete Device.stageDevice.wirelessMode.phyRateOptimization;
            if (Device.stageDevice.wirelessMode.windowSize) delete Device.stageDevice.wirelessMode.windowSize;
        }
        else if (Device.stageDevice.wirelessMode.mode === 'tdma') {
            Device.stageDevice.wirelessMode.windowSize = 8;
        }
        Device.setCfg("/network/bbic/wireless/mode", {
            wirelessMode: Device.stageDevice.wirelessMode
        })
    },
    macstatsLogger: function(Device) {
        Device.setCfg("/device/general/macstatsLogger", {
            macstatsLogger: Device.stageDevice.macstatsLogger
        })
    },
    ztp: function(Device) {
        Device.setCfg("/device/general/ztp", {
            'ztp': Device.stageDevice.ztp
        });
    },
    staleClientsCleanup: function(Device) {
        Device.setCfg("/device/npu/staleClient", {
            'staleClientsCleanup': Device.stageDevice.staleClientsCleanup
        });
    }
};
