angular.module('MimosaApp').directive('fileUpload', ['$parse', function($parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var model = $parse(attrs.fileUpload);
            var modelSetter = model.assign;


            element.on('change', function(event) {
                var onChangeHandler = scope.$eval(attrs.customOnChange);
                scope.$apply(function() {
                    modelSetter(scope, element[0].files[0]);
                });
                if(onChangeHandler) {
                    onChangeHandler.apply(arguments);
                }
            });
        }
    };
}]);