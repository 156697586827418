mimosaApp.factory('SessionTimeoutService', function() {
    var sessionLengthInMinutes = -1;

    var obj = {
        setLength: function(length) {
            if(!length) {
                length = 20160; // Two weeks.
            }
            sessionLengthInMinutes = length;
            this.timeLeft = sessionLengthInMinutes * 60;
        },
        reset: function() {
            if(sessionLengthInMinutes >= 0)
                this.timeLeft = sessionLengthInMinutes * 60;
        },
        timeLeft: 36000 // arbitrary number. Will get set when a real value comes in from the web socket.
    };

    window.setInterval(function() {
        obj.timeLeft = obj.timeLeft - 5;
    }, 5000);

    return obj;
});
