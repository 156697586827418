'use strict';

angular.module('MimosaApp').controller('ChannelpowerPageController', function ($scope, $controller, Device, $rootScope, productDef) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;

    $scope.spectrumSource = 'local';
    $scope.DFS = false;
    $scope.gainChanging = false;
    $scope.timers = {};

    $scope.config = {
        key: "radioConfigBbic",
        agc: "agc"
    };

    $scope.agc = {
        override: false,
        opts: [
            {
                min: -75,
                max: -50
            }, {
                min: -90,
                max: -30
            }
        ]
    };

    $scope.isPageValid = function () {
        if ($scope.channelForm.$invalid) {
            throw {
                name: 'There is an error.',
                message: 'A value is invalid.'
            };
        }
    };

    $scope.bandOptions2 = ['802.11b/g/n'];

    $scope.RadioBands = {
        "A": [20],
        "NA": [20, 40],
        "AC": [20, 40, 80]
    };

    $scope.AutoWidths = [20, 40, 80];

    $scope.SWTPCModes = ["AUTO", "MANUAL"];

    $scope.agcOptions = {
        csma: ["Auto", "Manual"],
        tdma: ["Auto", "Manual"]
    };

    $scope.AlternateReturnTimeOptions = [30, 60, 120, 240, 480];

    /**
     * Order is important here. To calculate power, a channel needs to be set, so that is checked first. Channel Width is before that
     *
     * @param obj
     * @param mhz
     */
    function checkForBlank(obj, mhz) {
        if (obj["Channel Width"] === null) {
            var bandwidthopt = $scope.RadioBands[Device.stageDevice[$scope.config.key]['Wireless Band']];
            obj["Channel Width"] = bandwidthopt[0];
        }
        if (obj["Center Frequency"] === null) {
            var channelOptions = $scope.getChannels(mhz);
            obj["Center Frequency"] = channelOptions[0].Number || channelOptions[0]['Center Frequency'][0];
        }
        if (obj["Tx Power"] === null) {
            var powerOptions = $scope.PowerRange(mhz);
            obj["Tx Power"] = powerOptions[0];
        }
        if (obj["Channel Number"] === null) {
            obj["Channel Number"] = $scope.getCurChannelOptions(5)['Primary Channel'][0][1];
        }
        if (obj["User Alt Bandwidth"] === null) {
            var altbandwidthopt = $scope.RadioBands[Device.stageDevice[$scope.config.key]['Wireless Band']];
            obj["User Alt Bandwidth"] = altbandwidthopt[0];
        }
        if (obj["User Alt CenterFreq"] === null) {
            var altChannelOptions = $scope.getChannels(5, true);
            obj["User Alt CenterFreq"] = altChannelOptions[0].Number || altChannelOptions[0]['Center Frequency'][0];
        }
        if (obj["User Alt Primary"] === null) {
            obj["User Alt Primary"] = $scope.getAltChannelOptions(5)['Primary Channel'][0][1];
        }
    }

    $scope.$watch(function (scope) {
        return scope.Device.stageDevice.radioConfigNpu;
    }, function twoGChange(n, o) {
        if (typeof n !== "undefined")
            checkForBlank(n, 2);
    }, true);


    $scope.getCurChannelOptions = function (mhz) {
        var listname = "";
        var configname = "";
        var channelCheck;
        switch (mhz) {
            case 5:
                listname = "channelListBbic";
                configname = $scope.config.key;
                channelCheck = function (ele) {
                    return ele["Center Frequency"][0] == Device.stageDevice[configname]["Center Frequency"];
                };
                break;
            case 2.4:
                listname = "channelListNpu";
                configname = "radioConfigNpu";
                channelCheck = function (ele) {
                    return ele['Frequency'] == Device.stageDevice[configname]['Center Frequency'];
                };
                break;
        }
        if (!Device.stats[listname] || !Device.stageDevice[configname]) {
            return;
        }

        return $scope.getChannels(mhz).filter(channelCheck)[0];
    };
    $scope.getAltChannelOptions = function (mhz) {
        var listname = "";
        var configname = "";
        var channelCheck;
        switch (mhz) {
            case 5:
                listname = "channelListBbic";
                configname = $scope.config.key;
                channelCheck = function (ele) {
                    return ele["Center Frequency"][0] == Device.stageDevice[configname]["User Alt CenterFreq"];
                };
                break;
        }
        if (!Device.stats[listname] || !Device.stageDevice[configname]) {
            return;
        }
        return $scope.getChannels(mhz, true).filter(channelCheck)[0];
    };
    $scope.autoChannelChange = function () {
        if (Device.stageDevice[$scope.config.key]['Auto Channel']) {
            Device.stageDevice[$scope.config.key]['User Alt Enable'] = false;
        }
    };

    $scope.PowerRange = function (mhz) {
        var curChannel = $scope.getCurChannelOptions(mhz);
        if (!curChannel) {
            return;
        }
        var range = [];
        for (var i = curChannel['Tx Power'].Min; i <= curChannel['Tx Power'].Max; i++) {
            range.push(i);
        }
        return range;
    };

    $scope.Range = function (start, end) {
        var result = [];
        for (var i = start; i <= end; i++) {
            result.push(i);
        }
        return result;
    };

    $scope.getChannels = function (mhz, alt) {
        var listname = '';
        var configname = '';
        switch (mhz) {
            case 5:
                listname = "channelListBbic";
                configname = $scope.config.key;
                break;
            case 2.4:
                listname = "channelListNpu";
                configname = "radioConfigNpu";
                break;
        }
        if (!Device.stats[listname]) {
            return;
        }
        if (!Device.stageDevice[configname]) {
            return;
        }
        if (alt) {
            return Device.stats.channelListBbic['Channel List'].filter(function (ele) {
                return ele.BandWidth === Device.stageDevice[configname]['User Alt Bandwidth'];
            })[0]['Channel List'].filter(function (ch) {
                return ch.DFS === false;
            });
        } else {
            var chnList = Device.stats[listname]['Channel List'].filter(function (ele) {
                return ele.BandWidth === Device.stageDevice[configname]['Channel Width'];
            })[0]['Channel List'];
            if (listname == "channelListBbic") {
                $scope.DFSchnlength = chnList.filter(function (each) {
                    return each.DFS === false;
                }).length;
                if ($scope.DFSchnlength == 0) {
                    Device.stageDevice[$scope.config.key]['User Alt Enable'] = false;
                }
            }
            return chnList;
        }
    };

    $scope.getExclusionRange = function () {
        if (Device.realDevice.APDetails && Device.realDevice.APDetails.hw_ext_freq === 2) {
            return {min: 4900, max: 6425};
        } else {
            return {min: 4900, max: 6200};
        }
    };

    var restrictedChannels = [];

    $scope.getRestricted = function () {
        try {
            restrictedChannels = Device.stats.restricted.filter(function (ele) {
                if (ele.BandWidth === Device.realDevice[$scope.config.key]["Channel Width"]) {
                    return true
                }
                return false;
            })[0]["Band List"];
        } catch (e) {

        }

        return restrictedChannels;
    };

    $scope.validate = {
        popoverMessage: function () {
            var exclusionRange = $scope.getExclusionRange();
            return "Exclusion must be in the range of " + exclusionRange.min + " - " + exclusionRange.max + " and must not over lap other exclusions, restrictions or active channel.";
        },
        exclusionStart: function () {
            var isError = false;
            var restricted = $scope.getRestricted();
            var exclusionRange = $scope.getExclusionRange();
            $scope.inPreExclusionRangeStart = false;
            var offset = Device.stageDevice[$scope.config.key] ? Device.stageDevice[$scope.config.key]['Channel Width'] / 2 : 0;
            if ($scope.setExclusionStart == 0 || $scope.setExclusionStart) {
                var cond1 = (($scope.setExclusionStart > exclusionRange.max) || ($scope.setExclusionStart < exclusionRange.min)) ? true : false;
                var cond2 = $scope.setExclusionStart <= Device.stageDevice[$scope.config.key]["Center Frequency"] + offset && Device.stageDevice[$scope.config.key]["Center Frequency"] - offset <= $scope.setExclusionEnd;

                var cond3 = false;
                for (var i = 0; i < restricted.length; i++) {
                    if (!false && $scope.setExclusionStart.between(restricted[i].start, restricted[i].end, true)) {
                        cond3 = true;
                    }
                }
                if (Device.stageDevice[$scope.config.key] && Device.stageDevice[$scope.config.key].excludedBands) {
                    angular.forEach(Device.stageDevice[$scope.config.key].excludedBands, function (range) {
                        if ($scope.setExclusionStart >= range.start && $scope.setExclusionStart < range.end)
                            $scope.inPreExclusionRangeStart = true;
                    });
                }
                isError = (cond1 || cond2 || cond3 || $scope.inPreExclusionRangeStart);
            }
            return isError;
        },
        exclusionEnd: function () {
            var isError = false;
            var restricted = $scope.getRestricted();
            var exclusionRange = $scope.getExclusionRange();
            $scope.inPreExclusionRangeEnd = false;
            var offset = Device.stageDevice[$scope.config.key] ? Device.stageDevice[$scope.config.key]['Channel Width'] / 2 : 0;
            if ($scope.setExclusionEnd == 0 || $scope.setExclusionEnd) {
                var cond1 = (($scope.setExclusionEnd > exclusionRange.max) || ($scope.setExclusionEnd < exclusionRange.min)) ? true : false
                var cond2 = (($scope.setExclusionStart && ($scope.setExclusionStart <= exclusionRange.max && $scope.setExclusionStart >= exclusionRange.min)) && ($scope.setExclusionStart > $scope.setExclusionEnd)) ? true : false
                var cond3 = $scope.setExclusionStart <= Device.stageDevice[$scope.config.key]["Center Frequency"] + offset && Device.stageDevice[$scope.config.key]["Center Frequency"] - offset <= $scope.setExclusionEnd;
                var cond4 = false;
                for (var i = 0; i < restricted.length; i++) {
                    if (!false && $scope.setExclusionEnd.between(restricted[i].start, restricted[i].end, true)) {
                        cond4 = true;
                    }
                }

                if (Device.stageDevice[$scope.config.key] && Device.stageDevice[$scope.config.key].excludedBands) {
                    angular.forEach(Device.stageDevice[$scope.config.key].excludedBands, function (range) {
                        if ($scope.setExclusionEnd >= range.start && $scope.setExclusionEnd <= range.end)
                            $scope.inPreExclusionRangeEnd = true;
                    });
                }
                isError = (cond1 || cond2 || cond3 || cond4 || $scope.inPreExclusionRangeEnd)
            }

            return isError;
        }
    };

    $scope.addExclusion = function (setExclusionStart, setExclusionEnd) {

        if (!setExclusionStart || !setExclusionEnd) {
            alert('You must specify a start and end frequency to create a new exclusion.')
            return;
        } else {
            if (!Device.stageDevice[$scope.config.key].excludedBands) {
                Device.stageDevice[$scope.config.key].excludedBands = [];
            }
            Device.stageDevice[$scope.config.key].excludedBands.push({
                'start': parseInt(setExclusionStart),
                'end': parseInt(setExclusionEnd)
            });

            if(Device.stageDevice.radioConfigBbic["User Alt Enable"]){
                var altChnlFreqStrt = Device.stageDevice.radioConfigBbic["User Alt CenterFreq"] - (Device.stageDevice.radioConfigBbic["User Alt Bandwidth"] / 2);
                var altChnlFreqEnd = Device.stageDevice.radioConfigBbic["User Alt CenterFreq"] + (Device.stageDevice.radioConfigBbic["User Alt Bandwidth"] / 2);
                if((setExclusionStart >= altChnlFreqStrt && setExclusionStart <= altChnlFreqEnd) || (setExclusionEnd >= altChnlFreqStrt && setExclusionEnd <= altChnlFreqEnd)) {
                    Device.stageDevice.radioConfigBbic["User Alt Enable"] = false;
                }
            }
        }
        $scope.setExclusionStart = null;
        $scope.setExclusionEnd = null;
    };

    $scope.removeExclusion = function (excludedBand) {
        var index = Device.stageDevice[$scope.config.key].excludedBands.indexOf(excludedBand);
        if (confirm('Are you sure you want to remove this exclusion restriction from your spectrum usage?')) {
            Device.stageDevice[$scope.config.key].excludedBands.splice(index, 1);
        }
        else return;
    };

    $scope.powerThresholdCheck = function () {
        if (!Device.stageDevice.hasOwnProperty('radioConfigBbic')) {
            return;
        }
        var limit = productDef.hasFeature('powerWarningThreshold');
        if (Device.stageDevice[$scope.config.key]['Tx Power'] >= limit) {
            return true;
        }
        return false;
    };

    $scope.set2GChannelNumber = function () {
        var channels = $scope.getChannels(2.4);
        for (var key in channels) {
            var channel = channels[key];
            if (channel.Frequency == Device.stageDevice.radioConfigNpu["Center Frequency"]) {
                Device.stageDevice.radioConfigNpu["Channel Number"] = channel.Number;
                break;
            }
        }
    };

    $scope.$watch("Device.realDevice.wirelessMode", function (n, o) {
        if (!n) return;
        if (n.mode == "tdma") {
            $scope.config = {
                key: "radioConfigBbic",
                agc: "tdma_agc"
            }
        } else {
            $scope.config = {
                key: "radioConfigBbic",
                agc: "agc"
            }

        }
    }, true);

    $scope.$on("socket:timers", function (event, timers) {
        $scope.timers = unpack(timers);
    });

    $scope.$on("socket:stats", function (event, message) {
        message.data = unpack(message.data);
        if (message.key == "channelListBbic") {
            $scope.loadingChannels = false;
            $rootScope.$broadcast("enableSaveBar");

            var newChannels = $scope.getChannels(5);

            $scope.Device.stageDevice[$scope.config.key]['Center Frequency'] = returnClosestFreq(Device.stageDevice[$scope.config.key]['Center Frequency'], newChannels);
            var primaryChannels = $scope.getCurChannelOptions(5)['Primary Channel'];
            var foundPrimary = primaryChannels.filter(function (ele) {
                return Device.stageDevice[$scope.config.key]['Channel Number'] == ele[1];
            });
            if (foundPrimary.length == 0) {
                Device.stageDevice[$scope.config.key]['Channel Number'] = primaryChannels[0][1];
            }
        }
    });

    $scope.$on('socket:error_msg', function () {
        Device.socket.emit("get device");
    });

    $scope.$on("reset", function () {
        console.log('channel reset');
        if (Device.hasFeature("gain"))
            $scope.gainChange(Device.stageDevice[$scope.config.key]['Antenna Gain']);
    });

    $scope.$on("socket:successful", function () {
        $scope.DFS = false;
        $scope.DFSCheck('radioConfigBbic', Device.stageDevice.radioConfigBbic['Center Frequency']);
    });

    $scope.$watch("Device.stats.channelListBbic", function () {
        // When antenna gain is changed, new power limits are reflected in new channel list.
        var powerCollection = $scope.PowerRange(5);
        if(!Device.stageDevice.radioConfigBbic){
            return;
        }
        if (Device.stageDevice.radioConfigBbic['SWTPC Mode'] === 'AUTO') {
            // Set max power if SWTPC Mode is Auto
            Device.stageDevice.radioConfigBbic['Tx Power'] = powerCollection.slice(-1)[0];
        } else { // SWTPC Mode is Manual
            // update if current power is higher than new max limit
            if (Device.stageDevice.radioConfigBbic['Tx Power'] > powerCollection.slice(-1)[0]) {
                Device.stageDevice.radioConfigBbic['Tx Power'] = powerCollection.slice(-1)[0];
            }
        }
        $scope.gainChanging = false;
        $rootScope.$broadcast("enableSaveBar");
    });

    $scope.$watch(function (scope) {
        return scope.Device.stageDevice[$scope.config.key];
    }, function fiveGChange(n, o) {
        if (typeof n === "undefined") {
            return;
        }
        checkForBlank(n, 5);
    }, true);

    $scope.$watch("Device.stats.radioStatusBbic.radioConfigBbic", function (n, o) {
        if (typeof n === "undefined" || n === o) {
            return;
        }
        if (n) {
            switch (n["Change Reason"]) {
                case "Radar Hit":
                    $scope.changeReason = "Channel change caused by DFS radar event";
                    break;
                case "Auto Channel":
                    $scope.changeReason = "Channel change caused by Auto Channel";
                    break;
                default:
                    $scope.changeReason = false;
            }
        }

    }, true);

    $scope.$watch("Device.stageDevice['radioConfigBbic']['Channel Width']", function (n, o) {
        var newChannels = $scope.getChannels(5);
        if (o !== undefined) {
            $scope.Device.stageDevice['radioConfigBbic']['Center Frequency'] = returnClosestFreq(Device.stageDevice['radioConfigBbic']['Center Frequency'], newChannels);
        }
        if (Device.stageDevice.radioConfigBbic && Device.stageDevice.radioConfigBbic['SWTPC Mode']==='AUTO') {
            // Set MAX power for Beacon on BW change if SWTPC Mode is Auto
            var powerCollection = $scope.PowerRange(5);
            Device.stageDevice.radioConfigBbic['Tx Power'] = powerCollection.slice(-1)[0];
        }
    });

    $scope.$watch("Device.stageDevice['radioConfigBbic']['Center Frequency']", function (n, o) {
        if (o !== undefined) {
            var powerCollection = $scope.PowerRange(5);
            Device.stageDevice.radioConfigBbic['Tx Power'] = returnClosestPower(Device.stageDevice.radioConfigBbic['Tx Power'], powerCollection);
        }
        if (Device.stageDevice.radioConfigBbic && Device.stageDevice.radioConfigBbic['SWTPC Mode']==='AUTO') {
            // Set MAX power for Beacon on Channel change if SWTPC Mode is Auto
            var powerCollection = $scope.PowerRange(5);
            Device.stageDevice.radioConfigBbic['Tx Power'] = powerCollection.slice(-1)[0];
        }
    });

    $scope.$watch("Device.stageDevice['radioConfigBbic']['SWTPC Mode']", function (n, o) {
        if (n==='AUTO') {
            // Set MAX power for Beacon
            var powerCollection = $scope.PowerRange(5);
            Device.stageDevice.radioConfigBbic['Tx Power'] = powerCollection.slice(-1)[0];
        }
    });

    function figureOutOverride(n) {
        if (n < $scope.agc.opts[0].min || n > $scope.agc.opts[0].max) {
            $scope.agc.override = true;
        } else {
            $scope.agc.override = false;
        }
    }

    $scope.$on("base-loaded", function () {
        figureOutOverride(Device.stageDevice[$scope.config.agc].maxGain);
    });

    var $agclistner = $scope.$watch(function () {
        if (Device.stageDevice[$scope.config.agc])
            return Device.stageDevice[$scope.config.agc];
        else
            return undefined;
    }, function (n, o) {
        if (n !== undefined) {
            figureOutOverride(n.maxGain);
            $agclistner();
        }
    }, true);

    $scope.overridePrompt = function () {
        if ($scope.agc.override) {
            $scope.agc.override = false;
            return;
        }
        $rootScope.$broadcast("prompt", "Warning: Selecting values outside of the defined operating range (-75 to -50 dBm) may result in poor performance.", function () {
            $scope.agc.override = false;
        }, function () {
            $scope.agc.override = true;
        });
    };

    $scope.addClientPrompt = function () {
        if ($scope.Device.stageDevice[$scope.config.agc].isAddNewClients) {

            $rootScope.$broadcast("prompt", "Client Association Assist helps connect weaker clients to the access point. In this mode, interference mitigation is not optimized and it is not recommended to run the A5 in this mode for an extended period. Please turn off Client Association Assist after new clients are connected to optimize for interference avoidance. Client Association Assist will be automatically turned off after 24 hours.", function () {
                $scope.Device.stageDevice[$scope.config.agc].isAddNewClients = false;
            });
        }
    };

    function returnClosestFreq(oldFreq, newCollection) {
        var closest = undefined;
        for (var i = 0; i < newCollection.length; i++) {
            if (!closest) closest = newCollection[i]['Center Frequency'][0];
            else if (Math.abs(newCollection[i]['Center Frequency'][0] - oldFreq) < Math.abs(oldFreq - closest)) {
                closest = newCollection[i]['Center Frequency'][0];
            }
        }
        return closest;
    };

    function returnClosestPower(oldPower, newCollection) {
        var closest = undefined;
        for (var i = 0; i < newCollection.length; i++) {
            if (!closest) closest = newCollection[i];
            else if (Math.abs(oldPower - newCollection[i]) < Math.abs(oldPower - closest)) {
                closest = newCollection[i];
            }
        }
        return closest;
    };

    $scope.DFSCheck = function(key, n) {
        var channelListMap = [20, 40, 80];
        var chanWidthIndex = channelListMap.indexOf(Device.stageDevice[key]['Channel Width']);
        var newChannelValue = n;
        var channelList = Device.stats.channelListBbic["Channel List"][chanWidthIndex]["Channel List"];

        for (var i = 0; i < channelList.length; i++) {
            if (newChannelValue === channelList[i]["Center Frequency"][0]) {
                if (channelList[i]["DFS"] === true) {
                    $scope.DFS = true;
                } else {
                    $scope.DFS = false;
                    Device.stageDevice[$scope.config.key]['User Alt Enable'] = false;
                }
                break;
            }
        }
    };

    $scope.emptySave = function () {
        var func = DbusHandlers.radioConfigBbic;
        func(Device);

        var cmds = Device.getCommands();
        for (var i = 0; i < cmds.length; i++) {
            Device.socket.emit("set config", cmds[i]);
        }

        $rootScope.$broadcast("saving");
        $rootScope.$broadcast("enableSaveBar");

        $scope.$on("socket:successful", function () {
            $scope.acceptDefault = true;
        })
    };

    $scope.$watch("Device.stageDevice.radioConfigBbic['Center Frequency']", function (n, o) {
        if (!n) return;
        $scope.DFSCheck('radioConfigBbic', n);
    }, true);

    var gainCall = new RPCall({
        path: "/network/bbic/wireless/radio/antennaGain",
        interface: "system.config.global",
        method: "UpdateChannelListPower",
        target: "a5.bbic",
        args: {}
    }, Device);

    $scope.$watch("Device.stageDevice.radioConfigBbic['Antenna Gain']", function (n, o) {
        if (n === o || o === undefined) {
            return;
        }
        if (!Device.hasFeature("gain"))
             return;
        $scope.gainChanging = true;
        $rootScope.$broadcast("disableSaveBar");
        gainCall.setArgs({
            "Antenna Gain": n
        });
        gainCall.call();
    });

    //trigger modal for Frequency Extension Mode
    $scope.$watch("Device.stageDevice.radioConfigBbic['Extension Enable']", function (n, o) {
        if (n === o || o === undefined) {
            return;
        }
        if (n !== undefined) {
            $scope.loadingChannels = true;
            $rootScope.$broadcast("disableSaveBar");
            Device.emit("dbus send", {
                path: '/network/bbic/wireless/radio/extendedFreqEnable',
                interface: 'system.config.global',
                name: "extendedFreqEnable",
                json: {
                    extendedFreqEnable: Device.stageDevice.radioConfigBbic['Extension Enable'],
                    updateChanList: true
                },

            });
        }

        if (n && n !== Device.realDevice.radioConfigBbic['Extension Enable']) {
            var msg;
            if (Device.realDevice.wirelessMode.mode === 'csma') {
                msg = "Warning: Enabling Extended Frequency Operation in Wifi-Interop does not support third party clients. Clients may be disconnected!";
            } else if (Device.realDevice.wirelessMode.mode === 'tdma') {
                msg = "Warning: Extended Frequency Operation in SRS may not be supported by some clients. Those clients may be disconnected!";
            }
            $rootScope.$broadcast('prompt', msg, function () {
                // called on use cancel
                Device.stageDevice[$scope.config.key]['Extension Enable'] = angular.copy(Device.realDevice[$scope.config.key]['Extension Enable']);
            }, function () {
                // called on user okay, do nothing -> modal closes
            })
        }
    });
}).directive('cpInput', function () {
    return {
        scope: {
            label: "@",
            unit: "@",
            subclass: "=",
            sublabel: "@",
            loading: "="
        },
        transclude: true,
        template: '<div class="col-sm-6"><div class="form-group"><label class="col-md-6 control-label">{{label}} <span ng-if="!unit" class="mimo-gr2">&nbsp;</span><span ng-if="unit" class="mimo-gr2">({{unit}})</span><div ng-if="sublabel" class="mimo-gr2 subText" ng-class="subclass">{{sublabel}} {{unit}}</div></label><div class="col-md-6" ng-if="loading"><p class="form-control-static">Loading...</p></div><div class="col-md-6" ng-if="!loading"><div ng-transclude></div></div></div></div>'
    }
}).filter('cpDisplay', function () {
    return function (channel) {
        var label = channel['Center Frequency'][0];

        if (channel.DFS) {
            label += " DFS";
        }

        if (channel.Radar) {
            label += " Blocked";
        }

        return label;
    }
});
