/**
 * Created by cdavis on 9/28/15.
 */

angular.module('MimosaApp').controller('DisconnectController', ['Device', '$scope', function(Device, $scope) {
    $scope.disconnected = false;
    $scope.$on('socket:disconnect', function() {
        $scope.disconnected = true;
    });
    /**
     * This will remove the disconnected message. Right now, when the device reconnects, we refresh the login page but this is just in case for the future.
     */
    $scope.$on("socket:connect", function() {
        $scope.disconnected = false;
    })
}]);