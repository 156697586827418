'use strict';

angular.module('MimosaApp').controller('ManagementPageController', ['Device', '$scope', '$controller', 'growl', '$rootScope', '$uibModal', function (Device, $scope, $controller, growl, $rootScope, $uibModal) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;

    $scope.appPriority = {
        apps: {
            "bitTorrent": "BitTorrent",
            "http": "HTTP/HTTPS",
            "icmp": "ICMP",
            "vpn": "VPN",
            "youTube": "YouTube"
        },
        slider: {
            showSelectionBar: true,
            getSelectionBarColor: function (value) {
                return ["#9EA2A2", "#839FB3", "#699CC4", "#4F99D5", "#3597E7"][value];
            },
            getPointerColor: function (value, pointerType) {
                return ["#9EA2A2", "#839FB3", "#699CC4", "#4F99D5", "#3597E7"][value];
            },
            translate: function (value) {
                return ["Off", "Low", "Medium", "High", "Highest"][value];
            },
            stepsArray: [{value: 0, legend: 'Off'}, {value: 1, legend: 'Low'}, {value: 2, legend: 'Medium'}, {
                value: 3,
                legend: 'High'
            }, {value: 4, legend: 'Highest'}]
        }
    };

    $scope.isPageValid = function () {
        if (Device.stageDevice.mgmtVlan.vlanStatus) {
            if (!Device.stageDevice.mgmtVlan.vlan) {
                throw {
                    name: "Invalid VLAN ID",
                    message: "Enter a Valid VLAN ID"
                };
            }
        } else {
            if (Device.stageDevice.mgmtVlan.vlanStatus == Device.realDevice.mgmtVlan.vlanStatus) {
                if (Device.stageDevice.mgmtVlan.vlan) {
                    throw {
                        name: "Invalid VLAN ID",
                        message: "Management Vlan is not enabled."
                    };
                }
            }
        }
        for (var i = 0; i < Device.stageDevice.ssids5.length; i++) {
            var ssid = Device.stageDevice.ssids5[i];
            if (ssid.Vlan == Device.stageDevice.mgmtVlan.vlan) {
                $scope.VLANID.$valid = false;
                throw {
                    name: "Invalid VLAN ID",
                    message: "VLAN ID must not match a SSID VLAN ID"
                };
            }
        }
        if (!$scope.IPV4.$valid) {
            throw {
                name: 'Invalid form',
                message: 'Management section has errors. Please make sure that the Gateway and Management IP are on the same subnet.'
            };
        }
        if (!$scope.VLANID.$valid) {
            throw {
                name: "Invalid VLAN Protocol",
                message: "VLAN Protocol is invalid"
            };
        }
        if (!$scope.RogueDHCP.$valid) {
            throw {
                name: "Invalid Rogue DHCP Server",
                message: "There was an invalid rogue DHCP server specified."
            }
        }

        if(!$scope.ServicesForm.$valid) {
            throw {
                name: "Invalid HTTP/HTTPS port specified",
                message: "Invalid HTTP/HTTPS port specified"
            }
        }

        if (Device.stageDevice.nms.cloudConnect == 2) {
            var cgIP = Device.stageDevice.nms.cloudGatewayIP;
            var isIP4 = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(cgIP);
            var isIP6 = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/i.test(cgIP);
            var isHost = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/i.test(cgIP);
            if ( !(isIP4 || isIP6 || isHost) ) {
                throw {
                    name: "Invalid Cloud Gateway Address",
                    message: "Invalid Cloud Gateway Address"
                }
            }
        }
        

    };
    $scope.$watch("Device.stageDevice.mgmtVlan.vlanStatus", function (n, o) {
        if (n === o) {
            return;
        }
        if (Device.stageDevice.mgmtVlan.vlanStatus == Device.realDevice.mgmtVlan.vlanStatus) {
            return;
        }
        if (Device.stageDevice.mgmtVlan.vlanStatus) {
            var message = "Upon activating the Management VLAN, the GUI of this device will become inaccessible over Ethernet, unless connecting over a network configured with a matching VLAN number.";
            $rootScope.$broadcast("prompt", message, function () {
                Device.stageDevice.mgmtVlan.vlanStatus = angular.copy(Device.realDevice.mgmtVlan.vlanStatus);
                $scope.disconnect = false;
            }, function () {
                $scope.disconnect = true;
            });
        }
    });
    $scope.$watch("Device.stageDevice.jumboPkt.jumboPktStatus", function (n, o) {
        if (n === o) {
            return;
        }
        if (Device.stageDevice.jumboPkt.jumboPktStatus == Device.realDevice.jumboPkt.jumboPktStatus) {
            return;
        }
        if (Device.stageDevice.jumboPkt.jumboPktStatus) {
            var message = "Please ensure that northbound switches are configured for 2474 byte MTU to match this feature. Otherwise, Enterprise SSID association may fail.";
            $rootScope.$broadcast("prompt", message, function () {
                Device.stageDevice.jumboPkt.jumboPktStatus = angular.copy(Device.realDevice.jumboPkt.jumboPktStatus);
                $scope.disconnect = false;
            }, function () {
                $scope.disconnect = true;
            });
        }
    });
    $scope.cloudToggle = function () {
        RPCall({}, Device);
        var call = new RPCall({
            path: "/system/nmsclient/config",
            interface: "nms.client.ifc",
            method: "nmsCloudMgmt",
            target: "nms.client",
            args: {
                nmsCloudMgmtStatus: Device.stats.nms.nmsCloudMgmtStatus
            }
        }, Device);
        call.call();
        if (Device.stats.nms.nmsCloudMgmtStatus) {
            var message = "Cloud management enabled.";
        } else {
            var message = "Cloud management disabled.";
        }
        growl.info(message);

    };

    $scope.ipModeOptions = [{
        name: 'DHCP + Static Failover',
        value: 'dhcp'
    }, {
        name: 'Static',
        value: 'static'
    }];

    $scope.option82CircuitIDSelection = [ {
        name: 'Disable',
        value: 3
    },{
        name: 'AP MAC Address',
        value: 0
    }, {
        name: 'CPE MAC Address',
        value: 1
    },{
        name: 'AP Device Name',
        value: 4
    }, {
        name: 'Custom ID',
        value: 2
    }];

    $scope.option82RemoteIDSelection = [{
        name: 'Disable',
        value: 3
    }, {
        name: 'AP MAC Address',
        value: 0
    }, {
        name: 'CPE MAC Address',
        value: 1
    },{
        name: 'CPE Device Name',
        value: 4
    }, {
        name: 'Custom ID',
        value: 2
    }];

    $scope.option82FormatOptions = [{
        name: 'XX:XX:XX:XX:XX:XX',
        value: 0
    }, {
        name: 'XX-XX-XX-XX-XX-XX',
        value: 1
    }, {
        name: 'XXXXXXXXXXXX',
        value: 2
    }];

    $scope.option82EncodeOptions = [{
        name: 'ASCII',
        value: 0
    }, {
        name: 'BINARY',
        value: 1
    }];

    $scope.cloudOptions = [{
        name: 'Disabled',
        value: 0
    }, {
        name: 'Mimosa Managed Cloud',
        value: 1
    }, {
        name: 'Self-Hosted',
        value: 2
    }];

    $scope.addDhcpToDevice = function (name) {
        if (Device.stageDevice.dhcpRogueServer) {
            var dhcpServerList = Device.stageDevice.dhcpRogueServer.validDhcpServerList;
            if (dhcpServerList && dhcpServerList.length < 5) {
                Device.stageDevice.dhcpRogueServer.validDhcpServerList.push(name)
            }
        }
        else {
            console.error('Rogue DHCP server protection feature is not enabled for this device');
        }
    };

    $scope.editDhcpOnDevice = function (dhcpObject) {
        var name = dhcpObject.name,
            serverIndex = dhcpObject.index;
        if (Device.stageDevice.dhcpRogueServer) {
            Device.stageDevice.dhcpRogueServer.validDhcpServerList[serverIndex] = name;
        }
        else {
            console.error('Unable to edit DHCP Server.');
        }
    };

    $scope.open = function (addOrEdit, dhcpToChange) {
        var modalInstance = $uibModal.open({
            templateUrl: 'dhcpModalContent.html',
            controller: 'dhcpModalInstanceCtrl',
            resolve: {
                Device: function () {
                    return $scope.Device;
                },
                addDhcpToDevice: function () {
                    return $scope.addDhcpToDevice;
                },
                editDhcpOnDevice: function () {
                    return $scope.editDhcpOnDevice;
                },
                modalConfig: function () {
                    return {
                        addOrEdit: addOrEdit,
                        dhcpToChange: dhcpToChange
                    };
                }
            }
        });
    };
    $scope.prompthttps = function () {
        if (Device.stageDevice.sslEnabled != Device.realDevice.sslEnabled) {
            $rootScope.$broadcast("prompt", "Your browser should automatically redirect within 5 seconds after enabling/disabling HTTPS. If it does not, please manually enter the new URL in the browser address bar.", function () {
                Device.stageDevice.sslEnabled = Device.realDevice.sslEnabled;
            });
        }
    }

    $scope.$on("post save", function () {
        if (!angular.equals(Device.stageDevice.webServer, Device.realDevice.webServer)) {
            Device.emit('restart');
            if (Device.stageDevice.webServer.HTTPSEnabled) {
                var port = Device.stageDevice.webServer.HTTPSPort;
                var redirect = "https://" + window.location.hostname + ":" + port + "/logout";
            } else {
                var port = Device.stageDevice.webServer.HTTPPort;
                var redirect = "http://" + window.location.hostname + ":" + port + "/logout";
            }
            setTimeout(function () {
                location.href = redirect;
            }, 5000);
        }
    });
}]);
