/**
 * Created by thegagan on 5/14/15.
 */

var DOC = $(document);

// ----------------- GENERIC ADD NEW TEMPLATE ROW -------------------------------------
// ------------------------------------------------------------------------------------
//
//$('body').on('click', '.templateInstanceAdd', function () {
//    $('.template')
//        .clone()
//        .removeClass('template')
//        .insertBefore('.template')
//        .animate({width: 'toggle', opacity: '0'}, 0)
//        .animate({width: 'toggle', opacity: '1'}, 300);
//});
//$('body').on('click', '.templateInstanceRemove', function () {
//    $(this).parents('.templateInstance').hide(300);
//});

// ----------------- INITIALIZE TOOLTIPS & POPOVERS -----------------------------------
// ------------------------------------------------------------------------------------
// works on all static and dynamic elements

$(document).ready(function() {

    $('body').tooltip({
        selector: '[data-toggle="tooltip"]',
        html: true
    });

    $('body').popover({
        selector: '[data-toggle="popover"]',
        html: true,
        content: function() {
            return $('.popover-content').html();
        }
    });

    $('body').on('click', function(e) {
        if ($(e.target).data('toggle') !== 'popover' &&
            $(e.target).parents('.popover.in').length === 0) {
            $('[data-toggle="popover"]').popover('hide');
        }
    });
});

// ----------------- FILE UPLOAD ------------------------------------------------------
// ------------------------------------------------------------------------------------

DOC.on('change', '.btn-file :file', function() {
    var input = $(this),
        label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', label);
});

