'use strict';

mimosaApp.service('Device', function ($timeout, $rootScope, a5Socket, productDef, $http) {

    var initiated = false;
    var batchcommand = [];
    var Device = {
        numChanges: 0,
        socket: a5Socket,
        stats: {},
        stageDevice: {}, // Angular commits changes to this object.
        realDevice: {}, // This is the latest device state
        is2_4GHzAvailable: false,
        hasFeature: function (feature) {
            return productDef.hasFeature(feature);
        },
        getCommands: function () {
            return batchcommand;
        },
        setCfg: function (path, object) {
            var dataToSend = {
                'path': path,
                'configData': object
            };
            console.info('Device: Set Config', dataToSend);
            batchcommand.push(dataToSend);
        },
        save: function (callBack) {
            this.saving = true;
            var self = this;

            // Get a diff of the device state
            var diff = odiff(angular.copy(this.realDevice), angular.copy(this.stageDevice));
            var handlersCalled = [];

            for (var i = 0; i < diff.length; i++) {
                var block = diff[i];
                var section = block.path[0];
                if (handlersCalled.indexOf(section) !== -1) {
                    continue;
                }
                if (typeof DbusHandlers[section] === 'function') {
                    DbusHandlers[section](self);
                    handlersCalled.push(section);
                } else {
                    throw new Error("Dbus handler is missing for:" + section);
                }
            }
            for (var i = 0; i < batchcommand.length; i++) {
                a5Socket.emit("set config", batchcommand[i]);
            }
            $rootScope.$broadcast("saving");
            $rootScope.$broadcast("enableSaveBar");
            batchcommand = [];
            $timeout(angular.noop);
        },
        emit: function (signal, data) {
            a5Socket.emit(signal, data);
        },
        reboot: function () {
            $http.get('/reboot');
            setTimeout(function () {
                a5Socket.disconnect(true);
            }, 100);
        },
        diff: function () {
            var oldDevice = angular.copy(this.realDevice);
            var newDevice = angular.copy(this.stageDevice);
            return odiff(oldDevice, newDevice);

        }
    };

    /**
     * Updates UI product name based upon product code
     * @param data
     * @constructor
     */
    function UpdateProduct(data) {

        if (!productDef.setProduct(data.APDetails.product_id)) {
            // Product was already set.
            return;
        }

        // Change page title and device type text
        $(".deviceType").text(productDef.getDef().name);

        var title = "Mimosa " + productDef.getDef().name;
        if (data.hasOwnProperty('hostname') && data.hostname !== "mimosaA5")
            title = productDef.getDef().name + " - " + data.hostname;

        document.title = title;
    }

    var handlers = {
        Connect: function (event) {
            if (Device.hasOwnProperty('reloadOnReconnect') && Device.reloadOnReconnect) {
                delete Device.reloadOnReconnect;
                $rootScope.$broadcast('reload');
            }
            initiated = false;
            a5Socket.emit('base');
        },

        UpdateStats: function (event, message) {
            Device.stats[message.key] = unpack(message.data);
        },
        SetBase: function (event, message) {
            var message = unpack(message);
            console.log("initial conf and stats received");
            UpdateProduct(message.conf);
            Device.realDevice = angular.copy(message.conf);
            Device.stageDevice = angular.copy(message.conf);
            Device.numChanges = 0;

            // Hack for Turbo Performance not existing

            if (!Device.realDevice.nms.hasOwnProperty('Turbo Performance')) {
                Device.realDevice.nms['Turbo Performance'] = false;
                Device.stageDevice.nms['Turbo Performance'] = false;
            }

            if (Device.realDevice.hasOwnProperty('ssids2') && Device.realDevice.hasOwnProperty('radioConfigNpu')) {
                if ($.isEmptyObject(Device.realDevice.radioConfigNpu) && $.isEmptyObject(Device.realDevice.ssids2)) {
                    Device.is2_4GHzAvailable = false;
                } else {
                    Device.is2_4GHzAvailable = true;
                }
            }
            if ($.isEmptyObject(Device.stageDevice.subscribers)) {
                Device.realDevice.subscribers = [];
                Device.stageDevice.subscribers = [];
            }
            if ($.isEmptyObject(Device.stageDevice.deviceDescription)) {
                Device.realDevice.deviceDescription = "";
                Device.stageDevice.deviceDescription = "";
            }
            Device.stats = angular.copy(message.stats);
            $(".deviceName").text(Device.realDevice.hostname);
            $rootScope.$broadcast("base-loaded");
        }
    };
    $rootScope.$on('socket:connect', handlers.Connect);
    $rootScope.$on('socket:stats', handlers.UpdateStats);
    //$rootScope.$on('socket:device', handlers.SetState);
    $rootScope.$on('socket:base', handlers.SetBase);
    return Device;
});
