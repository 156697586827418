'use strict'

angular.module('MimosaApp').controller('dhcpModalInstanceCtrl', ["$scope", "$uibModalInstance", "Device", "addDhcpToDevice", "modalConfig", "editDhcpOnDevice", function($scope, $uibModalInstance, Device, addDhcpToDevice, modalConfig, editDhcpOnDevice) {
    $scope.close = function() {
      $uibModalInstance.close();
    };
    $scope.Device = Device;
    $scope.duplicateServer = false;
    $scope.modalState = function (config) {
        var configObject = {
            addOrEdit: null,
            dhcpToChange: null
        };
        if (config.addOrEdit === "edit") configObject.addOrEdit = "Edit";
        else if (config.addOrEdit === "add") configObject.addOrEdit = "Add";
        else {
            console.error('Please specify modal state as either ADD or EDIT');
        }

        if (!config.dhcpToChange) configObject.dhcpToChange = {name: null};
        else {
            configObject.dhcpToChange = config.dhcpToChange;
        }
        return configObject;
    }(modalConfig);

    $scope.addDhcpToDevice = addDhcpToDevice;
    $scope.editDhcpOnDevice = editDhcpOnDevice;

    $scope.$watch("modalState.dhcpToChange.name", function(n, o) {
        var currentServers = Device.stageDevice.dhcpRogueServer.validDhcpServerList
        if(n !== o && Array.isArray(currentServers)) {
            if (currentServers.indexOf(n) > -1) {
                $scope.dhcpForm.dhcpip.$setValidity("duplicateIp", false);
                $scope.dupeServer = true;
            }
            else {
                $scope.dhcpForm.dhcpip.$setValidity("duplicateIp", true);
                $scope.dupeServer = false
            }
        }
    });
}]);