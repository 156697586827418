mimosaApp.service('Sites', function (Device) {

    var call = new RPCall({
        path: "/network/bbic/wireless/siteSurveyStart",
        interface: "system.config.global",
        method: "siteSurveyStart",
        target: "a5.bbic",
        args: {"startTimer" : true}
    }, Device);

    var timer;

    var obj = {
        lastReceived: false,
        timedout: false,
        surveying: false,
        start: function () {
            if(obj.surveying) return;
            obj.timedout = false;
            obj.surveying = true;
            call.call();
            /* In configmgr/radiohandler.py, ssTimeout for csma is 10 seconds,
             * for tdma is 20 seconds, 25 seconds should be enough here */
            timer = setTimeout(function() {
                obj.surveying = false;
                obj.timedout = true;
            }, 25000)
        },
        received: function() {
            obj.surveying = false;
            obj.timedout = false;
            console.log("results");
            clearTimeout(timer);
        }
    };

    return obj;
});
