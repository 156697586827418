angular.module('MimosaApp').controller('DiagnosticsPageController', ['$scope', '$controller', 'Device', 'FileUpload', function ($scope, $controller, Device, FileUpload) {
    $controller('PageController', {$scope: $scope, Device: Device});

    /*
        ///////////////////////////////////////////////////////////////////////////
        ping test
        ///////////////////////////////////////////////////////////////////////////
    */
    $scope.pingHost = "8.8.8.8";
    $scope.pingCount = 20;
    $scope.pingSize = 64;
    $scope.pingLines = "Ping test results will be displayed here";
    $scope.pingUpdateState = function (event, message) {
        var message = unpack(message);
        $scope.pingLines += message.Data;
    };
    $scope.$on("socket:ping", $scope.pingUpdateState);
    $scope.startPing = function () {
        $scope.pingLines = "";
        Device.emit("start ping", {
            "host": $scope.pingHost,
            "count": $scope.pingCount,
            "size": $scope.pingSize
        });
    };

    /*
        ///////////////////////////////////////////////////////////////////////////
        traceroute test
        ///////////////////////////////////////////////////////////////////////////
    */
    $scope.tracerouteHost = "127.0.0.1";
    $scope.hops = 30;
    $scope.resolveIP = false;

    $scope.tracerouteLines = "Traceroute test results will be displayed here";
    $scope.tracerouteUpdateState = function (event, message) {
        var message = unpack(message);
        $scope.tracerouteLines += message.Data;
    };
    $scope.$on("socket:traceroute", $scope.tracerouteUpdateState);
    $scope.startTraceroute = function () {
        $scope.tracerouteLines = "";
        Device.emit("start traceroute", {
            host: $scope.tracerouteHost,
            hops: $scope.hops,
            resolveIP: $scope.resolveIP
        });
    };

    /*
        ///////////////////////////////////////////////////////////////////////////
        Cloud test
        ///////////////////////////////////////////////////////////////////////////
     */
    $scope.cloudLines = "Cloud test results will be displayed here";
    $scope.cloudUpdateState = function (event, message) {
        var message = unpack(message);
        $scope.cloudLines += message.Data;
    };
    $scope.$on("socket:cloud", $scope.cloudUpdateState);
    $scope.startCloud = function () {
        $scope.cloudLines = "";
        Device.emit("start cloud", {
        });
    };

    /*
        ///////////////////////////////////////////////////////////////////////////
        bandwidth test
        ///////////////////////////////////////////////////////////////////////////
    */

    $scope.bwDuration = "";
    $scope.bwRemoteDevice = "";
    $scope.bwLines = "Bandwidth test results will be displayed here";
    $scope.bwUpdateState = function (event, message) {
        var message = unpack(message);
        $scope.bwLines += message.Data;
    };
    $scope.$on("socket:bandwidth", $scope.bwUpdateState);
    $scope.startBw = function () {
        $scope.bwLines = "";
        Device.emit("start bandwidth", angular.toJson({
            "duration": $scope.bwDuration,
            "remoteDevice": $scope.bwRemoteDevice,
        }, true));
    };
}]);
