angular.module('MimosaApp').service('FileUpload', ['$rootScope', 'SessionTimeoutService', function ($rootScope, SessionTimeoutService) {
    var uploader;
    var uploadData = {
        uploading: false,
        cancelUpload: function(key) {
            if(uploader) uploader.abort();
            this.uploading = false;
            $rootScope.$broadcast("upload:cancel", key);
        },
        reset: function(key) {
            this[key] = {
                percent: -1
            }
        },
        uploadFileToUrl: function (file, uploadUrl, key) {
            this.reset(key);
            this.uploading = true;
            uploader = new Chunkify(file, {
                url: uploadUrl,
                postSend: function() {
                    SessionTimeoutService.reset();
                },
                progress: function(percent) {
                    if(percent >= 100)
                        percent = 100;
                    uploadData[key].percent = percent;
                },
                complete: function() {
                }
            });
            uploader.start();
        }
    };
    return uploadData;
}]);