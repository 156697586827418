var BaseDevice = function () {
    /**
     * Base features. Probably want to set everything to True and then sub products to set things to false. We'll see
     */
    this.features = {
        gain: true,
        powerWarningThreshold: 18 // This is the 'default' power warning threshold. Override it with below products.
    };
    this.name = "Base Device";
};

function A5() {
    BaseDevice.call(this);
    this.features.gain = false;
    this.name = "A5";
}
function A5c() {
    BaseDevice.call(this);
    this.name = "A5c";
}
function A518() {
    BaseDevice.call(this);
    this.features.gain = false;
    this.name = "A5-18";
}
function A514() {
    BaseDevice.call(this);
    this.features.gain = false;
    this.name = "A5-14";
}

mimosaApp.service('productDef', function () {
    var product;
    var obj = {};
    obj.setProduct = function (id) {
        if(product) {
            return false;
        }
        switch (id) {
            case "A01":
                product = new A518();
                break;
            case "A02":
                product = new A5();
                break;
            case "A03":
                product = new A514();
                break;
            case "A04":
                product = new A5c();
                break;
            default:
                throw "Undefined product: "+id;
                break;
        }
        return true;
    };
    obj.hasFeature = function(feature) {
        if(!product) {
            console.info("No product configured");
            return;
        }
        if(product.features.hasOwnProperty(feature)) {
            return product.features[feature];
        }

    };
    obj.getDef = function() {
        return product;
    };
    return obj;
});