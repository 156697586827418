angular.module('MimosaApp').controller('PageController', ['Device', '$scope', '$rootScope', '$uibModal', 'growl', '$timeout', 'productDef', function (Device, $scope, $rootScope, $modal, growl, $timeout, productDef) {
    var vm = this;
    vm.accessor = {};

    $scope.changeCount = 0;

    $scope.installDateOpened = false;

    $scope.openInstallDate = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.installDateOpened = true;
    };
    $scope.isPageValid = function () {
        console.warn('This page has no validation. Assuming valid.');
        return true;
    };
    $scope.PreSave = function () {
    };

    $scope.$on('reload', function () {
        window.location.reload(true);
    });

    $scope.$on('socket:error_msg', function (path, errReason) {

        $modal.open({
            templateUrl: 'app/partials/dbuserrormodal.html',
            controller: 'DbusErrorController',
            resolve: {
                reason: function () {
                    return unpack(errReason);
                },
                path: function () {
                    return path;
                }
            }
        });
    });

    /**
     * This is a fancy way of merging the two objects together. We do this instead of assignment so Angular's digest will recognize changes.
     */

    $scope.$on('reset', function () {
        $timeout(function () {
            console.log('page reset');
            Device.socket.emit("base");
            growl.info("Device configuration reset.");
        }, 10);
    });
    $scope.$on('commit', function () {
        try {
            $scope.isPageValid();
            $scope.PreSave();
            Device.save();
            $scope.$broadcast("post save");
            $scope.changeCount = 0;
            Device.numChanges = 0;
        } catch (e) {
            growl.error("There was an error: " + e.message);
            $rootScope.$broadcast('alert:error', e);
            $rootScope.$broadcast('enableSaveBar');
        }
    });
    function checkForChanges() {
        var diff = Device.diff();
        var i = diff.length;
        while (i--) {
            var tmp = diff[i];
            /**
             * this needs to be moved to something better than an array search
             */
            if (tmp.path.indexOf("intf") !== -1) {
                diff.splice(i, 1);
            }
            if (tmp.path.indexOf("Change Reason") !== -1) {
                diff.splice(i, 1);
            }
            if (tmp.path.indexOf("radioConfigNpu") !== -1 && tmp.path.indexOf("Channel Number") !== -1) {
                diff.splice(i, 1);
            }
        }
        Device.numChanges = diff.length;
    }

    $scope.$watch(function () {
        return Device.stageDevice;
    }, function (newValue, oldValue) {
        if (newValue === oldValue) {
            // We're being initialized
            return;
        }

        if (Device.skipBar) {
            Device.skipBar = false;
            return;
        }

        checkForChanges();
    }, true);

    function UpdateState(event, message) {
        message.data = unpack(message.data);
        // If message comes from subscriber plans and user is NOT on the subscriber plan page, skip the save bar trigger but still update the data.
        if ((message.key == "subscribers" || message.key == "subscriberPlans") && window.location.pathname == "/subscriber-plans") {
            Device.skipBar = true;
        }

        Device.realDevice[message.key] = angular.copy(message.data);
        Device.stageDevice[message.key] = angular.copy(message.data);

        if (message.key === 'hostname') {
            document.title = productDef.getDef().name + " - " + message.data;
        }

        checkForChanges();
        $scope.$apply();
    }

    $scope.$on('socket:update', UpdateState);

}]);
