"use strict"

angular.module('MimosaApp').directive('fwtTable', ["Clients", function (Clients) {
    return {
        restrict: 'E',
        templateUrl: 'app/partials/fwt-table.html',
        controller: function ($scope, Device) {
            $scope.Device = Device;
            $scope.sortType = "mac";
            $scope.sortReverse = false;
            $scope.searchMac = '';

            $scope.show_wireless = true;
            $scope.show_ethernet = true;
            $scope.Clients = Clients;

            $scope.mapPort = function(mac) {
                if(mac === "00:00:00:00:00:00") {
                    return "Ethernet";
                }

                return "Wireless";
            };

            $scope.getTotalMac = function () {
                return Device.stats.fwtlist.length
            };

            // for the given station mac address, it returns the hostname of the station.
            $scope.getStationName = function(stationMac) {

                for (var station in Clients.list) {
                    // check if the Clients.list has the station we are interested.
                    if (stationMac === station) {
                        // Station is found, return the user friendly name for it.
                        if(Clients.list[station].Name)
                            return Clients.list[station].Name;
                        else
                            return stationMac;
                    }
                }

                // return back the client
                return stationMac;
            };

            $scope.clientMappings = function(value) {
                if (value == '00:00:00:00:00:00')
                    return "--";

                return $scope.getStationName(value);

            };

            $scope.getVlan = function(value) {
                if (value == '1')
                    return "--";

                return value;
            };

            $scope.removeMacs = function(item) {
                if($scope.show_ethernet && item[3] === "00:00:00:00:00:00") {
                    return true;
                }

                if($scope.show_wireless && item[3] !== "00:00:00:00:00:00") {
                    return true;
                }
                return false;
            };

            $scope.changeToClient = function(mac) {
                $("#clientTabs a:first").tab('show');
                $scope.$parent.tab = 'list';
                $scope.$parent.toggle[mac] = true;
            };

            $scope.fwtSort = function (fwt) {
                switch ($scope.sortType) {
                    case "mac":
                        return fwt[0];
                        break;
                    case "age":
                        return parseInt(fwt[1]);
                        break;
                    case "port":
                        return fwt[3] === "00:00:00:00:00:00" ? -1 : 0;
                        break;
                    case "cmac":
                        return fwt[3];
                        break;
                    case "vlan":
                        return parseInt(fwt[4]);
                        break;
                }
            };
        }
    }
}]);
