angular.module('MimosaApp').controller('EthernetstatsPageController', ['$scope', '$controller', 'Device', '$rootScope', '$location', 'UIDB', function($scope, $controller, Device) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;

    var reset = new RPCall({
        path: "/network/npu/wanstats",
        interface: "system.npu.stats",
        method: "npuIntfWanStatsReset",
        target: "npu.intf",
        args: {}
    }, Device);

    $scope.resetCounters = function() {
        reset.call();
    }
}]);
