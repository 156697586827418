mimosaApp.service('SSIDType', function ($rootScope, Device) {
    var ssidTypes = [
        {
            value: "CPE",
            display: "Fixed/CPE",
            features: {
                SSIDVLAN: true,
                CPEVLAN: true,
                BCAST: false
            },
            intfs: [5],
            modes: ["tdma", "csma"]
        }, {
            value: "Hotspot",
            display: "Hotspot",
            features: {
                SSIDVLAN: true,
                CPEVLAN: false,
                BCAST: true
            },
            intfs: [5],
            modes: ['csma']
        }, {
            value: "Hotspot",
            display: "Hotspot",
            features: {
                SSIDVLAN: false,
                CPEVLAN: false,
                BCAST: true
            },
            intfs: [2],
            modes: ['csma', 'tdma']
        }, {
            value: "Trunk",
            display: "Trunk",
            features: {
                SSIDVLAN: false,
                CPEVLAN: false,
                BCAST: false
            },
            intfs: [5],
            modes: ['tdma', 'csma']
        }
    ];


    return {
        getObject: function (type, intf) {
            var types = this.filter([Device.realDevice.wirelessMode.mode, intf]);
            for (var i in types) {
                if (types[i].value == type) {
                    return types[i];
                }
            }
        },
        filter: function (filter) {
            return ssidTypes.filter(function (value) {
                function filterValue(parameter) {
                    for (var i in value[parameter]) {
                        if (filter.indexOf(value[parameter][i]) != -1) {
                            return true;
                        }
                    }
                }

                if (filterValue('intfs') && filterValue('modes')) {
                    return true;
                } else {
                    return false;
                }
            });
        }

    };
});
