'use strict';

angular.module('MimosaApp').controller('VlanPageController', ['$scope', '$controller', 'Device', '$rootScope', "$uibModal", function($scope, $controller, Device, FileUpload, $rootScope, $uibModal) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;

    $scope.gotoUrl = function(id, event) {
        ga('send', 'pageview', id);
        if(Device.numChanges > 0) {
            event.preventDefault();
        } else {
            history.pushState('', '', id);
        }
    };
}]);


