'use strict';

/**
 * This generates the main navigation.
 */
angular.module('MimosaApp').controller('NavigationController', ['$scope', 'Device', function ($scope, Device) {
    /**
     * Panels is the top level of the navigation, the 'sections'.
     *
     *
     * @type {*[]}
     */
    $scope.panels = [{
        text: 'Overview',
        icon: 'mimosaglyph mimosaglyph-overview',
        links: [{
            text: 'Dashboard',
            id: 'dashboard'
        }]
    }, {
        'text': 'Wireless',
        'icon': 'mimosaglyph mimosaglyph-wireless-right',
        'links': [{
            'text': 'Clients',
            'id': 'clientList'
        },{
            'text': 'SSID',
            'id': 'ssid'
        }, {
            text: "Link",
            id: "link"
        },{
            text: 'Channel & Power',
            id: 'channelpower'
        }, {
            text: "Site Survey",
            id: "sitesurvey"
        }, {
            'text': 'Location',
            'id': 'location'
        }]
    }, {
        text: "Networking",
        icon: "mimosaglyph mimosaglyph-world",
        links: [{
            'text': 'Access Control Lists',
            'id': 'acl'
        }, {
            'text': 'Traffic Shaping Plans',
            'id': 'subscriber-plans'
        },{
            'text': 'VLAN',
            'id': 'vlan'
        }]
    }, {
        'text': 'Preferences',
        'icon': 'mimosaglyph mimosaglyph-preferences',
        'links': [{
            text: 'General',
            id: 'general'
        }, {
            'text': 'Management',
            'id': 'management'
        }, {
            text: 'Notifications',
            id: 'notifications'
        }, {
            'text': 'Firmware & Reset',
            'id': 'firmware'
        }, {
            text: 'Backup & Restore',
            id: 'backup'
        }]
    }, {
        text: 'Diagnostics',
        icon: 'mimosaglyph mimosaglyph-diagnostics',
        links: [{
            'text': 'Tests',
            'id': 'diagnostics'
        }, {
            text: "MAC Forwarding Table",
            id: "fwt"
        }, {
            'text': 'Logs',
            'id': 'logging'
        }, {
            text: 'Ethernet Stats',
            id: 'ethernetstats'
        }]
    }];
    $scope.date = new Date();
    $scope.$watch("Device.stats.CPLock", function(n, o) {
        if(!n) {
            $scope.blocked = false;
            return;
        }
        if(n.tdwr || n.changeChannel) {
            $scope.blocked = true;
        } else {
            $scope.blocked = false;
        }
    }, true);
    $scope.changeUrl = function(id, event) {
        if(Device.stats.hasOwnProperty('CPLock') && (Device.stats.CPLock.tdwr || Device.stats.CPLock.changeChannel)) {
            return;
        }
        ga('send', 'pageview', id);
        if(Device.numChanges > 0) {
            event.preventDefault();
        } else {
            history.pushState('', '', id);
        }
    }
}]);
