"use strict"

angular.module('MimosaApp').directive('dottedProgress', function(){
    return {
        restrict: 'E',
        templateUrl: 'app/partials/dottedProgress.html',
        controller: 'dotCtrl',
        scope: {
            signal: '@',
            dotCount: '@',
            range: '@'
        }
    }
})
    .controller('dotCtrl', function($scope) {
        $scope.dots = new Array(5);
        $scope.progress = 4;
        $scope.statusColor = {
            0: 'badSignal',
            1: 'medSignal',
            2: 'medSignal',
            3: 'goodSignal',
            4: 'goodSignal'
        }

        $scope.$watch('signal', function(newVal){
            var newSignal;
            newVal = parseFloat(newVal);

            if (newVal < 10.5) {
                newSignal = 0;
            } else if (newVal < 15.5) {
                newSignal = 1;
            } else if (newVal < 20.5) {
                newSignal = 2;
            } else if (newVal < 25.5) {
                newSignal = 3;
            } else {
                newSignal = 4;
            }

            $scope.progress = newSignal;
        });

    });