angular.module("MimosaApp").directive('satelliteView', ['$compile', '$window', function($compile, $window) {
    return {
        restrict: 'EA',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {

            var width = element.parent().width(),
                height = 300;


            var svg = d3.select(element[0]).append("svg")
                .attr("width", width)
                .attr("height", height);

            var projection = d3.geo.orthographic()
                .scale(240)
                .translate([width / 2, height / 2])
                .precision(.1)
                .clipAngle(90);

            var graticule = d3.geo.graticule();

            var path = d3.geo.path()
                .projection(projection);

            svg.append("path").datum(graticule).attr("class", "graticule").attr("style", "stroke: #aaa; fill: none;").attr("d", path);



            var feature = svg.selectAll("path");

            var worldData = false;
            var worldPath = false;
            d3.json("/assets/projections/world-50m.json", function(error, world) {
                if (error) throw error;

                worldData = world;
                worldPath = svg.append("path")
                    .datum(topojson.feature(worldData, worldData.objects.countries))
                    .attr("style", "stroke: #000; fill: #ccc; fill-opacity: .8")
                    //.attr("class", "boundary")
                    .attr("d", path);
                //svg.append(".place-")
                svg.append("image")
                    .attr("class", "mark")
                    .attr("width", 20)
                    .attr("height", 20)
                    .attr("xlink:href", "/assets/img/pins_A5.png")
                    .attr("opacity", 0);
                feature = svg.selectAll("path");
            });


            function recenter() {
                console.log(scope.satData);
                if(!projection || !scope.satData || !worldData)
                    return;

                console.log(projection.rotate());
                //projection.center([scope.satData.lat, scope.satData.long])
                projection.scale(4000);
                projection.rotate([scope.satData.long * -1, scope.satData.lat * -1, 0]);
                svg.selectAll("image")
                    .attr("opacity", 1)
                    .attr("transform", function(d) {
                        return "translate("+projection([scope.satData.long, scope.satData.lat])+")";
                    });

                feature.attr("d", path);
            }

            function UpdateSatData(data) {
                var ret = {};
                var longitude = 0.0;
                var latitude = 0.0;

                var ggaStr = data.GGA;
                var ggaArray = ggaStr.split(',');

                // Verify data before using
                if(!ggaArray[1] && !ggaArray[3])
                    return;

                if (ggaArray[2] == 'S')
                    latitude = -.01 * parseFloat(ggaArray[1]);
                else
                    latitude = .01 * parseFloat(ggaArray[1]);
                if (ggaArray[4] == 'W')
                    longitude = -.01 * parseFloat(ggaArray[3]);
                else
                    longitude = .01 * parseFloat(ggaArray[3]);
                ret.lat = latitude;
                ret.long = longitude;
                return ret;
            }
            scope.$watch(function() {
                return ngModel.$modelValue;
            }, function(o, n) {
                if(!n)
                    return;
                var newData = UpdateSatData(n);
                if(!scope.satData || newData.lat !== scope.satData.lat || newData.long !== scope.satData.long) {
                    scope.satData = UpdateSatData(n);
                    recenter();
                }
            });
        }
    };
}]);
