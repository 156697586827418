'use strict';
angular.module('MimosaApp').controller('LocationPageController', ['$scope', 'Device', '$controller', "$sce", "GPS", function ($scope, Device, $controller, $sce, GPS) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;
    $scope.GPS = GPS;

    $scope.clock = 0;
    $scope.glonass = [];
    $scope.gpssats = [];
    $scope.totalSats = 0;

    var oldlatlng = false;

    $scope.satIds = function (type) {
        return GPS[type].map(function (val) {
            return val.id;
        });
    };

    $scope.chartData = {
        series: [],
        data: [],
        labels: [],
        override: {
            backgroundColor: [],
            label: "Sat ID"
        },
        options: {
            animation: false,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "dB"
                        }
                    }
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: "Satellite ID"
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    title: function(item) {
                        var type = "GPS";
                        if(parseInt(item[0].xLabel) > 33) {
                            type = "GLONASS";
                        }
                        return type + ": #" + item[0].xLabel;
                    },
                    label: function(item, data) {
                        return "dB: " + item.yLabel;
                    }
                },
                bodySpacing: 4
            }

        }
    };

    function updateChart() {
        $scope.chartData.data = [];
        $scope.chartData.labels = [];
        $scope.chartData.override.backgroundColor = [];
        $scope.chartData.override = {
            backgroundColor: [],
            hoverBackgroundColor: []
        }
        for (var i = 0; GPS.all_sats[i]; i++) {
            var sat = GPS.all_sats[i];
            $scope.chartData.labels.push(sat.id);
            $scope.chartData.data.push(sat.y);
            if (sat.id > 33) {
                $scope.chartData.override.backgroundColor.push("#0fbc61");
                $scope.chartData.override.hoverBackgroundColor.push("#0fbc61");
            } else {
                $scope.chartData.override.backgroundColor.push("#22a7f0");
                $scope.chartData.override.hoverBackgroundColor.push("#22a7f0");
            }
        }
    }

    $scope.$watch("GPS.glonass", function (n) {
        updateChart();
    }, true);

    $scope.$watch("GPS.gpssats", function (n) {
        updateChart();
    }, true);

    $scope.$watch(function () {
        return GPS.latitude;
    }, function (n) {
        if (!n) {
            return;
        }
        var newlatlng = new LatLon(GPS.latitude, GPS.longitude);
        if (!oldlatlng || oldlatlng && newlatlng.distanceTo(oldlatlng) > 50) {
            $scope.googlesource = $sce.trustAsResourceUrl("https://www.google.com/maps/embed/v1/place?zoom=9&q=" + GPS.latitude + "," + GPS.longitude + "&key=AIzaSyAYjhfHeO2ptap_k-fKiwD2T2SDQhAVNrc");
            oldlatlng = newlatlng;
        }
    });

    function compare(a, b) {
        if (a.x < b.x)
            return -1;
        if (a.x > b.x)
            return 1;
        return 0;
    }
}]);
