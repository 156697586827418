/**
 * Created by charlie on 7/24/15.
 */

angular.module("MimosaApp").controller("AclPageController", ["Device", "$scope", "$rootScope", "$controller", function (Device, $scope, $rootScope, $controller) {
    $controller("PageController", {$scope: $scope, Device: Device});

    $scope.Device = Device;

    $scope.matchTypes = ["IP", "MAC"];
    $scope.aclDirections = [{
        key: "Inbound",
        value: true
    }, {
        key: "Outbound",
        value: false
    }];
    $scope.protocolOptions = [
        {
            key: "TCP",
            value: 6
        }, {
            key: "UDP",
            value: 17
        }, {
            key: "ICMP",
            value: 1
        }
    ];
    $scope.permits = [
        {
            key:"Allow",
            value: 0
        }, {
            key:"Deny",
            value: 1
        }];

    $scope.isPageValid = function() {

        if($scope.aclForm.$invalid) {
            throw {
                name: "Invalid form",
                message: "Unable to save the ACLs due to invalid fields."
            }
        }
        for (aclNum = 0; aclNum < $scope.Device.stageDevice.acls.length; aclNum++) {
            if ( ($scope.Device.stageDevice.acls[aclNum].aclMatchType == "IP") &&
                 ( ($scope.Device.stageDevice.acls[aclNum].ipv4.srcIpAddr == undefined) ||
                   ($scope.Device.stageDevice.acls[aclNum].ipv4.srcIpAddrMask == undefined) ||
                   ($scope.Device.stageDevice.acls[aclNum].ipv4.dstIpAddr == undefined) ||
                   ($scope.Device.stageDevice.acls[aclNum].ipv4.dstIpAddrMask == undefined)))
            throw {
                name: 'Invalid form',
                message: 'Entry ' + $scope.Device.stageDevice.acls[aclNum].aclName + ' is invalid'
            };
            // if no name and (no dest add | no src add)
            // fail

            var acl = $scope.Device.stageDevice.acls[aclNum];
            if(acl.aclMatchType == "IP") {
                if(!acl.aclName || (!acl.ipv4.dstIpAddr && !acl.ipv4.srcIpAddr)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a name and a source or destination address"
                    }
                }
                if((acl.ipv4.srcIpAddr && !acl.ipv4.srcIpAddrMask) || (!acl.ipv4.srcIpAddr && acl.ipv4.srcIpAddrMask)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a source address and source mask"
                    }
                }
                if((acl.ipv4.dstIpAddr && !acl.ipv4.dstIpAddrMask) || (!acl.ipv4.dstIpAddr && acl.ipv4.dstIpAddrMask)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a destination address and destination mask"
                    }
                }
            } else {
                if(!acl.aclName || (!acl.mac.dstMacAddr && !acl.mac.srcMacAddr)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a name and a source or destination address"
                    }
                }
                if((acl.mac.srcMacAddr && !acl.mac.srcMacAddrMask) || (!acl.mac.srcMacAddr && acl.mac.srcMacAddrMask)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a source address and source mask" 
                    }
                }
                if((acl.mac.dstMacAddr && !acl.mac.dstMacAddrMask) || (!acl.mac.dstMacAddr && acl.mac.dstMacAddrMask)) {
                    throw {
                        name: "Invalid ACL",
                        message: "ACLs require a destination address and destination mask"
                    }
                }
            }
        }
    };


    $scope.removeRule = function (acl) {
        var index = Device.stageDevice.acls.indexOf(acl);
        $rootScope.$broadcast("prompt", 'Are you sure you want to delete this rule?', function() {}, function(){
            Device.stageDevice.acls.splice(index, 1);
        });
    };

    $scope.addRule = function () {
        Device.stageDevice.acls.push({
            "aclName" : "",
            "aclMatchType" : "IP",
            "inbound" : true,
            "mac" : {
                "srcMacAddr" : "",
                "srcMacAddrMask" : "",
                "dstMacAddr" : "",
                "dstMacAddrMask" : ""
            },
            "ipv4" : {
                "srcIpAddr"     : "",
                "srcIpAddrMask" : "",
                "dstIpAddr"     : "",
                "dstIpAddrMask" : "",
                "ipProtocol"    : $scope.protocolOptions[0].value
            },
            "action" : {
                "deny" : "",
                "tc" : "",
                "dscp" : ""
            }
        });
    };
}]);
