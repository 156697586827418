angular.module('MimosaApp').controller('GeneralPageController', ['$scope', '$controller', 'Device', '$rootScope', function($scope, $controller, Device, $rootScope) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.ledOptions = [
        {
            key: "Auto",
            mode:"auto"

        }, {
            key: "Off",
            mode: "off"
        }, {
            key: "Low",
            mode: "low"
        }, {
            key: "Medium",
            mode: "med"
        }, {
            key: "High",
            mode: "high"
        }
    ];

    $scope.Device = Device;
    $scope.logHostModes = ["TCP", "UDP"];

    $scope.$on('socket:update', function(event, data) {
        if(data.key == "resetPassword") {
            setTimeout(function() {
                window.location.href = "/logout";
            }, 4000);
        }
    });
    $scope.isPageValid = function() {
        if($scope.Device.stageDevice.resetPassword.hasOwnProperty('newPassword')) {
            // User has specified data for the reset password portion of page.
            if (!$scope.Device.stageDevice.resetPassword.currentPassword) {
                throw {
                    name: 'Invalid form',
                    message: 'Current password not provided.'
                };
            }
            if ($scope.Device.stageDevice.resetPassword.newPassword !== $scope.Device.stageDevice.resetPassword.newPassword2) {
                throw {
                    name: 'Invalid form',
                    message: 'Passwords must match.'
                };
            }
        }
        if(!$scope.generalForm.$valid) {
            throw {
                message: "Invalid input. Please provide valid input."
            }
        }
    };

}]);
