angular.module('MimosaApp').directive("networkCheck", function() {
    return {
        require: 'ngModel',
        scope: {
            form: '=',
            ngModel: '='
        },
        link: function(scope, elem, attrs, ctrl) {

            var sameNetwork = function(ipOne, ipTwo, netmask) {

                if (!ipOne.$modelValue || !ipTwo.$modelValue || !netmask.$modelValue) {
                    ipOne.$setValidity('network-check', false);
                    ipTwo.$setValidity('network-check', false);
                    netmask.$setValidity('network-check', false);
                    return;
                }

                var components1 = ipOne.$modelValue.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/);
                var components2 = ipTwo.$modelValue.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/);
                var subcomps = netmask.$modelValue.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/);

                if (components1 === null || components2 === null || subcomps === null) {

                    ipOne.$setValidity('network-check', false);
                    ipTwo.$setValidity('network-check', false);
                    netmask.$setValidity('network-check', false);
                    return;
                }

                var ip1 = parseInt(components1[1]);
                var ip2 = parseInt(components1[2]);
                var ip3 = parseInt(components1[3]);
                var ip4 = parseInt(components1[4]);

                var sp1 = parseInt(components2[1]);
                var sp2 = parseInt(components2[2]);
                var sp3 = parseInt(components2[3]);
                var sp4 = parseInt(components2[4]);

                var sn1 = parseInt(subcomps[1]);
                var sn2 = parseInt(subcomps[2]);
                var sn3 = parseInt(subcomps[3]);
                var sn4 = parseInt(subcomps[4]);

                var octa1 = ip1 & sn1;
                var octa2 = ip2 & sn2;
                var octa3 = ip3 & sn3;
                var octa4 = ip4 & sn4;
                var octb1 = sp1 & sn1;
                var octb2 = sp2 & sn2;
                var octb3 = sp3 & sn3;
                var octb4 = sp4 & sn4;

                if ((octa1 == octb1) && (octa2 == octb2) && (octa3 == octb3) && (octa4 == octb4)) {
                    ipOne.$setValidity('network-check', true);
                    ipTwo.$setValidity('network-check', true);
                    netmask.$setValidity('network-check', true);
                } else {
                    ipOne.$setValidity('network-check', false);
                    ipTwo.$setValidity('network-check', false);
                    netmask.$setValidity('network-check', false);
                }
            };

            scope.$watch("ngModel", function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    sameNetwork(scope.form.gateway, scope.form.ipaddress, scope.form.netmask);
                }
            });
        }
    }
});