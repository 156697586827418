/**
 * Created by charlie on 7/24/15.
 *
 * Use this to create new page controllers. You have to rename the "LinkPageController".
 *
 * Also make sure to add it to the index.html as well.
 */

'use strict';

angular.module('MimosaApp').controller('LinkPageController', ['$scope', '$controller', 'Device', function($scope, $controller, Device) {
    $controller('PageController', {$scope: $scope, Device: Device});
}]);
