angular.module('MimosaApp').directive('showtab', function() {
	return {
		link: function(scope, element, attrs) {
			element.click(function(e) {
				e.preventDefault();
				$(element).tab('show');
			})
		}
	}
});
angular.module('MimosaApp').controller('LoggingPageController', ['$scope', '$controller', 'Device', 'FileUpload', '$rootScope', function($scope, $controller, Device, FileUpload, $rootScope) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.diag = {
    	showMessage : false,
    	disableButton: false,
		done: false,
    	message: "",
		class: ""
	};

	$scope.logLines = '';
	$scope.eventlogLines = '';
	$scope.npLogLines = '';

	Device.emit('get log');

	$scope.UpdateEventLog = function(event, message) {
		var message = unpack(message);
		var arrayLength = message.Data.length;
		for (var i = 0; i < arrayLength; i++) {
			$scope.eventlogLines += message.Data[i];
		}
	};

	$scope.UpdateEverythingLog = function(event, message) {
        var message = unpack(message);
		var arrayLength = message.Data.length;
		for (var i = 0; i < arrayLength; i++) {
			$scope.logLines += message.Data[i];
		}
	};

	Device.emit('get np_log');

	$scope.UpdateNpLog = function(event, message) {
        var message = unpack(message);
		var arrayLength = message.Data.length;
		for (var i = 0; i < arrayLength; i++) {
			$scope.npLogLines += message.Data[i];
		}
	};

	$scope.supportPrompt = function() {
        $rootScope.$broadcast("prompt", "It can take up to 5 minutes to generate the Support Log (.tar file) for download. Please do not navigate away from this page. When the process is completed, the download will be automatically started.", function() {
        }, function(){
        	Device.socket.emit("diag", "start");
        });
    }

    function handleDiag(event, message) {
        var message = unpack(message);
		switch(message.status) {
			case "done":
                $scope.diag.showMessage = true;
                $scope.diag.disableButton = false;
                $scope.diag.done = true;
                $scope.diag.message = "Support file generated.";
                $scope.diag.class = "alert-success";
                window.open("/supportInfo", "_self");
				break;
            case "started":
            	$scope.diag.disableButton = true;
            	$scope.diag.showMessage = true;
            	$scope.diag.done = false;
                $scope.diag.message = "Support file is being generated.";
                $scope.diag.class = "alert-info";
                break;
			default:
				console.log(message);
				break;
		}
	}

	$scope.$on('socket:event_log', $scope.UpdateEventLog);
	$scope.$on('socket:everything_log', $scope.UpdateEverythingLog);
	$scope.$on('socket:np_log', $scope.UpdateNpLog);
	$scope.$on('socket:diag', handleDiag);

}]);
