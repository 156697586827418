/**
 * Created by cdavis on 10/1/15.
 */

angular.module("MimosaApp").service("PDFPoll", function ($http, $timeout) {
    var url = {
        local: "/spectrum",
        combined: "/spectrum_combined"
    };
    var timer;
    var dataSource = 'local';

    var poller = function () {
        return $http.get(url[dataSource], {
            responseType: "arraybuffer"
        }).then(function (resp) {
            factory.loading = false;
            factory.data = resp;
            timer = $timeout(poller, 9000);
        });
    };

    var factory = {
        data: null,
        loading: false,
        start: function () {
            poller();
        },
        stop: function () {
            $timeout.cancel(timer);
        },
        source: function(source) {
            if(source == dataSource) {
                return;
            }
            dataSource = source;
            this.loading = true;
        }
    };

    return factory;
});

angular.module('MimosaApp').directive('spectrumAnalyzer', function (Device) {
    return {
        restrict: "E",
        scope: {
            exclusions: "=",
            height: "=",
            restricted: "=",
            lower: "=",
            center: "=",
            bw: "=",
            source: "=",
            upper: "=",
        },
        template: "<div id='spectrum-source-mask' ng-if='PDF.loading'><div class='bg'></div><h4>Loading...</h4></div>",
        controller: ["$scope", "PDFPoll", function ($scope, PDFPoll) {
            $scope.PDF = PDFPoll;
            PDFPoll.start();

            $scope.$on("$destroy", function () {
                PDFPoll.stop();
            });

            $scope.$watch("source", function(n, o) {
                PDFPoll.source(n);
            });


        }],
        link: function (scope, element, attributes, controller) {
            width = element.parent().width();
            element.css("display", "block");
            element.css("position", "relative");
            element.width(width);


            (function () {
                var legend = angular.element("<div/>");
                legend.append("<span class='box exclusion'></span> Manual Exclusion");
                legend.append("<span class='box active'></span> Active Channel");
                legend.append("<span class='box restricted'></span> Restricted");
                legend.prop("id", "sa-legend");
                element.append(legend);
            })();
            function resize() {
                var w = element.parent().width();
                chartDimensions.width = w - mainPadding.left - mainPadding.right;
                chartDimensions.spriteScale.width = chartDimensions.width / tile.width;
                renderer.resize(w, scope.height);
                drawGrid();
                drawLegend();
                updateExclusions(scope.exclusions);
                updateRestricted(scope.restricted);
                updateCenter();
                for(var key in cleanData) {
                    cleanData[key].sprite.position.x = freqToX(cleanData[key].freq);
                    cleanData[key].sprite.scale.y = chartDimensions.spriteScale.height;
                    cleanData[key].sprite.scale.x = chartDimensions.spriteScale.width;
                }
            }

            var ranges = {
                freq: {
                    min: 4900,
                    max: 6200,
                    step: 52
                },
                db: {
                    min: -100,
                    max: -40
                }
            };
            var stage, width, renderer, gridGraphics, gridMask;
            var tile = {
                height: 120,
                width: 1040,
                spritesContainer: null,
                sprites: []
            };
            var cleanData = {};
            var colorCache = {};

            var mainPadding = {
                top: 0,
                right: 60,
                bottom: 70,
                left: 60
            };

            var cWidth = width - mainPadding.left - mainPadding.right;
            var cHeight = scope.height - mainPadding.top - mainPadding.bottom;
            var chartDimensions = {
                width: cWidth,
                height: cHeight,
                x: mainPadding.left,
                y: mainPadding.top,
                spriteScale: {
                    width: cWidth / tile.width,
                    height: cHeight / tile.height
                }
            };

            var tileStage;
            var texture = PIXI.Texture.fromImage("/assets/img/sprite.png");


            function decimalToHex(d, padding) {
                var hex = Number(d).toString(16);
                padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

                while (hex.length < padding) {
                    hex = "0" + hex;
                }

                return hex;
            }

            function hue_2_rgb(v1, v2, vH) {
                if (vH < 0) vH += 1;
                if (vH > 1) vH -= 1;
                if (( 6 * vH ) < 1) return ( v1 + ( v2 - v1 ) * 6 * vH );
                if (( 2 * vH ) < 1) return ( v2 );
                if (( 3 * vH ) < 2) return ( v1 + ( v2 - v1 ) * ( ( 2 / 3 ) - vH ) * 6 );
                return ( v1 );
            }

            function hsla2hex(H) {
                var S = 1;
                var L = 0.5;
                var R = 0;
                var G = 0;
                var B = 0;

                if (S == 0)                       //HSL from 0 to 1
                {
                    R = L * 255;                     //RGB results from 0 to 255
                    G = L * 255;
                    B = L * 255;
                }
                else {
                    var var_2;
                    if (L < 0.5)
                        var_2 = L * ( 1 + S );
                    else
                        var_2 = ( L + S ) - ( S * L );

                    var var_1 = 2 * L - var_2

                    R = 255 * hue_2_rgb(var_1, var_2, H + ( 1 / 3 ));
                    G = 255 * hue_2_rgb(var_1, var_2, H);
                    B = 255 * hue_2_rgb(var_1, var_2, H - ( 1 / 3 ));
                }
                return decimalToHex(Math.round(R), 2) + decimalToHex(Math.round(G), 2) + decimalToHex(Math.round(B), 2);
            }

            function value2color(val) {
                if (val > 1)
                    val = 1;
                var cacheColor = colorCache[val];
                if (cacheColor)
                    return cacheColor;
                var minLinearColorValue = .0893; // this plus offset = 25 guaratees 0->1 map to 0->280
                var maxHue = 255;
                var delta = val;
                var offset = 25;
                var hue = ((maxHue - maxHue * delta / (1 - minLinearColorValue)) + offset) / 360; // red -> violet = 0 ~ 280 degree in hue
                var hex = hsla2hex(hue);
                colorCache[val] = parseInt("0x" + hex);
                return colorCache[val];
            }

            var legendGraphics;
            function drawLegend() {
                if(legendGraphics)
                    stage.removeChild(legendGraphics);
                legendGraphics = new PIXI.Graphics();
                stage.addChild(legendGraphics);

                var gradient = new PIXI.Graphics();
                var bars = new PIXI.Graphics();

                legendGraphics.addChild(gradient);
                legendGraphics.addChild(bars);

                var texture = PIXI.Texture.fromImage("/assets/img/sprite.png");

                var leftSide = chartDimensions.width + chartDimensions.x + 15;

                for(var i = tile.height; i >= 0; i--) {
                    var y = i % tile.height;
                    var pixel = new PIXI.Sprite(texture);
                    pixel.anchor.x = 0;
                    pixel.anchor.y = 0;
                    pixel.width = 18;

                    pixel.tint = value2color(i / tile.height, 0);
                    pixel.position.x = leftSide;
                    pixel.position.y = (chartDimensions.y + 25) + (y / tile.height) * (chartDimensions.height - 25);

                    gradient.addChild(pixel);
                }
                var start = chartDimensions.y + 25;
                var gap = (chartDimensions.height - 25) / 10;
                bars.lineStyle(1, 0xFFFFFF, 1);
                for(var i = 0; i <= 10; i++) {
                    if(i != 0 && i < 10) {
                        bars.moveTo(leftSide, start + (i * gap));
                        bars.lineTo(leftSide + 18, start + (i * gap))
                    }
                    if(i % 2 ==0) {
                        var text = new PIXI.Text(i / 10, {
                            font: "12px Open Sans Light",
                            align: "center",
                            fill: "#54585A"
                        });
                        text.x = leftSide + 20;
                        text.y = start + (i * gap);
                        text.anchor.y = 0.5;
                        bars.addChild(text);
                    }
                }

                var label = new PIXI.Text("CDF", {
                    font: "14px Open Sans Light",
                    align: "center",
                    fill: "#54585A"
                });
                label.anchor.x = 0.5;
                label.x = leftSide + 10;
                label.y = chartDimensions.y;
                bars.addChild(label);

            }

            function drawGrid() {
                if (gridGraphics) {
                    stage.removeChild(gridGraphics);
                }
                gridGraphics = new PIXI.Graphics();
                stage.addChild(gridGraphics);

                var gap = chartDimensions.width / ranges.freq.step;

                for (var i = 0; i <= ranges.freq.step; i++) {
                    if(i == 0 || i == ranges.freq.step) {
                        gridGraphics.lineStyle(1, 0xC7C9C7, 1);
                    } else {
                        gridGraphics.lineStyle(1, 0xC7C9C7, 0.3);
                    }
                    if (i % 4 == 0) {
                        gridGraphics.moveTo(gap * i + mainPadding.left, chartDimensions.y);
                        gridGraphics.lineTo(gap * i + mainPadding.left, chartDimensions.height + 20);
                        var text = new PIXI.Text(parseInt((((ranges.freq.max - ranges.freq.min) / ranges.freq.step) * i) + ranges.freq.min), {
                            font: "14px Open Sans Light",
                            align: "center",
                            fill: "#54585A"
                        });
                        text.x = gap * i + mainPadding.left - (text.width / 2);
                        text.y = chartDimensions.height + 20;
                        gridGraphics.addChild(text);
                    }
                }

                var ymin = ranges.db.min, ymax = ranges.db.max;
                var numY = 6;
                var gap = chartDimensions.height / numY;

                for (var i = 0; i <= numY; i++) {
                    if(i == 0 || i == numY) {
                        gridGraphics.lineStyle(1, 0xC7C9C7, 1);
                    } else {
                        gridGraphics.lineStyle(1, 0xC7C9C7, 0.3);
                    }
                    if (i !== 0 && i !== numY) {
                        var text = new PIXI.Text(ymax - (ymax - ymin) / numY * i, {
                            font: "14px Open Sans Light",
                            align: "center",
                            fill: "#54585A"
                        });
                        text.x = mainPadding.left - (text.width) - 5;
                        text.y = gap * i - (text.height / 2);
                        gridGraphics.addChild(text);
                    }
                    gridGraphics.moveTo(chartDimensions.x, gap * i);
                    gridGraphics.lineTo(chartDimensions.width + mainPadding.left, gap * i);
                }

                var yAxisLabel = new PIXI.Text("dBm/20MHz", {
                    font: "14px Open Sans Light",
                    align: "center",
                    fill: "#54585A"
                });
                yAxisLabel.anchor.x = 0.5;
                yAxisLabel.anchor.y = 0.5;
                yAxisLabel.rotation = 4.71239;
                yAxisLabel.x = 15;
                yAxisLabel.y = (chartDimensions.height / 2);
                gridGraphics.addChild(yAxisLabel);

                if(gridMask) {
                    gridMask.clear();
                } else {
                    gridMask = new PIXI.Graphics();
                }
                gridMask.beginFill();
                gridMask.drawRect(chartDimensions.x, chartDimensions.y, chartDimensions.width, chartDimensions.height);
                gridMask.endFill();
                stage.addChild(gridMask);
            }

            function updateData(data) {
                var data = new Float32Array(data);

                for (var i = 0; i < tile.width; i++) {
                    var value = 0;
                    for (var j = 0; j < tile.height; j++) {
                        var freq = ranges.freq.min + i * 5 / 4;
                        var dbm = j / tile.height;
                        value += data[tile.height * i + j];
                        var key = "" + i + "/" + j;

                        if(value <= 0.015) {
                            if(cleanData[key]) {
                                tileStage.removeChild(cleanData[key].sprite);
                                delete cleanData[key];
                            }
                            continue;
                        }

                        if(!cleanData[key]) {
                            cleanData[key] = {
                                freq: freq,
                                dbm: dbm
                            };
                            cleanData[key].sprite = new PIXI.Sprite(texture);
                            cleanData[key].sprite.scale.x = chartDimensions.spriteScale.width;
                            cleanData[key].sprite.scale.y = chartDimensions.spriteScale.height;

                            cleanData[key].sprite.position.x = freqToX(freq);
                            cleanData[key].sprite.position.y = ((chartDimensions.height - 2) * dbm) + 1;

                            tileStage.addChild(cleanData[key].sprite);
                        }

                        cleanData[key].value = value;
                        cleanData[key].sprite.tint = value2color(value);
                    }
                }
            }

            var resizer;

            function updateConfiguration() {
                if (!stage) {
                    var w = element.parent().width();
                    chartDimensions.width = w - mainPadding.left - mainPadding.right;
                    renderer = new PIXI.autoDetectRenderer(w, scope.height, {
                        transparent: true,
                        antialias: false,
                        resolution: window.devicePixelRatio || 1,
                        autoResize: true
                    });
                    element.append(renderer.view);
                    stage = new PIXI.Container();
                    drawGrid();
                    drawLegend();
                    $(window).resize(function () {
                        clearTimeout(resizer);
                        resizer = setTimeout(resize, 100);
                    });
                    tileStage = new PIXI.Container();
                    tileStage.mask = gridMask;
                    stage.addChild(tileStage);

                    animate();
                }
            }

            function YtoDb(y) {
                if (y < chartDimensions.y) {
                    return false;
                } else if (y > chartDimensions.y + chartDimensions.height) {
                    return false;
                }
                return ((y - chartDimensions.y) / (chartDimensions.height + mainPadding.top));
            }

            function XtoFreq(x) {
                if (x < chartDimensions.x) {
                    return false;
                } else if (x > chartDimensions.x + chartDimensions.width) {
                    return false;
                }
                return Math.floor(ranges.freq.min + ((ranges.freq.max - ranges.freq.min) * ((x - chartDimensions.x) / (chartDimensions.width))));
            }

            function freqToX(freq) {
                return chartDimensions.x + (chartDimensions.width / (ranges.freq.max - ranges.freq.min) * ((ranges.freq.max - ranges.freq.min) - (ranges.freq.max - freq)));
            }

            var centerGraphic;
            var center = {};
            function updateCenter() {
                if(!center.freq || !center.bw) {
                    return;
                }
                if(!centerGraphic) {
                    centerGraphic = new PIXI.Graphics();
                    stage.addChild(centerGraphic);
                } else {
                    centerGraphic.clear();
                }
                centerGraphic.beginFill(0x3fb125);
                centerGraphic.drawRect(freqToX(center.freq - (center.bw / 2)), chartDimensions.height + 1, freqToX(center.freq + (center.bw / 2)) - freqToX(center.freq - (center.bw / 2)), 10);
            }

            var restrictedCont;
            function updateRestricted(restricted) {
                if(!Array.isArray(restricted)) {
                    return;
                }
                if(!restrictedCont) {
                    restrictedCont = new PIXI.Graphics();
                    stage.addChild(restrictedCont);
                } else {
                    restrictedCont.clear();
                }
                restrictedCont.beginFill(0xB43426);
                for(var i = 0; i < restricted.length; i++) {
                    var r = restricted[i];
                    restrictedCont.drawRect(freqToX(r.start), chartDimensions.height + 1, freqToX(r.end) - freqToX(r.start), 10)
                }
            }

            var excludedCont;

            function updateExclusions(exclude) {
                if (!excludedCont) {
                    excludedCont = new PIXI.Graphics();
                    stage.addChild(excludedCont);
                } else {
                    excludedCont.clear();
                }
                excludedCont.beginFill(0x9EA2A2);
                for (var key in exclude) {
                    var exc = exclude[key];
                    excludedCont.drawRect(freqToX(exc.start), chartDimensions.height + 1, freqToX(exc.end) - freqToX(exc.start), 10);
                }
            }

            function animate() {
                renderer.render(stage);
                requestAnimationFrame(animate);
            }

            updateConfiguration();
            scope.$watch(function (scope) {
                return scope.exclusions;
            }, function (n, o) {
                updateExclusions(n);
            }, true);

            scope.$watch(function(scope) {
                return scope.restricted;
            }, function(n, o) {
                updateRestricted(n);
            });

            scope.$watch("center", function(n, o) {
                center.freq = n;
                updateCenter();
            });

            scope.$watch("bw", function(n, o) {
                center.bw = n;
                updateCenter();
            });
            scope.$watch(function (scope) {
                return scope.PDF.data;
            }, function (n, o) {
                if (!n || n.data.byteLength == 0) {
                    return;
                }
				if (n.data.byteLength == 499200) {
                    ranges.freq.min = 4900;
                    ranges.freq.max = 6200;
                    ranges.freq.step = 52;
                    tile.width = 1040;
				} else {
                    ranges.freq.min = 4900;
                    ranges.freq.max = 6425;
                    ranges.freq.step = 61;
                    tile.width = 1220;
				}
				resize();
				updateData(n.data);
            });
            scope.$on("$destroy", function (event) {
                animate = function () {
                };
                stage.destroy({
                    children: true
                });
            });

            if (!scope.PDF.data && window.pdf) {
                scope.PDF.data = {
                    data: window.pdf
                };
            }

            //todo *** could be used in future *** - John McGowan 04/13/2017
            // var frequencyToChannel = function (frequency) {
            //     if (frequency > 5000) {
            //         var chan = Math.floor((frequency - 5000) / 5);
            //         if (frequency >= 5905) {
            //             chan = chan + 20;
            //         }
            //         return chan;
            //     }
            //     else if (frequency <= 5000)
            //         return Math.floor((frequency - 4000) / 5);
            // }

            var mouseHover = (function() {
                createMouseOver();

                var spectrumtt = element.find("#spectrum-tt");
                var spectrumv = element.find("#spectrum-v");
                var spectrumh = element.find("#spectrum-h");

                function closest(num, arr) {
                    var curr = arr[0];
                    if(curr && !curr.hasOwnProperty("dbm")) {
                        return {
                            dbm: 0
                        }
                    }
                    for(var i = 0; i < arr.length;i++) {
                        if(arr[i].dbm <= num) {
                            curr = arr[i];
                        }
                    }
                    return curr;
                }
                function createMouseOver() {
                    element.append("<div id='spectrum-h'></div><div id='spectrum-v'></div><div id='spectrum-tt'><h3>Freq: <span class='freq'></span> MHz</h3><h3>PSD: <span class='dbm'></span> dBm</h3><h3>CDF: <span class='cdf'></span>%</h3></div>");
                }
                return {
                    move: function(event) {
                        var freq = XtoFreq(event.offsetX),
                            dbm = YtoDb(event.offsetY),
                            channel;
                        if(!freq || !dbm) {
                            spectrumh.hide();
                            spectrumv.hide();
                            spectrumtt.hide();
                            element.css({
                                "cursor": "default"
                            });
                            return;
                        }

                        element.css({
                            "cursor": "none"
                        });
                        spectrumh.css({
                            left: chartDimensions.x
                        }).width(chartDimensions.width);
                        spectrumv.css({
                            top: chartDimensions.y
                        }).height(chartDimensions.height);
                        var width = spectrumtt.outerWidth();
                        var height = spectrumtt.outerHeight();

                        var freqData = [];
                        for(var key in cleanData) {
                            if(cleanData[key].freq >= (freq - 0.5) && cleanData[key].freq <= (freq + 0.5)) {
                                freqData.push(cleanData[key]);
                            }
                        }
                        var cdf = closest(dbm, freqData);
                        var top = event.offsetY - height - 5;
                        var left = event.offsetX + 5;

                        if(left + width + 5 > chartDimensions.x + chartDimensions.width - 5) {
                            left = event.offsetX - width - 5;
                        }
                        if(top < 0) {
                            top = event.offsetY + 5;
                        }
                        spectrumtt.css({
                            top: top,
                            left: left
                        }).find(".freq").text(freq).end().find(".dbm").text(((dbm * -60 - 40)).toFixed()).end().find(".cdf").text((cdf.value > 100 ? 100 : cdf.value * 100).toFixed(2)).end().show();
                        spectrumh.css("top", event.offsetY - 1).show();
                        spectrumv.css("left", event.offsetX - 1).show();

                    }
                }
            })();

            element.on("mousemove", function(event) {
                mouseHover.move(event);
            });



        }
    };
});
