'use strict';

/* App Module */
var mimosaApp = angular.module('MimosaApp', [
    'ngRoute',
    'ngResource',
    'btford.socket-io',
    'ngProgress',
    'chart.js',
    'ui.bootstrap',
	'ui.unique',
    'rzModule',
    'lz-string',
    'ngAnimate',
    'angularSpinner',
    'frapontillo.bootstrap-switch',
    "angular-growl"
]);
mimosaApp.factory('a5Socket', function (socketFactory) {
    var mySocket = socketFactory();
    mySocket.forward('stats');
    mySocket.forward('time');
    mySocket.forward('timers');
    mySocket.forward('connect');
    mySocket.forward('disconnect');
    mySocket.forward('notify');
    mySocket.forward('ping');
    mySocket.forward('traceroute');
    mySocket.forward('cloud');
    mySocket.forward('error_msg');
    mySocket.forward('event_log');
    mySocket.forward('everything_log');
    mySocket.forward('np_log');
    mySocket.forward("spectrum");
    mySocket.forward("diag");
    mySocket.forward('successful');
    mySocket.forward('update');
    mySocket.forward('base');
    mySocket.forward('uidb');
    mySocket.forward('wanstats');
    return mySocket;
});
mimosaApp.config(["growlProvider", function (provider) {
    provider.globalTimeToLive(3000);
}]);

mimosaApp.config(function ($routeProvider, $locationProvider, ChartJsProvider) {

    ChartJsProvider.setOptions({
        chartColors: ["#22A7F0", "#0FBC61", "#F4C30B"],
        maintainAspectRatio: false
    });


    var partialRoot = 'app/partials/';
    $routeProvider
        .when('/:page', {
            controller: function ($scope, $routeParams, $controller, SessionTimeoutService) {
                SessionTimeoutService.reset();
                $controller(($routeParams.page.capitalizeFirstLetter() + 'PageController'), {$scope: $scope});
                $scope.$on("$routeChangeStart", function (event, next, current) {
                    if ($scope.changeCount > 0) {
                        event.preventDefault();
                    }
                });
            },
            templateUrl: function (param) {
                ga('send', 'pageview', param.page);

                return partialRoot + param.page + '.html?v=1';
            }
        })
        .otherwise({
            redirectTo: '/dashboard'
        });
    $locationProvider.html5Mode(true);
});
/**
 * Pre-fetch a spectrum
 */

(function () {
    // Disable for Internet Explorer. This will need proper research someday.
    if (navigator.appName == "Microsoft Internet Explorer" || navigator.appVersion.indexOf('Trident') !== -1) {
        // IE 11 reports itself as Netscape, but version 'Trident' of Netscape.
        return;
    }

    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open("GET", "/spectrum", true);
    xhr.onload = function (e) {
        window.pdf = this.response;
    };
    xhr.send();

})();
/**
 * Custom Angular filters
 */

/**
 * Absolute value
 */


mimosaApp.filter('absValue', function() {
    return function(input) {
        return Math.abs(input);
    }
});

/**
 * Capital first letter
 */

mimosaApp.filter('capitalize', function () {
    return function (input) {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
});

/**
 * Generate a range
 */

mimosaApp.filter('range', function () {
    return function (input, min, max) {
        min = parseInt(min); //Make string input int
        max = parseInt(max);
        for (var i = min; i < max; i++)
            input.push(i);
        return input;
    };
});

mimosaApp.filter("numCheck", function () {
    return function (input) {
        return typeof input === "number" ? input : "--";
    }
});

/**
 * Returns # of properties in an object
 */
mimosaApp.filter("propCount", function () {
    return function (input) {
        if (typeof input !== "object") {
            return 0;
        }
        return Object.keys(input).length;
    }
});

mimosaApp.filter('unlock', function () {
    return function (input) {
        if (!input) {
            return input;
        }
        var tmp = input.split("");
        tmp.splice(6, 0, "-");
        tmp.splice(3, 0, "-");
        return tmp.join("");
    }
});

/**
 * order object by
 */

mimosaApp.filter('orderObjectBy', function () {
    return function (items, field, reverse) {
        var filtered = [];
        angular.forEach(items, function (item) {
            filtered.push(item);
        });
        filtered.sort(function (a, b) {
            return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
        });
        if (reverse) filtered.reverse();
        return filtered;
    };
});


/**
 * Converts seconds to days, hours, minutes, seconds
 */

mimosaApp.filter("secondsToTime", function () {
    return function (seconds, includeSeconds) {
        var seconds = parseInt(seconds) || 0;
        var days = Math.floor(seconds / 86400);
        var hours = Math.floor((seconds - (days * 86400)) / 3600);
        var minutes = Math.floor((seconds - (days * 86400) - (hours * 3600)) / 60);

        if (days > 0) days = days + "d ";
        else days = "";
        if (hours > 0) hours = hours + "h ";
        else hours = "";
        if (minutes > 0) minutes = minutes + "m ";
        else minutes = "";
        if (includeSeconds)
            var seconds_str = (seconds % 60) + "s ";
        else
            var seconds_str = "";

        var time = days + hours + minutes + seconds_str;
        return time;
    }
});

/**
 * Converts bits to Mb
 */

mimosaApp.filter("Bps", function () {
    return function (input) {
        var bytes = Math.abs(input);
        var info = {
            bytes: Math.abs(input),
            label: "B"
        };
        info.bytes = info.bytes / 1000000;
        info.label = "Mb";
        return parseFloat(info.bytes).toFixed(2);
    }
});

/*
Returns Wifi Interop or SRS for tdma/csma
*/

mimosaApp.filter("wifiMode", function () {
    return function (input) {
        if (input === "tdma") return "SRS";
        else if (input === "csma") return "Wifi Interop";
        else return "";
    }
})

/**
 * Appends 'DFS' to relevant channels
 */

mimosaApp.filter('checkDFS', function () {
    return function (input, Device) {
        if (!Device || !input) return;
        var bandwidthMap = [20, 40, 80],
            freq = Device.stats.radioStatusBbic.radioConfigBbic['Center Frequency'],
            channelWidth = Device.stats.radioStatusBbic.radioConfigBbic['Channel Width'],
            chanIndex = bandwidthMap.indexOf(channelWidth),
            chanList = Device.stats.channelListBbic['Channel List'][chanIndex]['Channel List'];
        for (var i = 0; i < chanList.length; i++) {
            if (chanList[i]['Center Frequency'][0] === freq && chanList[i].DFS === true) {
                return input + ' DFS'
            }
        }
        return input;
    }
})

/**
 Not sure where to put prototype methods yet
 **/

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

Number.prototype.between = function (min, max, lte) {
    if (lte) {
        return this >= min && this <= max;
    }
    return this >= min && this < max;
};

/**
 * Generates a unique identifier.
 *
 * @param separator
 * @returns {*}
 */
var generateUid = function (separator) {
    var delim = typeof separator === false ? '-' : separator;

    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
};

/**
 * Perform a single RCP
 * @param options
 * @param Device
 * @returns {{call: call, setArgs: setArgs}}
 * @constructor
 */
function RPCall(options, Device) {
    var intf = options.interface;
    var path = options.path;
    var method = options.method;
    var handler = options.target;
    var Device = Device;

    var text = [intf, path, method, handler].join(" ");
    var slug = text.toString().toLowerCase().replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    options.slug = slug;

    return {
        call: function (cb) {
            Device.socket.on(slug, function (message) {
                var data = unpack(message);
                Device.socket.removeListener(slug);
                if (cb)
                    cb(data);
            });
            Device.socket.emit("rpc_call", options);
        },
        setArgs: function (args) {
            options.args = args;
        }
    }
}

/**
 * This might be used by the backend to keep track of clients. We don't need this... yet.
 * @type {*}
 */
mimosaApp.clientId = generateUid('');

/**
 * List of vendor mac addresses
 */

mimosaApp.filter("VendorMac", ['OUIService', function (oui) {
    function properCase(string) {
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };
    var vendorFilter = function (input) {
        return properCase(oui.getBrand(input));
    }
    vendorFilter.$stateful = true;
    return vendorFilter;
}]);

/**
 * Browser has internet connection check
 */

function onlineCheck() {
    return false;
}

/**
 * This file from NMS will overwrite the above function with a true.
 */
(function () {
    function async_load() {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://nms-alpha.mimosa.co/app/js/onlineCheck.js?q=' + new Date().getTime();
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    }

    if (window.attachEvent)
        window.attachEvent('onload', async_load);
    else
        window.addEventListener('load', async_load, false);
})();


function LatLon(lat, lon) {
    // allow instantiation without 'new'
    if (!(this instanceof LatLon)) return new LatLon(lat, lon);

    this.lat = Number(lat);
    this.lon = Number(lon);
}

if (Number.prototype.toRadians === undefined) {
    Number.prototype.toRadians = function () {
        return this * Math.PI / 180;
    };
}

LatLon.prototype.distanceTo = function (point, radius) {
    if (!(point instanceof LatLon)) throw new TypeError('point is not LatLon object');
    radius = (radius === undefined) ? 6371e3 : Number(radius);

    var R = radius;
    var φ1 = this.lat.toRadians(), λ1 = this.lon.toRadians();
    var φ2 = point.lat.toRadians(), λ2 = point.lon.toRadians();
    var Δφ = φ2 - φ1;
    var Δλ = λ2 - λ1;

    var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d;
};

function Chunkify(file, options) {
    if (!this instanceof Chunkify) {
        return new Chunkify(file, options);
    }

    var self = this;

    this.file = file;

    this.options = $.extend({
        url: '/firmware'
    }, options);

    this.file_size = this.file.size;
    this.chunk_size = (60 * 1024 * 1000); // Modify this to set size of chunks.
    this.range_start = 0;
    this.percent = 0;
    this.range_end = this.chunk_size;

    if ('mozSlice' in this.file) {
        this.slice_method = 'mozSlice';
    }
    else if ('webkitSlice' in this.file) {
        this.slice_method = 'webkitSlice';
    }
    else {
        this.slice_method = 'slice';
    }

    this.upload_request = new XMLHttpRequest();
    this.upload_request.onload = this.complete.bind(this);
    this.upload_request.upload.addEventListener("progress", function (e) {
        self.percent = Math.ceil(((self.range_start + e.loaded) / self.file_size) * 100);
        self.options.progress(self.percent);
    });

}

Chunkify.prototype = {
    upload: function () {
        var self = this,
            chunk;
        setTimeout(function () {
            // Prevent range overflow
            if (self.range_end > self.file_size) {
                self.range_end = self.file_size;
            }

            chunk = self.file[self.slice_method](self.range_start, self.range_end);

            self.upload_request.open('POST', self.options.url, true);
            self.upload_request.overrideMimeType('application/octet-stream');

            self.upload_request.setRequestHeader('Content-Range', JSON.stringify({
                start: self.range_start,
                end: self.range_end,
                size: self.file_size
            }));
            var formData = new FormData();
            formData.append('file', chunk, self.file.name);
            self.upload_request.send(formData);
            if (typeof self.options.postSend == "function") {
                self.options.postSend();
            }

        }, 20);
    },
    complete: function () {
        if (this.range_end === this.file_size) {
            this.options.complete();
            return;
        }
        this.range_start = this.range_end;
        this.range_end = this.range_start + this.chunk_size;
        if (!this.is_abort)
            this.upload();
    },
    start: function () {
        this.upload();
    },
    abort: function () {
        this.is_abort = true;
        this.upload_request.abort();
    }
};


/**
 * Adding decimal rounding helpers
 */

(function () {
    /**
     * Decimal adjustment of a number.
     *
     * @param {String}  type  The type of adjustment.
     * @param {Number}  value The number.
     * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number} The adjusted value.
     */
    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    // Decimal round
    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }
    // Decimal floor
    if (!Math.floor10) {
        Math.floor10 = function (value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }
    // Decimal ceil
    if (!Math.ceil10) {
        Math.ceil10 = function (value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }
})();
(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-38296509-13', 'auto');
ga('send', 'pageview');


/**
 * Polyfills here
 */

if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {

            // Steps 1-2.
            if (this == null) {
                throw new TypeError('this is null or not defined');
            }

            var O = Object(this);

            // Steps 3-5.
            var len = O.length >>> 0;

            // Steps 6-7.
            var start = arguments[1];
            var relativeStart = start >> 0;

            // Step 8.
            var k = relativeStart < 0 ?
                Math.max(len + relativeStart, 0) :
                Math.min(relativeStart, len);

            // Steps 9-10.
            var end = arguments[2];
            var relativeEnd = end === undefined ?
                len : end >> 0;

            // Step 11.
            var final = relativeEnd < 0 ?
                Math.max(len + relativeEnd, 0) :
                Math.min(relativeEnd, len);

            // Step 12.
            while (k < final) {
                O[k] = value;
                k++;
            }

            // Step 13.
            return O;
        }
    });
}

/**
 * Unpacks a B64(zlib(json))) string.
 * @param string
 */

function unpack(string) {
    var unpacked = JSON.parse(JSON.stringify(string));
    return unpacked;
}