/**
 * Created by cdavis on 4/28/16.
 */
angular.module('MimosaApp').directive('nmsLink', function() {

    return {
        template: '<a class="nms-link pull-right" ng-class="{disabled: disabled()}" title="Historical Data" ng-click="gotoNms()"><i class="mimosaglyph mimosaglyph-goto-cloud "></i></a>',
        restrict: "EA",
        scope: {},
        controller: function($scope, $window, Device) {
            $scope.disabled = function() {
                var isOnline = window.onlineCheck();
                return !isOnline;
            }
            $scope.gotoNms = function() {
                if($scope.disabled()) {
                    return;
                }

                if(!Device.realDevice.APDetails.serial) {
                    return;
                }
                $window.open("//manage-cloud.mimosa.co/app/index.html#/devices/sno/" + Device.realDevice.APDetails.serial);
            }
        }
    }
});