angular.module('MimosaApp').controller('UpgradeController', ['Device', '$scope', 'ngProgressFactory', function(Device, $scope, ngProgressFactory) {
    $scope.Device = Device;
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setHeight("0px");
    $scope.progressbar.setColor("#3597e7");

    $scope.$watch("Device.stats.upgrade", function(n, o) {
        if (!n) {
            return;
        }
        if (Object.keys(n).length == 0) {
            return;
        }
        if (n.status == "success" ) {
            $scope.progressbar.setHeight("5px");
            $scope.progressbar.set(n.percentage >= 99 ? 100 : n.percentage);
            $scope.Device.reloadOnReconnect = true;
        }
        if (n.status == "upgrading" ) {
            $scope.progressbar.setHeight("5px");
            $scope.progressbar.set(n.percentage >= 99 ? 100 : n.percentage);
            $scope.Device.reloadOnReconnect = true;
        }
    }, true);

    $scope.$watch("Device.stats.upgrade.upgrade", function(n, o) {
        if (!n) {
            return;
        }
        if (Object.keys(n).length == 0) {
            return;
        }
        if (n.upgrade_status == "upgrading" ) {
            $scope.progressbar.setHeight("5px");
            // Device is in an upgrade state. Is this reliable?
            $scope.progressbar.set(n.percentage >= 99 ? 100 : n.percentage);
            $scope.Device.reloadOnReconnect = true;
        }
    }, true);
}]);

/**
 5:::{"args": [{"data": {"upgrade": {"message": "", "percentage": 10.0, "upgrade_status": "upgrading"}}, "key": "upgrade"}], "name": "stats"}
 **/