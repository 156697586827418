'use strict';

/* Directives */

var smartInputs = function (Device) {

    return {
        restrict: 'E',
        require: ['?ngModel', '?ngDisabled'],
        priority: 101,
        controller: function ($scope, $element, $attrs, Device) {
            $scope.$on('uiStateChange', function () {
                if (!$attrs.hasOwnProperty('ngModel')) {
                    return;
                }
            });
        },
        link: function (scope, element, attr) {
            element.bind('focus', function () {
                var ele = element[0];
                for (var i = 0; i < ele.attributes.length; i++) {
                    var attr = ele.attributes[i];
                    if (attr.name == 'ng-model') {
                        // Device.io.emit("notify", {
                        //     type: "focus",
                        //     value: attr.value,
                        //     CID: mimosaApp.clientId
                        // });
                    }
                }
            }).bind('blur', function () {
                var ele = element[0];
                for (var i = 0; i < ele.attributes.length; i++) {
                    var attr = ele.attributes[i];
                    if (attr.name == 'ng-model') {
                        // Device.io.emit("notify", {
                        //     type: "blur",
                        //     value: attr.value,
                        //     CID: mimosaApp.clientId
                        // });
                    }
                }
            }).bind('change', function () {
                var ele = element[0];
                for (var i = 0; i < ele.attributes.length; i++) {
                    var attr = ele.attributes[i];
                    if (attr.name == 'ng-model') {
                        // Device.io.emit("notify", {
                        //     type: "change",
                        //     value: attr.value,
                        //     CID: mimosaApp.clientId
                        // });
                    }
                }
            });
        }
    };
};

/**
 * This is for sending click events when user clicks on stuff.
 */
// angular.module("MimosaApp").directive({
//     "textarea": smartInputs,
//     "input": smartInputs
// });

angular.module('MimosaApp').directive('input', function ($q, $timeout) {
    return {
        restrict: 'E',
        require: '^?ngModel',
        link: function (scope, elm, attrs, ngModel) {
            if (!ngModel) {
                return;
            }
            /**
             * Currently this only does one one type of validation, meaning inputs can only have one validation type. Theoretically we could modify it to handle type='ip hostname notblank' etc, to do multiple validations at once. If needed.
             */

            var reg;
            var allowEmpty = true;

            switch (attrs.type) {
                case "internetName":
                    reg = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/);
                    allowEmpty = false;
                    break;
                case "ip":
                    reg = new RegExp(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
                    break;
                case "mac":
                    reg = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$');
                    break;
                case "hostname":
                    reg = new RegExp('^[a-zA-Z0-9-_ ]{3,64}$');
                    break;
            }
            if (reg) {

                ngModel.$validators[attrs.type] = function (modelValue, viewValue) {
                    if (modelValue === undefined || viewValue === "") {
                        return true; // maybe?
                    }

                    return !!reg.exec(modelValue);
                };
            }
        }
    };
}).directive('noDupe', [function () {
    return {
        restrict: 'A',
        require: "ngModel",
        scope: {
            "noDupe": "="
        },
        link: function (scope, element, attr, ngModel) {
            ngModel.$validators.noDupe = function (modelValue) {
                if (modelValue === scope.noDupe) {
                    return false;
                }

                return true;
            }
        }
    }
}]).directive('preventValue', [function () {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            var valuesToPrevent = scope.$eval(attrs.preventValue);

            ngModel.$validators.preventValue = function (modelValue) {
                if (valuesToPrevent.indexOf(modelValue) !== -1) {
                    return false;
                }
                return true;
            }
        }
    }
}]).directive('input', [function () {
    return {
        restrict: 'E',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
            if ('undefined' !== typeof attrs.type && 'number' === attrs.type && ngModel) {
                ngModel.$formatters.push(function (modelValue) {
                    return Number(modelValue);
                });

                ngModel.$parsers.push(function (viewValue) {
                    return Number(viewValue);
                });
            }
        }
    }
}]).directive('vlanProtocol', [function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attr, ngModel) {
            function validate(modelValue, viewValue) {
                if (modelValue === undefined || viewValue === "") {
                    return true;
                }

                if (!/^[0-9A-F]{1,4}$/i.test(modelValue)) {
                    return false;
                }

                if (parseInt(modelValue, 16) > 0x0000 && parseInt(modelValue, 16) <= 0xFFFF) {
                    return true;
                }

                return false;
            }

            ngModel.$validators.vlanProtocol = validate;
        }
    }
}]).directive('cvlan', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$formatters.push(function (modelValue) {
                console.log('model', modelValue);
                if (modelValue === undefined) {
                    return 0;
                }
                return modelValue;
            });

            function validate(modelValue, viewValue) {

                if (viewValue === "" || viewValue === undefined || viewValue === "0") {
                    return true;
                }
                modelValue = parseInt(modelValue); // If input is text, we want number object.

                if (modelValue === 0) {
                    return true;
                }

                if (modelValue >= 2 && modelValue <= 4093) {
                    return true;
                }
                return false;
            }

            ngModel.$validators.cvlan = validate;
        }
    }
}).directive("vlan", function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attr, ngModel) {

            function noBlank() {
                if(ngModel.$viewValue === '') {
                    ngModel.$setViewValue(0);
                    ngModel.$render();
                }
            }

            if (attr.type == 'number') {
                element.on('blur', function() {
                    scope.$evalAsync(noBlank);
                });

                noBlank();
            }

            function validate(modelValue, viewValue) {

                if (viewValue === "" || viewValue === undefined) {
                    return true;
                }
                modelValue = parseInt(modelValue); // If input is text, we want number object.

                if (modelValue === 0) {
                    return true;
                }

                if (modelValue >= 2 && modelValue <= 4093) {
                    return true;
                }
                return false;
            }

            ngModel.$validators.vlan = validate;
        }
    }

}).directive('radius', [function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attr, ngModel) {
            function validate(modelValue, viewValue) {
                var value = modelValue || viewValue;

                if (!value) {
                    return true;
                }

                if (value.indexOf(' ') > -1) {
                    return false;
                } else {
                    return true;
                }

            }

            ngModel.$validators.radius = validate;
        }
    };
}]).directive('notSame', [function () {
    'use strict';

    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            var modelToMatch = element.attr('not-same');
            ngModel.$validators.match = function (modelValue, viewValue) {
                return viewValue != scope.$eval(modelToMatch);
            };
        }
    };
}]).directive('sameAs', [function () {
    'use strict';

    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            var modelToMatch = element.attr('same-as');
            ngModel.$validators.match = function (modelValue, viewValue) {
                return viewValue === scope.$eval(modelToMatch);
            };
        }
    };
}]).directive('match', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            ngModel.$parsers.unshift(validate);

            scope.$watch(attrs.match, function () {
                ngModel.$setViewValue(ngModel.$viewValue);
            });

            function validate(value) {
                var isValid = scope.$eval(attrs.match) == value;
                ngModel.$setValidity('match', isValid);
                return isValid ? value : undefined;
            }
        }
    };
}).directive('unique', function ($rootScope) {
    /**
     * Validation for Unique value within a model object.
     *
     * Usage example: for making ssid per client unique: model="client.ssid"
     * Add the following to the html: unique="clients"
     */
    return {
        require: 'ngModel',
        scope: {
            uniqueName: "@",
            unique: "="
        },
        link: function (scope, elem, attr, ngModel) {

            function isUnique(value, minOccur) {
                if ("undefined" == typeof(minOccur)) {
                    minOccur = 0;
                }

                var isValid = true,
                    count = 0;

                jQuery.each(scope.unique, function (index, el) {
                    if (el == value || el[scope.uniqueName] == value) {
                        //if(el[attr.uniqueProp] == value) {
                        if (++count > minOccur) {
                            isValid = false;
                            return;
                        }
                    }
                });
                return isValid;
            }

            function validate(value) {
                var valid = isUnique(value);
                ngModel.$setValidity('unique', valid);

                return valid ? value : undefined;
            }

            ngModel.$parsers.unshift(function (value) {
                ngModel.$setValidity('unique', isUnique(value));
                return value;
            });

            ngModel.$formatters.unshift(function (value) {
                ngModel.$setValidity('unique', isUnique(value, 1));
                return value;
            });
        }
    }
}).directive('port', [function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, ele, attr, ngModel) {
            function validate(modelValue, viewValue) {
                if (modelValue === undefined || viewValue === "") {
                    return true;
                }

                var port = parseInt(modelValue);

                if (port > 0 && port <= 65535) {
                    return true;
                }

                return false;
            }

            ngModel.$validators.port = validate;
        }
    }
}]).directive('notNull', ['$parse', function ($parse) {
    return {
        restrit: 'A',
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            if (attrs.type == 'number') {
                function update() {
                    var getter = $parse(attrs['ngModel']);
                    var value = getter(scope);

                    if (value == null) {
                        ngModel.$setViewValue(0);
                        ngModel.$render();
                    }
                }

                element.on('blur', update);
                scope.$on('$destroy', function () {
                    element.off('blur', update);
                });
            }
        }
    }
}]).directive('uberNumberValidate', [function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            function update() {
                var newValue = false;
                var max = Number(attrs.max);
                var min = Number(attrs.min);

                if (ngModel.$viewValue == "" || ngModel.$viewValue == "-") {
                    newValue = min;
                }

                if (Number(ngModel.$viewValue) > max && newValue === false) {
                    newValue = max;
                }

                if (Number(ngModel.$viewValue) < min && newValue === false) {
                    newValue = min;
                }
                if (newValue !== false) {
                    ngModel.$setViewValue(newValue);
                    ngModel.$render();
                }
            }

            element.on('blur', function () {
                scope.$evalAsync(update);
            });
        }
    }
}]).directive('onlyDigits', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (!ngModel) return;
            var maxLimit = Number(attrs.max),
                minLimit = Number(attrs.min);
            ngModel.$parsers.unshift(function (inputValue) {
                var digits = inputValue.split('').filter(function (s) {
                    return (!isNaN(s) && s != ' ');
                }).join('');
                var num = digits;
                if (minLimit && !(digits == '') && Number(digits) <= minLimit)
                    num = minLimit;
                else if (maxLimit && !(digits == '') && Number(digits) >= maxLimit)
                    num = maxLimit;
                ngModel.$viewValue = num;
                ngModel.$$lastCommittedViewValue = num;
                ngModel.$render();
                return num;
            });
        }
    };
}).directive('coaPort', function (Device) {
    return {
        restrict: 'A',
        scope: {
            ssid: "="
        },
        require: "ngModel",
        link: function (scope, ele, attr, ngModel) {
            if (!ngModel) {
                return;
            }

            function validate(modelValue, viewValue, model) {
                if (modelValue === undefined || viewValue === "") {
                    return true;
                }
                
                var mode = Device.stageDevice.wirelessMode.mode;

                if (mode === "csma") {
                    var key = "ssids5"
                } else {
                    var key = "tdma_ssids5";
                }

                for (var ssid in Device.stageDevice[key]) {
                    if (Device.stageDevice[key][ssid].hasOwnProperty('DAS Server') && Device.stageDevice[key][ssid].intf !== scope.ssid.intf) {
                        if (modelValue == Device.stageDevice[key][ssid]['DAS Server']['Port']) {
                            return false;
                        }
                    }
                }

                return true;
            }

            ngModel.$validators.port = function (modelValue, viewValue) {
                return validate(modelValue, viewValue, ngModel);
            };
        }
    }
});
