'use strict'

angular.module('MimosaApp').controller("LinkPageController", function($scope, $controller, Device, $rootScope) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;
    $scope.disconnect = false;

    $scope.genderSplitOptions = [
        "A - 50/50",
        "B - 50/50",
        "A - 67/33",
        "B - 67/33",
        "A - 75/25",
        "B - 75/25"
    ];

    $scope.bandOptions5 = [
        {
            key: "WiFi Interop",
            mode: 'csma'

        }, {
            key: "SRS",
            mode: "tdma"
        }
    ];

    $scope.phyRateOptimizationOptions = [
        "3%",
        "10%"
    ];

    $scope.PreSave = function() {
        var curRadConf = Device.stats.radioStatusBbic.radioConfigBbic;
        var curAgcConf = "agc";
        var targetAgcConf = "tdma_agc";

        if(Device.stageDevice.wirelessMode.mode == "csma") {
			curAgcConf = "tdma_agc";
			targetAgcConf = "agc";
        }
        for(var key in curAgcConf) {
            Device.stageDevice[targetAgcConf] = Device.stageDevice[curAgcConf];
        }
    };

    $scope.isPageValid = function() {
        if(!$scope.linkForm.$valid) {
            throw {
                message: "Invalid input. Please provide valid input."
            }
        }
    }
    $scope.initGenderTraffic = function () {
        if ($scope.Device.stageDevice.wirelessMode.genderTrafficSplit !== undefined) return;
        else {
            $scope.Device.stageDevice.wirelessMode.genderTrafficSplit = $scope.genderSplitOptions[0];
        }
    };
    $scope.initPhyRateOptimization = function () {
        if ($scope.Device.stageDevice.wirelessMode.phyRateOptimization !== undefined) return;
        else {
            $scope.Device.stageDevice.wirelessMode.phyRateOptimization = $scope.phyRateOptimizationOptions[0];
        }
    };
    $scope.$watch("Device.stageDevice.wirelessMode.mode", function(n, o) {
        if(n === o) {
            return;
        }
        if(n !== Device.realDevice.wirelessMode.mode) {
            var msg;
            if (n === "csma") {
                msg = "Warning: Switching wireless protocols requires a reboot. Please verify SSID, Channel and Power settings after device completes reboot.";
            }
            else {
                msg = "Warning: Switching wireless protocols requires a reboot. The SRS protocol is compatible exclusively with Mimosa clients (no third-party clients). Only one 5 GHz SSID is supported. Please verify SSID, Channel and Power settings after device completes reboot.";
            }
            $rootScope.$broadcast("prompt", msg, function() {
                Device.stageDevice.wirelessMode.mode = angular.copy(Device.realDevice.wirelessMode.mode);
                Device.stageDevice.wirelessMode.genderTrafficSplit = angular.copy(Device.realDevice.wirelessMode.genderTrafficSplit);
                Device.stageDevice.wirelessMode.phyRateOptimization = angular.copy(Device.realDevice.wirelessMode.phyRateOptimization);
                $scope.disconnect = false;
            }, function(){
                $scope.disconnect = true;
            });
        }
    });
    /**
     * Copied from ChannelPower controller. Does this need to be moved to main pagecontroller? Perhaps.
     */
    $scope.$on("post save", function() {
        if($scope.disconnect) {
            // We delay 100 milliseconds just to be sure the message was sent.
            setTimeout(function() {
                Device.socket.disconnect(true);
            }, 100);
        }
    });

    $scope.$watch("Device.stageDevice.nms['Turbo Performance']", function(n, o) {
        if(n === o) {
            return;
        }
        if(n !== Device.realDevice.nms['Turbo Performance']) {
            var msg = "Changing this configuration will have an impact on system performance. This change requires a system reboot.";
            $rootScope.$broadcast("prompt", msg, function() {
                Device.stageDevice.nms["Turbo Performance"] = angular.copy(Device.realDevice.nms["Turbo Performance"]);
                $scope.disconnect = false;
            }, function(){
                $scope.disconnect = true;
            });
        }
    });

    $scope.agcOptions = ["Off", "Manual"];
});
