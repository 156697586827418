'use strict';

angular.module('MimosaApp').controller('FirmwarePageController', ['$scope', '$controller', 'Device', 'FileUpload', '$rootScope', "$uibModal", function($scope, $controller, Device, FileUpload, $rootScope, $uibModal) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;
    $scope.FileUpload = FileUpload;


    $scope.firmware = false;
    $scope.uploadFile = function(key) {
        var file = $scope[key];
        switch(key) {
            case "firmware":
                var uploadUrl = "/firmware";
                break;
            case "config":
                var uploadUrl = "/loadconfig";
                break;
        }
        FileUpload.uploadFileToUrl(file, uploadUrl, key);
    };
    $scope.uploadShow = function(key) {
        if(FileUpload.hasOwnProperty(key)) {
            if(FileUpload[key].percent > -1) {
                return false;
            }
        }
        if($scope[key]) {
            return true;
        }
        return false;
    };

    $scope.open = function(url, factoryResetHeader) {
        var msg = "";
        switch(url) {
            case "reboot":
                msg = "This will cause the device to reboot.";
                break;
            case "clearconfig":
                msg = "This will clear the device's configuration.";
                break;
            case "lock":
                msg = "After reboot, device will require to be unlocked.";
                break;
            case "factoryreset":
                msg = "This action will clear the device's configuration and unlock status to the factory settings.";
                break;
        }
        msg = msg + " Are you sure you wish to do this?";

        var modal = $uibModal.open({
            templateUrl: "confirmation.html",
            controller: "ConfirmationController",
            resolve: {
                msg: function() {
                    return msg;
                },
                url: function() {
                    return url;
                },
                factoryResetHeader: function() {
                    if (!factoryResetHeader) {
                        return false;
                    }
                    else {
                        return factoryResetHeader;
                    }
                }
            }
        });
        modal.result.then(function(url) {
            // Do we need to do anything when a modal is accepted?
        }, function() {
            // Do we need to do anything when the modal is cancelled?
        });
    };
    $scope.isPageValid = function () {
        if (!Device.stageDevice.clientWatchDog.timeout) {
            throw {
                name: "Invalid Time",
                message: "Enter a value between 5 and 60 inclusive"
            };
        }
    };
    $scope.cancelUpload =function(key) {
        $scope[key] = false;
        Device.socket.emit("upload:cancel")
        FileUpload.cancelUpload(key);
        FileUpload.reset(key);
        $("#"+key+"_upload_input").val(''); // This is an angular no-no but in this case, it would require a whole new set of directives/service files.
    };
    // If the error flag is set, we reset all the upload data, remove error flag, show a message.
    $scope.$watch(function() {
        return FileUpload.error;
    }, function(n) {
        if(n) {
            $rootScope.$broadcast("alert:info", 'There was an error uploading. Please try again.');
            $scope.cancelUpload('firmware');
        }
    }, true);

}]).controller("ConfirmationController", function($scope, $uibModalInstance, url, $http, msg, factoryResetHeader) {
    $scope.msg = msg;
    var callUrl = function(url) {
        var url = "/"+url;
        $http.get(url).then(function(res) {
            if (url == '/factoryreset' || url == '/lock') {
                $scope.Device.reloadOnReconnect = true;
            }
        }, function(err) {
        });
    };

    $scope.factoryReset = factoryResetHeader;

    $scope.ok = function() {
        callUrl(url);
        $uibModalInstance.close(url);
    };
    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }
});

