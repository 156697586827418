/**
 * Created by thegagan on 4/21/15.
 */

$(document).ready(function() {

    // make clicked links 'active'
    $('body').on('click', '.vertical-nav-div-cont ul li.item a', function() {
        $('body .vertical-nav-div-cont ul li.item a.active').removeClass('active');
        $(this).addClass('active');
    });

    // close nav dropdowns when user mouses out of nav
    $('body').on('mouseleave', '.vertical-nav-div-cont', function() {
        $('.nav-dropdown').parent().removeClass('open');
    });

    // sticky nav behavior on pin click
    $('body').on('click', '.nav-pin', function() {
        $('.nav-pin').toggleClass('active');
        if ($('.nav-pin').hasClass('active')) {
            $('.vertical-nav-div-cont').addClass('active');
            $('#main-container').addClass('navPinned');
        }
        else {
            $('.vertical-nav-div-cont').removeClass('active');
            $('#main-container').removeClass('navPinned');
        }
    });

    // sticky nav behavior for mobile. on nav click, collapse nav
    $('body').on('click', '.nav-pin-mobile', function() {
        $('.nav-pin-mobile').toggleClass('mobile-active');
    });


    // nav pin behavior
    $('body').on('click', '#vert-nav-container', function() {
        if ($('.nav-pin-mobile').hasClass('mobile-active')) {
            $('.vertical-nav-div-cont').on('click', function() {
                $('.nav-pin').removeClass('active');
                $('.nav-pin-mobile').removeClass('active');
                $('.vertical-nav-div-cont').removeClass('active');
                $('#main-container').removeClass('navPinned');
            });
        }
    });

    // on mobile remove all nav pin behavior
    var resetForMobile = function() {
        if ($('body').width() <= 992) {
            $('.vertical-nav-div-cont').removeClass('active');
            $('#main-container').removeClass('navPinned');
        }
    };
    resetForMobile();
    $(window).resize(function() {
        resetForMobile();
    });

    // Make sure the currently selected link is highlighted (useful for reloads);
    $('#vertNavCollapsing a').removeClass('active');
    $("#vertNavCollapsing a[href='" + window.location.hash + "']").addClass('active');


// ----------------- MOBILE NAV TOGGLE ------------------------------------------------
// ------------------------------------------------------------------------------------
    // when any left nav link is clicked, return all views back to basic view. Used with 920-max @media
    $('body').on('click', 'ul li.item', function() {
        $(this).removeClass('active');
        // $('.vertical-nav-div-cont').removeClass('active');
        //$('#main-container').removeClass('navPinned');
    });

    $('body').on('click', '.toggle-mobileNav', function() {
        $(this).toggleClass('active');
        $('.vertical-nav-div-cont').toggleClass('active');
        $('#main-container').toggleClass('navPinned');
    });

});
