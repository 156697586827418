angular.module('MimosaApp').directive('mmhelp', ['$compile', '$window', '$uibModal', function ($compile, $window, modal) {

    var template = "<i ng-click='openHelp()' class='mimosaglyph-info mimosaglyph pull-right pRight10 pointer-glyph'></i>";
    var controller = ["$scope", "$uibModal", "HelpData", function ($scope, uibModal, HelpData) {
        $scope.openHelp = function () {
            var template = '<div class="modal-header"><h3 class="modal-title">{{HelpHtml.title}}</h3></div><div class="modal-body" ng-bind-html="HelpHtml.body"></div><div class="modal-footer"><button class="btn btn-primary" type="button" ng-click="ok()">OK</button>';

            ga('send', 'event', "Help", "Opened", $scope.id);
            var modalInstance = uibModal.open({
                template: template,
                controller: "HelpModal",
                resolve: {
                    HelpHtml: function () {
                        return HelpData.get($scope.id);
                    }
                }
            });
        };
    }];
    return {
        restrict: "EA",
        scope: {
            id: "@"
        },
        transclude: true,
        replace: true,
        template: template,
        controller: controller
    }
}]).controller("HelpModal", function ($scope, $uibModalInstance, HelpHtml) {
    $scope.HelpHtml = HelpHtml;

    $scope.ok = function () {
        $uibModalInstance.close();
    }
}).factory("HelpData", function ($http, $sce) {

    var HelpEntry = {
        init: function () {
            var self = this;
            var helpHtml = $http.get("/assets/help.html", {
                cache: true
            });
            helpHtml.then(function (response) {
                self.data = angular.element(response.data);
            });
        },
        get: function (id) {
            var ret = {
                title: "",
                body: ""
            };
            var content = this.data.filter("#"+id);
            if(content.length > 0) {
                ret.title = content.prop("title");
                ret.body = $sce.trustAsHtml(content[0].outerHTML);
                return ret;
            }
            console.log(id);
        }
    };

    HelpEntry.init();

    return HelpEntry;
});