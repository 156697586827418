angular.module('MimosaApp').controller('SaveBarController', ['Device', '$scope', '$rootScope', function(Device, $scope, $rootScope) {

    $scope.isDisabled = false;
    $scope.Device = Device;
    $scope.hide = false;

    $scope.$on('change', function(scope, changeCount) {
        if(changeCount == 0) {
            scope.currentScope.isDisabled = false;
        }
        console.log('Save Bar Changed: ', changeCount);
    });

    $scope.commit = function() {
        $scope.isDisabled = true;
        $rootScope.$broadcast('commit');
    };

    $scope.reset = function() {
        $rootScope.$broadcast('reset');
    };

    //TODO These should be moved to a service and off scope events.
    $scope.$on("disableSaveBar", function() {
        $scope.isDisabled = true;
    });
    $scope.$on("enableSaveBar", function() {
        $scope.isDisabled = false;
    });
    $scope.$on("hideSaveBar", function() {
        $scope.hide = true;
    });
    $scope.$on("showSaveBar", function() {
        $scope.hide = false;
    });
    $scope.$on("socket:successful", function() {
        // Do nothing for now
    });
}]);
