angular.module('MimosaApp').directive('sessionTimeout', ['SessionTimeoutService', 'Device', function (SessionTimeoutService, Device) {
    var controller = ['$scope', '$uibModal', function ($scope) {

        $scope.$watch("Device.realDevice.sessionTimeout", function (n, o) {
            if (n !== o) {
                SessionTimeoutService.setLength(parseFloat(n));
            }
        });

        $scope.$watch("service.timeLeft", function (n, o) {
            if(n <= 0) {
                window.location.href = "/logout"
            }
        });
    }];

    var link = function(scope, ele, attr) {
        scope.service = SessionTimeoutService;
        scope.Device = Device;
    };
    return {
        restrict: 'E',
        link: link,
        controller: controller
    }
}]).directive('documentWatch', function($document, SessionTimeoutService, $http) {
   return {
       link: function(scope, ele, attr) {
           var pinging = false;
           $document.on('mouseup', function(event) {
               SessionTimeoutService.reset();
               if(!pinging) {
                   pinging = true;
                   $http.get("/ping").then(function() {
                       pinging = false;
                   }, function(res) {
                       pinging = false;
                       if (res.status == 403) {
                           window.location.href = '/login';
                       }

                   });
               }
           });
       }
   }
});