angular.module('MimosaApp').factory("OUIService", ['LZString', '$http', function(lzstring, $http) {
    var ouis = false;
    var re = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+/;

    var cached = {};

    $http.get("/assets/oui-c.txt").then(function(response) {
        ouis = lzstring.decompressFromUTF16(response.data);

    });
    var service = {
        getBrand: function (oui) {
            oui = oui.toUpperCase();
            if (!ouis) {
                return "Unknown";
            }
            if(!re.test(oui)) {
                return "Invalid";
            }
            var oui = parseInt(oui.split(":").splice(0, 3).join(""), 16);
            if(!cached.hasOwnProperty(oui)) {
                var a = ouis.indexOf(oui);
                if(a === -1) {
                    cached[oui] = "Unknown";
                } else {
                    var start = ouis.indexOf(",", a) + 1;
                    var end = ouis.indexOf("|", start);
                    cached[oui] = ouis.substring(start, end);
                }
            }
            return cached[oui];
        }
    }
    return service;
}]);