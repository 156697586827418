'use strict';

angular.module('MimosaApp').directive('validationSpaces', function(){
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, ngModel) {
            ngModel.$validators.spaces = function(modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) return true;

                if (viewValue.indexOf(" ") === -1) return true;

                return false;
            }
        }
    }
});