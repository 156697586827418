angular.module('MimosaApp').controller('DashboardPageController', ['$scope', '$controller', 'Device', 'Clients', '$rootScope', '$location', 'UIDB', function ($scope, $controller, Device, Clients, $rootScope, $location, UIDB) {
    $controller('PageController', {$scope: $scope, Device: Device});
    $scope.Device = Device;
    $scope.Clients = Clients;
    $scope.UIDB = UIDB;
    $scope.num_of_fixed_sats = 0;

    $scope.Device.socket.forward("dashboard_apstats");
    $scope.temperature = {
        temp1: {
            Name: "CPU #1 Temp",
            C: "--",
            F: "--"
        },
        temp2: {
            Name: "CPU #2 Temp",
            C: "--",
            F: "--"
        }
    };
    $scope.latitude = "--";
    $scope.longitude = "--";

    $scope.clientPieData = $scope.clientPieLabels = $scope.apstats = [];

    /**
     * Client Chart
     */

    /// new shtufffffff
    $scope.barLabels = ['-120 to -80', '-80 to -70', '-70 to -60', '-60 to -50', '-50 to -10'];
    $scope.barData = [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0]
    ];

    $scope.clientBarOptions = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [
                {
                    stacked: true,
                    display: true,
                    position: 'left',
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 5,
                        maxTicksLimit: 5,
                        min: 0
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Clients"
                    }
                }
            ],
            xAxes: [
                {
                    stacked: true,
                    scaleLabel: {
                        display: true,
                        labelString: "RSSI"
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title: function (item) {
                    var titleStr = "Clients: ";
                    var clientCount = 0;
                    item.forEach(function (el) {
                        clientCount += el.yLabel;
                    })
                    titleStr += clientCount;
                    return titleStr;
                },
                label: function (item, data) {
                    var index = item.index;
                    var datasetIndex = item.datasetIndex;
                    var datasetMaxIndex = data.datasets.length - 1;
                    return data.datasets[datasetIndex].label + ": " + data.datasets[datasetIndex].data[index];
                }
            },
            itemSort: function (a, b) {
                return b.datasetIndex - a.datasetIndex;
            },
            bodySpacing: 4
        }
    };

    $scope.clientSeries = [{
        name: 'Good PER',
        index: 0,
        min: 0,
        max: 7
    }, {
        name: 'Ok PER',
        index: 1,
        min: 7,
        max: 10
    }, {
        name: 'Poor PER',
        index: 2,
        min: 10,
        max: 100
    }];

    var rssiMap = {
        0: {
            min: -50,
            max: -10
        },
        1: {
            min: -60,
            max: -50
        },
        2: {
            min: -70,
            max: -60
        },
        3: {
            min: -80,
            max: -70
        },
        4: {
            min: -120,
            max: -80
        }
    };

    updateClientChart = function (data) {
        var series = [];
        var clients = data;

        $scope.clientSeries.forEach(function (barSeries) {
            var newSeries = [];
            for (var y = 4; y >= 0; y--) {
                var clientCount = 0;
                var macs = Object.keys(clients);
                for (var i = 0; i < macs.length; i++) {
                    var client = clients[macs[i]];
                    if (client.disconnected != false)
                        continue;
                    var avgRssi = parseInt(client.su_rssi_avg[0]);
                    var per = parseInt(client.su_txavgper[60]);

                    if (!per.between(barSeries.min, barSeries.max)) {
                        continue;
                    }
                    if (!avgRssi.between(rssiMap[y].min, rssiMap[y].max)) {
                        continue;
                    }
                    clientCount++;
                }
                newSeries.push(clientCount);
            }
            series[barSeries.index] = newSeries;
        });
        $scope.barData = series;
    };

    $scope.barDataOverride = [
        {
            backgroundColor: 'rgba(15, 188, 97, .3)',
            borderColor: 'rgba(15, 188, 97, 1)'
        },
        {
            backgroundColor: 'rgba(244, 195, 11, .3)',
            borderColor: 'rgba(244, 195, 11, 1)'
        },
        {
            backgroundColor: 'rgba(170, 0, 0, .3)',
            borderColor: 'rgba(170, 0, 0, 1)'
        }
    ];

    $scope.barSeries = ['Good PER', 'OK PER', 'Poor PER'];

    ///// end new

    /**
     * Copied from linkpagecontroller. This needs to get refactored to maybe a service.
     * @type {*[]}
     */

    $scope.bands = [{
        'name': '5 GHz',
        'value': 5,
        key: 'tdma_ssids5',
        mode: "tdma",
        max: 1
    }, {
        'name': '5 GHz',
        'value': 5,
        key: 'ssids5',
        mode: "csma",
        max: 8
    }, {
        'name': '2.4 GHz',
        'value': 2,
        key: 'ssids2',
        max: 7
    }];

    $scope.filterBands = function (value, index, arr) {
        if (!Device.stageDevice.wirelessMode) {
            return false;
        }
        if (value.mode == Device.stageDevice.wirelessMode.mode || !value.mode) {
            return true;
        }
        return false;
    };


    /**
     * End Client Chart
     */

    /**
     *
     * Performance Chart
     *
     */
    $scope.labels = (function () {
        var labelArr = [];
        for (var i = 0; i <= 60; i++) {
            labelArr.push(i);
        }
        return labelArr.reverse();
    }());
    $scope.series = ['DL', 'UL'];
    $scope.data = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

    $scope.adjustedTime = function (data) {
        var hours = getHours();
        var minutes = getMinutes();
        var seconds = getSeconds();
        return seconds + 's,' + minutes + 'm,' + hours + 'h,' + 'and data is' + data;
    };
    $scope.onClick = function (points, evt) {
        console.log(points, evt);
    };

    function bytesToBps(y, fixed) {
        var abs_y = Math.abs(y);
        if (abs_y >= 1000000000) {
            return (y / 1000000000).toFixed(1) + " Gbps"
        }
        else if (abs_y >= 1000000) {
            return (y / 1000000).toFixed(1) + " Mbps"
        }
        else if (abs_y >= 1000) {
            return (y / 1000).toFixed(fixed) + " kbps"
        }
        else if (abs_y > 0) {
            return y.toFixed(fixed) + " bps"
        }
        else if (abs_y === 0) {
            return ''
        }
        else {
            return y
        }
    }

    $scope.options = {
        animation: false,
        type: 'line',
        legend: {
            display: true
        },
        tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
                title: function (item) {
                    return item[0].xLabel + ' ago'
                },
                label: function (item, data) {
                    var labelArr = [];
                    labelArr[0] = data.datasets[1].label + ": " + bytesToBps(data.datasets[1].data[item.index]);
                    labelArr[1] = data.datasets[0].label + ": " + bytesToBps(data.datasets[0].data[item.index]);
                    return labelArr;
                },
                afterTitle: function (item, data) {
                    var dataIndex = item[0].index,
                        dataDL = data.datasets[0].data[dataIndex],
                        dataUL = data.datasets[1].data[dataIndex];
                    return "Total: " + bytesToBps(dataDL + dataUL);
                }
            }
        },
        scales: {
            yAxes: [{
                stacked: true,
                ticks: {
                    min: 0,
                    suggestedMax: 1000,
                    callback: function (y) {
                        return bytesToBps(y, 0);
                    },
                    suggestedMin: 0
                }
            }],
            xAxes: [{
                ticks: {
                    maxTicksLimit: 20,
                    callback: function (x) {
                        return x + "s"
                    }
                }
            }]
        }
    };

    function updateXPutChart(orgStats) {
        var tmp = $scope.data.slice();

        tmp[0] = tmp[0].splice(-60);
        tmp[1] = tmp[1].splice(-60);

        tmp[0].push(orgStats.su_txbytes * 8);
        tmp[1].push(orgStats.su_rxbytes * 8);

        /* Change of $scope.data should be atomic */
        $scope.data = tmp;
        $scope.datetime = Date.now();
    }

    /**
     * End Performance Chart
     */


    $scope.clientPopover = function (client) {
        return client.MACaddr + (client.IP ? " | " + client.IP : "");
    };

    /**
     * Outputs client stats to javascript console.
     */
    $scope.$watch(function (scope) {
        return scope.Device.stats;
    }, function dashboardChange(n, o, scope) {

        /**
         * Temperature update
         * @type {string[]}
         */
        try {
            if (typeof Device.stats.boardtemp[0] != "undefined") {
                // Show board temperature and BBIC temperature
                scope.temperature.temp1.Name = "System Temp"
                scope.temperature.temp1.C = parseInt(Device.stats.boardtemp[0]).toFixed(0);
                scope.temperature.temp1.F = (parseInt(Device.stats.boardtemp[0]) * 9 / 5 + 32).toFixed(0);
                if (typeof Device.stats.bbictemp[0] != "undefined" && parseInt(Device.stats.bbictemp[0]) > 0) {
                    scope.temperature.temp2.Name = "CPU Temp"
                    scope.temperature.temp2.C = parseInt(Device.stats.bbictemp[0]).toFixed(0);
                    scope.temperature.temp2.F = (parseInt(Device.stats.bbictemp[0]) * 9 / 5 + 32).toFixed(0);
                }
            } else {
                // Show BBIC temperature and NPU temperature
                if (typeof Device.stats.bbictemp[0] != "undefined" && parseInt(Device.stats.bbictemp[0]) > 0) {
                    scope.temperature.temp1.Name = "CPU #1 Temp"
                    scope.temperature.temp1.C = parseInt(Device.stats.bbictemp[0]).toFixed(0);
                    scope.temperature.temp1.F = (parseInt(Device.stats.bbictemp[0]) * 9 / 5 + 32).toFixed(0);
                }
                if (typeof Device.stats.nputemp[0] != "undefined" && parseInt(Device.stats.nputemp[0]) > 0) {
                    scope.temperature.temp2.Name = "CPU #2 Temp"
                    scope.temperature.temp2.C = parseInt(Device.stats.nputemp[0]).toFixed(0);
                    scope.temperature.temp2.F = (parseInt(Device.stats.nputemp[0]) * 9 / 5 + 32).toFixed(0);
                }
            }
        } catch (e) {
            console.log("error with temps");
        }
        /**
         * Update scope lat/long with new device data. To be replaced by device status fields at some point.
         */
        if (scope.Device.stats.hasOwnProperty('gps') && scope.Device.stats.gps.GGA) {

            var ggaStr = scope.Device.stats.gps.GGA;
            var ggaParts = ggaStr.split(",");

            // value of -200 indicates that the GPS is not connected.
            if (scope.Device.stats.gps.LATITUDE === -200) {
                $scope.latitude = scope.Device.stats.gps.LATITUDE;
            } else {
                $scope.latitude = (ggaParts[1].slice(0, 2) * 1) + ((ggaParts[1].slice(2) * 1) / 60);
                $scope.latitude = ggaParts[2] == "N" ? $scope.latitude : $scope.latitude * -1;
            }

            if (scope.Device.stats.gps.LONGITUDE === -200) {
                $scope.longitude = scope.Device.stats.gps.LONGITUDE;
            } else {
                $scope.longitude = (ggaParts[3].slice(0, 3) * 1) + ((ggaParts[3].slice(3) * 1) / 60);
                $scope.longitude = ggaParts[4] == "W" ? $scope.longitude * -1 : $scope.longitude;
            }

            $scope.num_of_fixed_sats = scope.Device.stats.gps.FIX.split(',').length;
        }
    }, true);

    $scope.$watch("Clients.list", function (n, o) {
        if (!n || n.length == 0) {
            return;
        }
        updateClientChart(n);
    }, true);

    $scope.deviceInterference = 0;

    var lastTimeStamp = 0;
    $scope.$on("socket:dashboard_apstats", function (e, msg) {
        var msg = unpack(msg);

        if (msg.tstamp == lastTimeStamp)
            return;
        lastTimeStamp = msg.tstamp;

        $scope.apstats = msg;
        $scope.deviceInterference = msg.airtime_int;
        updateXPutChart(msg);
    });

    $scope.$on("socket:time", function (e, msg) {
        var msg = unpack(msg);
        $scope.unixtime = msg.Data;
        $scope.Time = new Date(msg.Data * 1000);
        $scope.RebootTime = new Date(msg.RebootTime * 1000);
        $scope.UpTime = msg.Data - msg.RebootTime;
    });

    $scope.piechartOptions = {
        tooltipTemplate: "<%=label%>: <%= Math.round(value) %>%",
        segmentStrokeWidth: 1,
        maintainAspectRatio: false,
        animation: false,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (item, data) {
                    var label = data.labels[item.index];
                    var value = parseFloat(data.datasets[item.datasetIndex].data[item.index]);
                    return label + ": " + (value.toFixed(1)) + "%";
                }
            }
        },
        elements: {
            arc: {
                borderWidth: 1
            }
        }
    };
    $scope.pieChartColors = ["#F3f3f3", "#22A7F0", '#0FBC61', '#F4C30B', '#6BA4B8', '#45B97E', '#8F993E', '#0057A0'];

    $scope.$watch("Clients.list", function (n) {
        if (!n || n.length == 0) {
            return;
        }
        var tmpLabels = [];
        var tmpData = [];
        var clients = [];
        var airtimeClients = angular.copy(n);
        for (mac in airtimeClients) {
            clients.push(airtimeClients[mac]);
        };

        var clients = clients.sort(function (a, b) {
            return b.airtimeTotal[0] - a.airtimeTotal[0];
        });

        var tmpData = $.merge(tmpData, clients.map(function (ele) {
            return ele.airtimeTotal[0];
        }));

        var clientAggregateAir = tmpData.reduce(function (prev, cur) {
            return prev + cur;
        }, 0);

        var interference = Math.min((100 - clientAggregateAir), $scope.deviceInterference);

        tmpLabels.unshift("Interference");
        tmpData.unshift(interference);

        $scope.tmpData = tmpData;
        $scope.totalAir = tmpData.reduce(function (prev, cur) {
            return prev + cur;
        }, 0);

        var idle = 100 - (interference + clientAggregateAir);
        tmpLabels.unshift("Idle");
        tmpData.unshift(idle);

        var tmpLabels = $.merge(tmpLabels, clients.map(function (ele) {
            return ele.displayName;
        }));

        $scope.clientPieData = tmpData;
        $scope.clientPieLabels = tmpLabels;
    }, true);

    /**
     *  This could be moved to its own directive/service someday
     *
     * @param flag
     */
    function sendTempFlag(flag) {
        Device.socket.emit("set temp flag", flag);
        $scope.preemptHide = true;

    }

    function sendPersistFlag(flag) {
        Device.socket.emit("set flag", flag);
    }

    var conflictListener = $scope.$watch("Device.stats.CPLock", function (n, o) {
        if (!n) return;
        var message = false;
        if (n.tdwr) {
            message = "Due to Terminal Doppler Weather Radar (TDWR) proximity, the current channel frequency is not allowed.  Please select new channel settings for Center Frequency (MHz), Channel Width (MHz) and Tx Power (dBm).";
        } else if (n.changeChannel) {
            switch (n.reason) {
                case "Auto-Channel":
                    message = "Auto Channel is not supported in SRS. Please select new manual channel settings for Center Frequency (MHz), Channel Width (MHz) and Tx Power (dBm).";
                    break;
                case "Invalid Channel":
                    message = "The configured channel is not supported in WiFi Interop Mode.  Please select new channel settings for Center Frequency (MHz), Channel Width (MHz) and Tx Power (dBm).";
                    break;
                case "regulation":
                    message = "Channel regulations may have changed. Please select new channel settings.";
                    break;
            }
        }

        if (message) {
            conflictListener();
            $rootScope.$broadcast('prompt', message, function () {
                $scope.closeAgcGuide();
                $location.path("/channelpower");
            }, function () {
                $scope.closeAgcGuide();
                $location.path("/channelpower");
            }, true);
        }
    }, true);

    $scope.closeAgcGuide = function () {
        sendTempFlag({
            agcGuide: true
        });
    };
    $scope.closeHTTPS = function () {
        sendPersistFlag({
            https: true
        });
    }

    $scope.showHttps = function () {
        if (UIDB.initialized && (!UIDB.persist.hasOwnProperty('flags') || (UIDB.persist.hasOwnProperty('flags') && !UIDB.persist.flags.https)))
            return true;
        return false;
    };

    $scope.showAgcGuide = function () {
        if (!Device.stats.reboot || Object.keys(Device.stats.reboot).length === 0) return;

        if (Device.stats.CPLock.tdwr || Device.stats.CPLock.changeChannel) {
            return false;
        }
        return Device.stats.reboot.indexOf("Mode Changed") != -1;
    };
}]);
