mimosaApp.service('Clients', function (Device, $rootScope) {
    Device.socket.forward("dashboard_apstats");
    Device.socket.forward('noise');
    var noise = 0;
    var rxLastReceived = 0;

    var clients = {
        list: {},
        isMimosaConnected: false
    };

    clients.getListLength = function () {
        var length = 0;
        for (key in this.list) {
            if (!this.list[key].disconnected) {
                length++;
            }
        }
        return length;
    };

    clients.getDisconnected = function () {
        var length = 0;
        for (key in this.list) {
            if (this.list[key].disconnected) {
                length++;
            }
        }
        return length;
    };

    clients.bySsid = function (ssid) {
        var filteredBySsid = [];
        for (key in this.list) {
            if ((!this.list[key].disconnected) && (this.list[key].ssid === ssid)) {
                filteredBySsid.push(ssid);
            }
        }
        return filteredBySsid;
    };

    function findProblems(client) {
        var problems = [];
        // Check port auth status
        try {
            if (client.portAuth.status === 2) {
                problems.push("802.1X_UNAUTH");
            } else if (client.portAuth.status === 3) {
                problems.push("802.1X_AUTH");
            }
        } catch (e) {
            // ignore.
        }
        if (client.disconnected) {
            problems.push("DISCONNECTED");
        }

        return problems;
    }

    function pushNshift(newData, dataSet, maxLength) {
        if (dataSet.length >= maxLength) {
            dataSet.pop();
            dataSet.unshift(newData);
        }
        else {
            dataSet.unshift(newData);
        }
    };

    function shiftNpush(newData, dataSet, maxLength) {
        if (dataSet.length === 0) {
            for (var i = 0; i < maxLength; i++) {
                dataSet[i] = 0;
            }
        }
        if (dataSet.length >= maxLength) {
            dataSet.shift();
            dataSet.push(newData);
        }
        else {
            dataSet.push(newData);
        }
    }

    function formatData(data) {
        var clientObj = clients.list,
            notHistoric = ["Name", "IP", "ssid", "Version", "deviceType", "disconnected", "portAuth", "txPower", 'cvlan', 'radvlan', 'manvlan'],
            reverse = ['totalbits', 'txbits', 'rxbits', 'su_txavgper'],
            macs = Object.keys(data),
            associatedMacs = [];

        for (var i = 0; i < macs.length; i++) {
            var macData = data[macs[i]];
            if ($.isEmptyObject(macData.timeData))
                continue;
            else {
                associatedMacs.push(macs[i]);
            }
        }

        for (var key in clientObj) {
            if (associatedMacs.indexOf(key) === -1) {
                delete clientObj[key];
            }
        }

        for (var m = 0; m < associatedMacs.length; m++) {
            var currentMac = associatedMacs[m];
            if (!clientObj[currentMac]) {
                var currentMacData = data[currentMac];
                clientObj[currentMac] = {
                    Serial: currentMacData["Serial"],
                    _timeStamps: [],
                    rx: {},
                    isMimosa: currentMac.toUpperCase().indexOf('20:B5:C6') > -1
                };
            }
            // check and update non-historic properties if necessary
            for (var prop = 0; prop < notHistoric.length; prop++) {
                if (!clientObj[currentMac][notHistoric[prop]] || clientObj[currentMac][notHistoric[prop]] !== data[currentMac][notHistoric[prop]]) {
                    clientObj[currentMac][notHistoric[prop]] = data[currentMac][notHistoric[prop]];
                }
            }

            function getDistance(client) {
                if (client.isMimosa) {
                    return client.prop_delay[0] * 300;
                }
                else {
                    var subInfo = Device.stageDevice.subscribers.filter(function (ele) {
                        if (client.MACaddr[0].toUpperCase() == ele.macAddress.toUpperCase()) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    if (subInfo.length == 0) {
                        return '--';
                    }
                    if (subInfo[0].distance == 0) {
                        return '--'; // Flag to say it hasn't been configured.
                    }
                    return subInfo[0].distance * 1000; // Return meters
                }
            };

            // add/update all historical data integer properties
            for (var num in data[currentMac].timeData) {
                if (clientObj[currentMac]._timeStamps.indexOf(data[currentMac].timeData[num].received) > -1) {
                    continue;
                }
                else {
                    pushNshift(data[currentMac].timeData[num].received, clientObj[currentMac]._timeStamps, 61);
                }
                var timeObj = data[currentMac].timeData;
                for (var key in timeObj[num]) {
                    // if client doesn't have current data point -> add to client obj
                    if (!clientObj[currentMac][key]) {
                        clientObj[currentMac][key] = [];
                    }
                    if (reverse.indexOf(key) > -1) {
                        shiftNpush(timeObj[num][key], clientObj[currentMac][key], 61);
                    } else {
                        pushNshift(timeObj[num][key], clientObj[currentMac][key], 61);
                    }
                }

                var rxReverse = ["per"];
                if (data[currentMac].hasOwnProperty('remoteStats')) {
                    var stats = data[currentMac].remoteStats;

                    for (var key in stats) {
                        if (!clientObj[currentMac].rx[key]) {
                            clientObj[currentMac].rx[key] = Array(61);
                            clientObj[currentMac].rx[key].fill(0);
                        }
                        if (rxReverse.indexOf(key) > -1) {
                            var index = 0;
                            var func = shiftNpush;
                        } else {
                            var index = 61;
                            var func = pushNshift;
                        }
                        func(stats[key], clientObj[currentMac].rx[key], 61);
                    }
                }

                var airtimeTotal = data[currentMac].timeData[num].airtime_rx + data[currentMac].timeData[num].airtime_tx;

                if (!clientObj[currentMac].airtimeTotal) {
                    clientObj[currentMac].airtimeTotal = [];
                    clientObj[currentMac].airtimeTotal.push(airtimeTotal);
                }
                else {
                    pushNshift(airtimeTotal, clientObj[currentMac].airtimeTotal, 61);
                }

                clientObj[currentMac].macdec = parseInt(clientObj[currentMac].MACaddr[0].split(":").map(function (hex) {
                    return parseInt(hex, 16);
                }).join(""));

                clientObj[currentMac].displayName = clientObj[currentMac].Name || clientObj[currentMac].IP || clientObj[currentMac].MACaddr[0];

                if (!clientObj[currentMac].isMimosa) {
                    clientObj[currentMac].displayName = findSubscriberName(clientObj[currentMac]);
                }
            }
            clientObj[currentMac].distance = getDistance(clientObj[currentMac]);
            clientObj[currentMac].problems = findProblems(clientObj[currentMac]);

            // Update host information
            if (data[currentMac].hasOwnProperty('sta_host')) {
                clientObj[currentMac].sta_host = JSON.parse(JSON.stringify(data[currentMac].sta_host));
            }
        }
    }

    // for non-mimosa clients, if the user has set a Friendly Name, return it for the client list
    var findSubscriberName = function (client) {
        var subscribers = Device.stageDevice.subscribers;
        for (var i = 0; i < subscribers.length; i++) {
            if (subscribers[i].macAddress.toUpperCase() === client.MACaddr[0].toUpperCase() && subscribers[i].name !== 'sub_name') {
                return subscribers[i].name
            }
        }
        return client.displayName;
    }

    function isMimosaConnected() {
        var found = false;
        for (var key in clients.list) {
            if (clients.list[key].isMimosa) {
                found = true;
                break;
            }
        }
        clients.isMimosaConnected = found;
    }

    function MessageReceived(event, message) {
        if (message.key != "clients") {
            return;
        }

        formatData(unpack(message.data));
        isMimosaConnected();
    }

    function UpdateNoise(event, message) {
        noise = parseFloat(message.noise);
    }

    $rootScope.$on("socket:stats", MessageReceived);
    return clients;
});
