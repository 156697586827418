/**
 * Created by charlie on 7/24/15.
 */

'use strict';

angular.module('MimosaApp').controller('SsidPageController', function ($scope, $controller, Device, growl, $uibModal, Clients, $rootScope, SSIDType, SSID) {
    $controller('PageController', {$scope: $scope, Device: Device});

    $scope.Device = Device;
    $scope.Clients = Clients;
    $scope.SSIDType = SSIDType;
    $scope.SSID = SSID;

    $rootScope.$broadcast('hideSaveBar');

    $scope.securityTypes = {
        'csma': {
            5: [{
                'name': 'Open',
                'value': 'Open'
            }, {
                'name': 'WPA2-Enterprise',
                'value': 'Enterprise'
            }, {
                'name': 'Pre-Shared Key',
                'value': 'Pre-Shared-Key'
            }],
            2: [{
                'name': 'Open',
                'value': 'Open'
            }, {
                'name': 'Pre-Shared Key',
                'value': 'Pre-Shared-Key'
            }]
        },
        'tdma': {
            5: [{
                'name': 'Pre-Shared Key',
                'value': 'Pre-Shared-Key'
            }, {
                'name': 'WPA2-Enterprise',
                'value': 'Enterprise'
            }],
            2: [{
                'name': 'Open',
                'value': 'Open'
            }, {
                'name': 'Pre-Shared Key',
                'value': 'Pre-Shared-Key'
            }]
        }
    };


    $scope.bands = [{
        'name': '5 GHz',
        'value': 5,
        key: 'tdma_ssids5',
        mode: "tdma",
        max: 1
    }, {
        'name': '5 GHz',
        'value': 5,
        key: 'ssids5',
        mode: "csma",
        max: 8
    }, {
        'name': '2.4 GHz',
        'value': 2,
        key: 'ssids2',
        max: 7
    }];

    $scope.getNewSSIDBands = function () {
        if (!Device.stageDevice.wirelessMode) {
            return false;
        }
        var mode = Device.stageDevice.wirelessMode.mode;

        return $scope.bands.filter(function (ele) {

            var ret = true;
            if (ele.hasOwnProperty("mode") && ele.mode != mode) {
                ret = false;
            }

            if (Device.stageDevice[ele.key].length >= ele.max) {
                ret = false;
            }
            if (!Device.is2_4GHzAvailable && ele.key == 'ssids2') {
                ret = false;
            }
            return ret;
        });
    };

    $scope.filterBands = function (value) {
        if (!Device.stageDevice.wirelessMode) {
            return false;
        }
        if (value.value == 2 && !Device.is2_4GHzAvailable) {
            return false;
        }
        if (value.mode == Device.stageDevice.wirelessMode.mode || !value.mode) {
            return true;
        }
        return false;
    };

    $scope.showNewSsid = false;

    $scope.toggleForm = function () {
        $scope.newSsid = getBlankSsid();
        $scope.showNewSsid = !$scope.showNewSsid;
    };

    function getBlankSsid() {
        var ssidTemplate = {
            'Security': 'Pre-Shared-Key',
            'Enable': true,
            'Broadcast SSID': true,
            'Option82 Enable': false,
            'Vlan': 0,
            'Intra-BSS Traffic': false,
            'SSID Type': 'CPE',
            'Radius Auth Port': 1812,
            'Radius Acct Port': 1813,
            'rts': 500,
            'ssid': '',
            'Radius Proxy': false,
            'CPE Data Vlan Enabled': false,
            '802.1p': false,
            'Indigenous Vlan': 0,
        };
        return ssidTemplate;

    }

    $scope.bands.forEach(function (ele) {
        if (!Array.isArray(Device.stageDevice[ele.key])) {
            Device.stageDevice[ele.key] = [];
        }
    });

    $scope.isPageValid = function () {
        for (var i = 0; i < Device.stageDevice.ssids5.length; i++) {
            if (Device.stageDevice.ssids5[i].rts === 1) {
                throw {
                    name: "Invalid form",
                    message: "RTS Threshould can not be one."
                };
            }
            for (var j = i + 1; j < Device.stageDevice.ssids5.length; j++) {
                if (Device.stageDevice.ssids5[j].ssid == Device.stageDevice.ssids5[i].ssid) {
                    throw {
                        name: "Invalid VLAN ID",
                        message: "SSID name must be unique."
                    };
                }
                if ((Device.stageDevice.ssids5[j].Vlan == Device.stageDevice.ssids5[i].Vlan) && (Device.stageDevice.ssids5[i].Vlan != 0)) {
                    throw {
                        name: "Invalid VLAN ID",
                        message: "VLAN ID must be unique."
                    };
                }
            }
        }
        if ($scope.ssidForm.ssids.hasOwnProperty('$valid') && !$scope.ssidForm.ssids.$valid) {
            throw {
                name: 'Invalid form',
                message: 'There was an SSID form error.'
            };
        }
    };

    $scope.toggleDisable = function (ssid, intf) {
        if (ssid.Enable) {
            SSID.enable({
                ssid: ssid,
                bbic: intf === 5
            });
        } else {
            SSID.disable({
                ssid: ssid,
                bbic: intf === 5
            });
        }
    };

    $scope.removeSSID = function (ssid, intf) {
        $rootScope.$broadcast('prompt', "Are you sure you wish to delete this SSID?", function () {
        }, function () {
            SSID.delete({
                ssid: ssid,
                bbic: intf === 5
            });
        });
    };

    $scope.$watch("Device.realDevice.wirelessMode", function (n) {
        if (!n) {
            return;
        }
        $scope.newSsid = getBlankSsid();
    });

    $scope.KoushaSort = function (a) {
        if (!a.intf) {
            var ret = 99999;
        } else {
            var ret = parseInt(a.intf.match(/\d+/)[0]);
        }
        return ret;
    };

    $scope.editSsid = function (ssid, intf, index) {
        var modalInstance = $uibModal.open({
            controller: 'ssidControl',
            templateUrl: 'newssid.html',
            size: 'lg',
            resolve: {
                ssid: angular.copy(ssid),
                bands: function () {
                    return $scope.bands;
                },
                securityTypes: function () {
                    return $scope.securityTypes;
                },
                index: function () {
                    return index;
                },
                intf: function () {
                    return intf;
                },
                mode: function () {
                    return 'edit';
                }
            }
        });
    };

    $scope.newSsidModal = function () {
        var ssid = getBlankSsid();
        if (Device.realDevice.wirelessMode.mode === 'tdma') {
            ssid['SSID Type'] = "Hotspot";
        }
        var modalInstance = $uibModal.open({
            controller: 'ssidControl',
            templateUrl: 'newssid.html',
            size: 'lg',
            resolve: {
                ssid: function () {
                    return ssid;
                },
                bands: function () {
                    return $scope.getNewSSIDBands();
                },
                securityTypes: function () {
                    return $scope.securityTypes;
                },
                intf: function () {
                    return false;
                },
                mode: function () {
                    return 'new';
                },
                index: function () {
                    return -1;
                }
            }
        });
    };

    $scope.$on('$destroy', function () {
        $rootScope.$broadcast('showSaveBar');
    });
}).controller("ssidControl", function ($scope, Device, $uibModalInstance, ssid, bands, securityTypes, intf, growl, mode, index, SSIDType, SSID) {

    $scope.bands = bands;
    $scope.ssid = ssid;
    $scope.securityTypes = securityTypes;
    $scope.mode = mode;
    $scope.index = index;
    $scope.intf = intf;
    $scope.Device = Device;
    $scope.SSIDType = SSIDType;

    $scope.ssid_band = intf ? bands.filter(function (item) {
        return item.key == intf;
    })[0].value : defaultBand();

    $scope.ssidFilter = [$scope.ssid_band, Device.realDevice.wirelessMode.mode];

    $scope.selectedType = SSIDType.getObject(ssid['SSID Type'], $scope.ssid_band);

    function defaultBand() {
        if (!intf && Device.stageDevice.wirelessMode.mode == "tdma") {
            return 2;
        }
        return 5;
    }

    function validateSsid(ssid) {

        if ($scope.Band === 2) {
            ssid["SSID Type"] = 'Hotspot';
        }

        for (var i = 0; i < Device.stageDevice["ssids" + $scope.ssid_band].length; i++) {
            var tmp = Device.stageDevice["ssids" + $scope.ssid_band][i];

            if (mode == 'new') {
                if (tmp.ssid === ssid.ssid) {
                    growl.error("SSID name must be unique.");
                    return;
                }
                if ((tmp.Vlan === ssid.Vlan) && (ssid.Vlan != 0)) {
                    growl.error("Vlan ID must be unique.");
                    return;
                }
            }
        }
        if (!$scope.newSsidForm.$valid) {
            growl.error("There was an error creating a new SSID.");
            return;
        }

        if (ssid.hasOwnProperty('Vlan') && ssid.Vlan > 0 && ssid.Vlan === Device.stageDevice.mgmtVlan.vlan) {
            growl.error("VLAN ID Can not be the same as the management VLAN.");
            return;
        }
    }

    $scope.ok = function () {
        validateSsid($scope.ssid);
        if (mode == "edit") {
            SSID.update({
                ssid: ssid,
                bbic: $scope.ssid_band === 5
            });
        } else {
            SSID.create({
                ssid: ssid,
                bbic: $scope.ssid_band === 5
            });
        }
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.$watch("ssid", function (n, o) {
        if ($scope.ssid_band === 2) {
            n['Radius Proxy'] = false;
        }
    }, true);

    $scope.$watch("selectedType", function (n) {
        $scope.ssid['SSID Type'] = n.value;
    });

}).directive('radiusSecret', function () {
    return {
        scope: {
            required: "=",
            model: "="
        },
        transclude: false,
        templateUrl: "password.html"
    }
}).directive('serverConfig', function ($compile) {
    return {
        scope: {
            ipModel: "=",
            portModel: "=",
            passModel: "=",
            defaultPort: "@?",
            intervalModel: "=",
            intervalShow: "=?",
            portValidator: "=?",
            required: "=?",
            ssid: "=?"
        },
        transclude: true,
        templateUrl: "server.html",
        compile: function (ele, attrs) {
            if (!attrs.compiled) {
                if (attrs.hasOwnProperty('portValidator')) {
                    ele.find("input[port]").attr(attrs.portValidator, true).attr('ssid', attrs.ssid);
                }
                ele.attr('compiled', true);

                return {
                    pre: function (scope, ele, attrs) {
                        // do nothing... ?
                    },
                    post: function (scope, ele, attrs) {
                        scope.colWidth = 6;

                        if (scope.intervalShow) {
                            scope.colWidth = 4;
                        }
                        if (!scope.portModel && scope.defaultPort) {
                            scope.portModel = parseInt(scope.defaultPort);
                        }
                    }
                }
            }
        }
    }
}).service('SSID', function (Device) {

    var obj = {
        saving: false,
        disable: function (opts) {
            var toSend = {
                action: 'disable',
                intf: opts.ssid.intf
            };
            commit(toSend, opts.bbic);
        },
        delete: function (opts) {
            var toSend = {
                action: 'delete',
                intf: opts.ssid.intf
            };
            commit(toSend, opts.bbic);
        },
        update: function (opts) {
            var toSend = {
                action: "update",
                ssidInfo: opts.ssid
            };
            commit(toSend, opts.bbic);
        },
        enable: function (opts) {
            var toSend = {
                action: 'enable',
                ssidInfo: opts.ssid
            };
            commit(toSend, opts.bbic);
        },
        create: function (opts) {
            var toSend = {
                action: "create",
                ssidInfo: opts.ssid
            };
            commit(toSend, opts.bbic);
        }
    };

    function commit(data, bbic) {

        if (bbic) {
            var path = '/network/wireless/ssid/configupdate';
        } else {
            var path = '/network/npu/wireless/ssid/configupdate';
        }

        obj.saving = true;
        var gainCall = new RPCall({
            path: "/config/manager/set",
            interface: "system.config.global",
            method: "setConfig",
            target: "config.manager",
            args: {
                path: path,
                configData: data
            }
        }, Device);

        gainCall.call(function () {
            obj.saving = false;
        });
    }

    return obj;
});
