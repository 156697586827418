
angular.module('MimosaApp').controller('PromptController', function($scope, $uibModal) {
    $scope.$on('prompt', function(event, message, cancelHandler, okHandler, hideCancel) {
        var modalInstance = $uibModal.open({
            templateUrl: 'PromptContent.html',
            controller: 'PromptInstanceController',
            resolve: {
                message: function() {
                    return message;
                },
                cancel: function() {
                    return cancelHandler || function() {};
                },
                ok: function() {
                    return okHandler || function() {};
                },
                hideCancel: function() {
                    return hideCancel || false;
                }
            }
        });
    });

}).controller('PromptInstanceController', function ($scope, $rootScope, $uibModalInstance, message, cancel, ok, hideCancel) {

    $scope.message = message;
    $scope.hideCancel = hideCancel;
    $scope.ok = function () {
        $uibModalInstance.close();
        ok();
        $uibModalInstance.dismiss("ok");
    };

    $scope.cancel = function () {
        $rootScope.$broadcast("prompt:cancel");
        cancel();
        $uibModalInstance.dismiss('cancel');
    };

    $scope.$on("modal.closing", function(e, reason) {
        switch(reason) {
            case "backdrop click":
                cancel();
                $rootScope.$broadcast("prompt:cancel");
                break;
        }
    });

});